@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 16px 0;
}
.breadcrumbs a {
  color: var(--clr-main, #1D7FB7);
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
}