@use '../abstracts/' as *;

/* home_bnr
********************************************** */
.home_bnr {
  margin-top: -247px;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: perc(739,1400,'%') auto;
    gap: perc(16,310,'%') perc(56,1400,'%');
    .bnr-support {
      grid-column: 2/3;
      grid-row: 1/3;
    }
  }
  @include mq(sp) {
    margin-top: -124px;
  }
}
.home_bnr > * {
  display: block;
  border-radius: rem(8);
  overflow: hidden;
  @include mq(sp) {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.home_bnr a:hover {
  opacity: .7;
}

/* home_about
********************************************** */
.home_about {
  margin-top: 237px;
  &::after {
    top: -122px;
    @include mq(sp) {
      top: spvw(-61);
    }
  }
  @include mq(sp) {
    margin-top: 118px;
  }
}
.home_about--blc {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: auto perc(671,1400,'%');
    align-items: center;
  }
}
.home_about--txtarea {
  @include mq(sp, min, ps) {
    padding-left: perc(40,700,'%');
  }
  @include mq(sp) {
    margin-bottom: 24px;
  }
  .mce-content-body {
    line-height: 2.13;
  }
}
.home_about--imgarea {
  position: relative;
  aspect-ratio: 671/467;
  @include mq(sp, min, ps) {
    min-width: 560px;
  }
  @include mq(sp) {
    width: min(588px, 100%);
    @include auto-margin;
  }
  img {
    position: absolute;
  }
  .fukidashi {
    width: perc(160,671,'%');
    @include mq(1571) {
      border-width: max(calc(4/1571*1298px), perc(4,1571,vw));
      font-size: max(calc(16/1571*1298px), perc(16,1571,vw));
    }
    @include mq(sp) {
      border-width: min(4px, perc(4,583,vw));
      font-size: min(sprem(14), perc(14,583,vw));
    }
  }
}
.home_about--img01 {
  top: perc(24,467,'%');
  left: perc(135,671,'%');
  width: perc(312,671,'%');
}
.home_about--img02 {
  top: perc(162,467,'%');
  right: 0;
  width: perc(233,671,'%');
}
.home_about--fukidashi01 {
  top: 0;
  left: 0;
}
.home_about--fukidashi02 {
  top: 0;
  right: perc(39,671,'%');
}
.home_about--fukidashi03 {
  bottom: 0;
  left: perc(220,671,'%');
}
.home_about--icon01 {
  bottom: perc(37,467,'%');
  left: perc(51,671,'%');
  width: perc(33,671,'%');
}
.home_about--icon02 {
  bottom: perc(78,467,'%');
  left: perc(114,671,'%');
  width: perc(49,671,'%');
}