@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(48) 40px 40px;
    background-color: var(--bg-off_wht, #f7f7f7);
    border-radius: rem(20);
    overflow: hidden;
    @include mq(sp) {
      padding: rem(48) 24px 24px;
    }
    &:not(:first-child) {
      margin-top: 2em;
    }
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  .ez-toc-title-container {
    margin-top: rem(-48);
    margin-bottom: 32px;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
    background-color: var(--clr-main, #1D7FB7);
    padding: rem(10) 1em;
    @include mq(sp) {
      margin-bottom: 16px;
      margin-left: -24px;
      margin-right: -24px;
      width: calc(100% + 48px);
    }
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--clr-wht, #fff);
    font-size: rem(20);
    letter-spacing: .1em;
    font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: rem(16);
      aspect-ratio: 1/1;
      background-image: url(../images/common/icon-list.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: .5em;
      filter: brightness(0) invert(1);
    }
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 0;
  }
  div#ez-toc-container ul li {
    position: relative;
    padding-left: 1em;
    &::before {
      display: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 1em;
      left: 0;
      width: .5em;
      height: 2px;
      border-bottom: 2px solid var(--clr-sub, #ccc);
    }
  }
  div#ez-toc-container ul.ez-toc-list a {
    padding: .2em 0;
    color: var(--clr-main, #1D7FB7);
    &:hover {
      color: var(--clr-sub, #ccc);
      text-decoration: none;
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* author
**************************************** */
  .author {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 30% auto;
      column-gap: 5%;
      padding: 32px;
    }
  }
  .author--img {
    object-fit: cover;
    @include mq(sp) {
      display: block;
      max-width: 200px;
      @include auto-margin;
      margin-bottom: 16px;
    }
  }
  .author--ttl {
    position: relative;
    padding-left: 1em;
    letter-spacing: .1em;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: perc(4,20,em);
      height: 90%;
      background-color: #75bbbb;
      border-radius: 9999px;
    }
  }

/* blog--share
**************************************** */
  .blog--share_ttl {
    width: fit-content;
    padding: 0 perc(7,24,em);
    @include auto-margin;
    span {
      position: relative;
      display: block;
      padding: 0 1em;
    }
  }
  .blog--share_ttl,
  .blog--share_ttl span {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 2px;
      height: rem(30);
      border-right: 2px solid;
    }
    &::before {
      left: 0;
      transform: rotate(-36deg);
    }
    &::after {
      right: 0;
      transform: rotate(36deg);
    }
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(6);
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: rem(12);
    font-weight: bold;
    border-radius: rem(10);
    background-color: #f8f8f8;
    &:hover {
      background-color: var(--bg-off_wht, #f7f7f7);
    }
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: rem(10);
  }

/* blog--related
**************************************** */
  .posts_scroll {
    overflow: auto;
    padding-bottom: rem(32);
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
  }
  .posts_scroll .post:not(:last-child) {
    margin-right: 1rem;
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: rem(6);
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(64);
    color: var(--clr-main, #1D7FB7);
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: rem(6) rem(16);
    color: var(--clr-main, #1D7FB7);
    background-color: var(--bg-main, #ccc);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background-color: var(--bg-sub, #ddd);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: rem(40);
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: rem(4);
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: rem(4);
  }
  .wp-pagenavi .extend {
    width: rem(24) !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    @include rect(8);
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }
