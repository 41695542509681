@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.faq_item {
  position: relative;
  border-radius: rem(16);
  background-color: var(--bg-wht, #fff);
  padding: rem(28) rem(44);
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: rem(16);
  }
  .toggle {
    position: absolute;
    top: rem(20);
    right: rem(24);
    @include rect(40);
    background-color: #ffa59c;
    border-radius: 50%;
    transition: var(--transit-default, all .4s ease-out);
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(13);
      height: 3px;
      background-color: var(--bg-wht, #fff);
      border-radius: 9999px;
      transition: var(--transit-default, all .4s ease-out);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
      background-color: var(--bg-sub, #ddd);
      &::after {
        opacity: 0;
      }
    }
  }
}
.faq_item .ttl-03 {
  margin-bottom: 0 !important;
  font-size: rem(18);
  padding-left: perc(36,18,em);
  padding-right: perc(30,18,em);
  margin-left: perc(-21,18,em);
  line-height: 1.44;
  letter-spacing: perc(1.44,18,em);
  &::after {
    content: 'Q.';
    position: absolute;
    top: -.25em;
    left: 0;
    font-family: var(--font-en, "Montserrat", sans-serif);
    font-size: rem(22);
    line-height: 1.68;
    letter-spacing: perc(2.2,22,em);
    color: #75bbbb;
  }
}
.faq_item .mce-content-body {
  line-height: 1.75;
  letter-spacing: perc(1.28,16,em);
  padding-top: rem(32);
  padding-bottom: rem(16);
  display: none;
}
.faq_item .mce-content-body img {
  @include mq(sp, min, ps) {
    &.alignleft {
      margin-right: 32px;
    }
    &.alignright {
      margin-left: 32px;
    }
  }
  &.alignleft,
  &.alignright {
    margin-top: 0;
    overflow: hidden;
    max-width: 248px;
    @include mq(sp) {
      float: none !important;
      margin-bottom: 16px !important;
    }
  }
}