@use '../abstracts' as *;
@use 'sass:math';

/* txt
**************************************** */
.txt-lg {
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.txt-sm {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.txt-v {
  @include write-v;
}
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #1D7FB7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #ccc); }
.txt-cta,
.txt-cta * { color: var(--btn-bg-cta, #1D7FB7); }

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, $fz-pc) + rem}
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-en {
  font-family: var(--font-en, 'Montserrat', sans-serif);
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}