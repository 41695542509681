@use '../abstracts' as *;

/* lps_parts--slide
********************************************** */
  .lps_parts--slide .img img {
    display: inline-block;
  }
  .lps_parts--slide .img + .text {
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    @include mq(med) {
      margin-top: 16px;
    }
  }
  .lps_parts--slide .slick-list {
    margin-bottom: 32px;
  }
  .lps_parts--slide .slick-arrow {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    text-decoration: none;
    @include mq(med) {
      width: 56px;
    }
    @include mq(sp) {
      width: 40px;
    }
  }
  .lps_parts--slide .slick-prev { 
    left: -60px;
    @include mq(med) {
      left: 0;
    }
  }
  .lps_parts--slide .slick-next { 
    right: -60px; 
    @include mq(med) {
      right: 0;
    }
  }
  .lps_parts--slide .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include mq(med) {
      position: relative;
      bottom: 4px;
    }
  }
  .lps_parts--slide .slick-arrow::after {
    content: '';
    display: block;
    @include rect(30);
    transform: rotate(45deg);
    color: var(--clr-main, #1D7FB7);
    @include mq(med) {
      @include rect(20);
    }
    @include mq(sp) {
      @include sprect(16);
    }
  }
  .lps_parts--slide .slick-prev::after {
    border-bottom: 2px solid;
    border-left: 2px solid;
  }
  .lps_parts--slide .slick-next::after {
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .lps_parts--slide .slick-dots li {
    display: block;
    @include rect(12);
    background-color: var(--bg-main, #ccc);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
    margin: rem(6);
    &.slick-active,
    &:hover {
      background-color: var(--clr-main, #1D7FB7);
    }
  }