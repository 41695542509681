@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  font-size: rem(15);
  padding: perc(16,15,em) perc(40,15,em);
  letter-spacing: perc(2.1,15,em);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(264px,100%);
  color: var(--btn-clr-main, #1D7FB7);
  text-align: center;
  background-color: var(--btn-bg-main, transparent);
  text-decoration: none;
  @include mq(sp) {
    min-width: 100%;
  }
  &:hover {
    background-color: var(--btn-bg-main-hvr, #f7f7f7);
  }
  svg {
    fill: var(--btn-clr-main, #1D7FB7);
  }
}
.flex > .btn a { min-width: 100%; }
.btn {
  &.txt-ctr a {
    @include auto-margin;
  }
  &.txt-rgt a {
    margin-left: auto;
  }
  &:not(.btn-cta) a {
    border: 2px solid;
  }
}
.btn.btn-next_page a {
  min-width: min(372px,100%);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #fff);
  background-color: var(--btn-bg-wht, transparent);
  &:hover {
    background-color: var(--btn-bg-wht-hvr, #f7f7f7);
  }
  svg {
    fill: var(--btn-clr-wht, #fff);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  color: var(--btn-clr-cta, #fff);
  background-color: var(--btn-bg-cta, #1D7FB7);
  &:hover {
    opacity: .7;
    background-color: var(--btn-bg-cta, #1D7FB7);
  }
  svg {
    fill: var(--btn-clr-cta, #fff);
  }
}

@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}