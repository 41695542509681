@use "../abstracts" as *;

/* bg
********************************************** */
.bg-off_wh {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.bg-main {
  background-color: var(--bg-main, #ccc);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}