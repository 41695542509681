@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: .5em;
}
.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: '';
  position: absolute;
  top: 1em;
  left: -1.3em;
  @include rect(10);
  border: 1px solid var(--clr-main, #1D7FB7);
  background-color: var(--clr-main, #1D7FB7);
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  border-color: #D3D3D3;
}
.box {
  padding: 24px 32px;
  background-color: #f8f8f8;
  border-radius: rem(16);
  font-size: rem(14);
  line-height: 1.71;
  letter-spacing: perc(1.12,14,em);
  @include mq(sp) {
    padding: 24px;
  }
  &:not(:first-child) {
    margin-top: 2.5em;
  }
  &:not(:last-child) {
    margin-bottom: 2.5em;
  }
}
.box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p:not([class*="mgn-btm"]):not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: .25;
}
.mce-content-body blockquote::before {
  background-image: url('../images/common/icon-quote_left.svg');
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url('../images/common/icon-quote_right.svg');
  bottom: 13px;
  right: 10px;
}
.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) { margin-top: 1em; }
.mce-content-body table:not(:last-child) { margin-bottom: 1em; }
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  background-color: var(--bg-wht, #fff);
  vertical-align: top;
}
.mce-content-body th {
  min-width: 120px;
  text-align: left;
}
.mce-content-body sup {
  font-size: rem(12);
  color: #555;
}
.bold {
  font-weight: 700;
  color: var(--clr-sub, #1D7FB7);
}
.thin {
  font-weight: 400;
  font-family: var(--font-secondary);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table { margin: 0; }
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: .25;
  font-size: rem(14);
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft  {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    @include rect(8);
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
}
.mce-content-body.wp-editor {
  padding: 24px !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: .5em;
  }
}
.editor-ttl {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.editor-ttl2 {
  font-size: rem(24);
  color: var(--clr-main, #1D7FB7);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl3 {
  position: relative;
  font-size: rem(18);
  padding-left: 1em;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: perc(5,18,em);
    height: 100%;
    background-color: var(--clr-main, #1D7FB7);
    border-radius: .15em;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: rem(14);
  opacity: .6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: '※';
  }
}

/* テーブル */
.table2 th {
  background-color: var(--clr-main, #1D7FB7);
  color: var(--clr-wht, #fff);
}
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  background-color: transparent;
}
.table1 th {
  color: var(--clr-main, #1D7FB7);
  font-size: rem(18);
}
.table1 th::before,
.table1 td::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}
.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main, #1D7FB7);
}
.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

@include mq(sp) {
  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  .table1.table-sp-block td::before {
    display: none;
  }
}