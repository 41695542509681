@use '../abstracts/' as *;

/* home_message
********************************************** */
.home_message::after {
  top: -283px;
  @include mq(sp) {
    top: spvw(-141.5);
  }
}
.home_message--blc {
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: perc(720,1308,'%') auto;
    column-gap: perc(110,1308,'%');
  }
}
.home_message--txtarea {
  @include mq(sp) {
    margin-bottom: 24px;
  }
  .mce-content-body {
    line-height: 2.13;
    p:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
}
.home_message--imgarea {
  position: relative;
  @include mq(sp, min, ps) {
    height: 480px;
  }
  @include mq(sp) {
    width: min(421px, 100%);
    @include auto-margin;
    transform: scale(.9);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &::before {
    top: perc(30,480,'%');
    right: perc(13,480,'%');
    width: perc(63,480,'%');
    aspect-ratio: 63/64;
    background-image: url(../images/common/icon-kyu.svg);
  }
  &::after {
    bottom: perc(27,480,'%');
    left: perc(-36,480,'%');
    width: perc(59,480,'%');
    aspect-ratio: 59/79;
    background-image: url(../images/common/icon-hari.svg);
  }
  img {
    border-radius: 50%;
    border: solid 4px #2d2d2d;
    @include mq(sp) {
      border-width: min(4px, perc(4,488,vw));
    }
  }
}
.home_message--fukidashi01 {
  top: perc(-8,480,'%');
  left: perc(-58,480,'%');
  width: perc(160,480,'%');
  letter-spacing: perc(1.6,16,em);
  @include mq(sp) {
    border-width: min(4px, perc(4,488,vw));
    font-size: min(sprem(14), perc(14,488,vw));
    &::before {
      height: min(4px, perc(4,488,vw));
    }
  }
}
.home_message--profile {
  position: relative;
  border-radius: 9999px;
  padding: rem(48) rem(56) rem(48) rem(314);
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: 248px auto;
    align-items: flex-start;
  }
  @include mq(sp) {
    padding: 24px;
    border-radius: rem(20);
  }
  .ttl-03 {
    padding-left: perc(45,24,em);
    line-height: 1.83;
    letter-spacing: perc(6.24,24,em);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      @include rect(6,66);
      transform: translateY(-50%);
      background-color: #75bbbb;
      border-radius: 9999px;
    }
  }
  .mce-content-body {
    line-height: 1.71;
    letter-spacing: perc(1.12,14,em);
    p:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}
.home_message--img01 {
  position: absolute;
  top: -28px;
  @include mq(sp, min, ps) {
    left: -45px;
  }
  @include mq(sp) {
    top: -56px;
    right: -24px;
    width: 116px;
    height: auto;
  }
}