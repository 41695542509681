@use '../abstracts' as *;

/* schedule
********************************************** */
.schedule:not(:last-child):not(.schedule-fx) {
  margin-bottom: rem(14);
}
.schedule-fx {
  border-radius: rem(16) 0 0 rem(16);
  background-color: var(--bg-wht, #fff);
  @include mq(med, min, ps) {
    position: fixed;
    bottom: 24px;
    right: 0;
    display: grid;
    grid-template-columns: 66px auto;
    width: 568px;
    height: 284px;
    transform: translateX(500px);
    transition: var(--transit-default, all .4s ease-out);
    z-index: z(top);
    &.active {
      transform: translateX(0);
    }
  }
  @include mq(med) {
    border-radius: rem(16);
  }
  @include mq(sp) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: -24px;
    margin-left: -7%;
    margin-right: -7%;
  }
  .schedule--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(med) {
      display: none;
    }
    &:hover {
      color: var(--clr-sub, #ccc);
    }
    svg {
      margin-bottom: 11px;
      fill: #75bbbb;
    }
  }
  .schedule--blc {
    position: relative;
    padding: 24px 44px 36px 36px;
    @include mq(sp) {
      padding: 16px 7% 24px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 35px;
      bottom: 35px;
      left: 0;
      border-left: 2px solid #ddd;
      width: 2px;
      @include mq(med) {
        display: none;
      }
    }
  }
  .schedule--table {
    background-color: var(--bg-off_wht, #f7f7f7);
    border-radius: rem(8);
  }
  .schedule--table tr:last-child {
    th::before,
    td::before {
      display: none;
    }
  }
  .schedule--table th,
  .schedule--table td {
    &:first-child {
      padding-left: rem(18);
      &::before {
        left: rem(18);
      }
    }
    &:last-child {
      padding-right: rem(18);
      &::before {
        right: rem(18);
      }
    }
    &.txt-main {
      color: #75bbbb;
    }
  }
  .schedule--map {
    border-color: #75bbbb;
  }
  .schedule--info {
    padding: rem(14) 0 rem(8);
  }
}
.schedule--ttl.ttl-03 {
  width: fit-content;
  line-height: 1.83;
  letter-spacing: perc(1.92,24,em);
  padding: 0 perc(7,24,em);
  @include mq(490) {
    font-size: calc(18/490*100vw);
  }
  @include auto-margin;
  &:not(:last-child) {
    margin-bottom: 0 !important;
  }
  span {
    position: relative;
    display: block;
    padding: 0 1em;
  }
}
.schedule--ttl,
.schedule--ttl span {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 2px;
    height: rem(30);
    border-right: 2px solid;
  }
  &::before {
    left: 0;
    transform: rotate(-36deg);
  }
  &::after {
    right: 0;
    transform: rotate(36deg);
  }
}
.schedule--table {
  width: 100%;
}
.schedule--table th,
.schedule--table td {
  position: relative;
  padding: rem(6.5) rem(8);
  text-align: center;
  &:first-child {
    padding-right: rem(18);
    text-align: left;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(185,177,164,.3); 
  }
}
.schedule--info {
  display: flex;
  align-items: center;
  padding-top: rem(18);
}
.schedule--address {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.85;
  letter-spacing: perc(.78,15,em);
  @include mq(sp) {
    .fz-13 {
      display: block;
      width: 100%;
    }
  }
  svg {
    display: block;
    fill: var(--clr-main, #1D7FB7);
    margin-right: rem(9);
  }
}
.schedule--map {
  width: fit-content;
  padding: 0 rem(10);
  border-radius: 9999px;
  color: #75bbbb;
  background-color: var(--bg-wht, #fff);
  border: 1px solid var(--clr-wht, #fff);
  letter-spacing: perc(.72,12,em);
  margin-left: auto;
  white-space: nowrap;
  &:hover {
    color: var(--clr-wht, #fff);
    border-color: #75bbbb;
    background-color: #75bbbb;
  }
}
.schedule {
  .schedule--list {
    padding-top: rem(9);
    border-top: 1px solid #e8e8e8;
  }
  .schedule--list li:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: 23px;
    }
  }
  .schedule--tel svg {
    width: rem(21);
    margin-right: rem(8);
  }
  .schedule--list .schedule--contact {
    width: fit-content;
    min-width: min(210px,100%);
    @include mq(sp) {
      @include auto-margin;
    }
  }
  .schedule--contact a {
    font-size: rem(16);
  }
}