@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img .img-contain {
  max-width: 80%;
}
.hover-lg {
  .post--img {
    overflow: hidden;
  }
  .post--img img {
    transition: all .8s ease-out;
  }
  &:hover .post--img img {
    transform: scale(1.05);
  }
}
.post--link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  background-color: var(--clr-main, #1D7FB7);
  color: #fff;
  padding: .25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  border-radius: 9999px;
}
.cat_list a:hover {
  opacity: .7;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: rem(64);
}
.side--ttl {
  letter-spacing: .1em;
  border-bottom: 2px solid #ddd;
  padding-bottom: .5em;
  margin-bottom: 1em;
}
.side--ttl small {
  display: block;
  opacity: .5;
  margin-top: .2em;
}

/* --- list --- */
.side--list li {
  position: relative;
  padding-left: 1em;
  &::before {
    content: '';
    position: absolute;
    top: 1em;
    left: 0;
    width: .5em;
    height: 2px;
    border-bottom: 2px solid var(--clr-sub, #ccc);
  }
}
.side--list a {
  display: block;
  padding: .2em 0;
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
}

/* --- post --- */
.posts-side .post {
  display: grid;
  grid-template-columns: rem(64) auto;
  column-gap: rem(16);
  &:not(:last-child) {
    margin-bottom: rem(16);
  }
}

/* --- archive --- */
.side_column .archive_list {
  padding-left: 0;
  &::before {
    display: none;
  }
}
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: var(--line-height-default, 1.8);
  padding: .2em 0;
  transition: var(--transit-default, all .4s ease-out);
  &::after {
    content: '';
    display: block;
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
    transition: var(--trans-default, all .4s ease-out);
  }
  &.active::after {
    transform: rotate(315deg);
  }
  &:hover {
    color: var(--clr-main, #1D7FB7);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  &:hover {
    background-color: var(--bg-sub, #ddd);
  }
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: #fff;
  border: 1px solid #ddd;
  color: inherit;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: var(--line-height-default, 1.8);
  letter-spacing: var(--ltr-space-default, .08em);
  transition: var(--transit-default, all .4s ease-out);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: rem(4);
    right: rem(12);
    margin: auto;
    @include rect(8);
    color: var(--clr-main, #1D7FB7);
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: var(--transit-default, all .4s ease-out);
  }
  &:hover {
    background-color: var(--bg-sub, #ddd);
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: 0;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  border-radius: rem(20);
  overflow: hidden;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: perc(300,880,'%') auto;
    align-items: center;
  }
  &:not(:last-child) {
    margin-bottom: rem(40);
  }
}
.posts-blog .post--img {
  @include mq(sp) {
    aspect-ratio: 4/3;
  }
}
.posts-blog .post--txtarea {
  padding: 40px;
  @include mq(sp) {
    padding: 24px;
  }
}
.posts-blog .post--date {
  color: #aaa;
}
.posts-blog .post--ttl {
  letter-spacing: .1em;
}
.posts-blog .post--link:not([href="javascript:void(0);"]):hover,
.posts-side .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list.slick-slider {
  @include mq(sp, min, ps) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: rem(24);
      margin: auto;
      @include rect(8);
      color: #75bbbb;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
    }
  }
}
.news_list .post {
  padding-bottom: 1em;
  padding-top: 1em;
  @include mq(sp) {
    display: block;
  }
}
.news_list.slick-slider .post {
  @include mq(sp, min, ps) {
    display: flex !important;
    padding-top: .3em;
    padding-bottom: .3em;
  }
}
.news_list:not(.slick-slider) .post {
  border-bottom: 1px solid #ddd;
  &:first-child {
    border-top: 1px solid #ddd;
  }
  .post--link:not([href="javascript:void(0);"])::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: rem(24);
    margin: auto;
    @include rect(8);
    color: #75bbbb;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }
}
.news_list .post--info {
  @include mq(sp, min, ps) {
    margin-right: 1em;
  }
  @include mq(sp) {
    margin-bottom: .5em;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1D7FB7);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}
