@use '../abstracts/' as *;

/* home_news
********************************************** */
.home_news {
  padding-bottom: 0 !important;
  @include mq(sp, min, ps) {
    margin-top: -102px;
    padding-bottom: 24px !important;
    .news_list {
      max-width: 480px;
      height: 40px;
      background-color: var(--bg-wht, #fff);
      border-radius: 9999px;
      padding: 0 32px;
      margin-left: 192px;
    }
    .news_list--ttl {
      margin-left: 208px;
    }
  }
  @include mq(sp) {
    padding-top: 40px;
    .news_list {
      box-shadow: none;
      margin-bottom: 24px;
    }
  }
  .news_list--ttl {
    line-height: 2.85;
    letter-spacing: perc(1.3,13,em);
    @include mq(sp) {
      font-size: sprem(24);
      color: var(--clr-main, #1D7FB7);
      text-align: center;
    }
  }
}