@use '../abstracts' as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm { max-width: 960px; }
.inner-xs { max-width: 720px; }
.inner-md { max-width: 1400px; }
.inner-md2 { max-width: 1320px; }
.inner-md3 { max-width: 1308px; }
.inner-lg { max-width: 1440px; }
.inner-xl {
  max-width: perc(1720,1920,'%');
  @include mq(med) {
    max-width: 90%;
  }
  @include mq(sp) {
    max-width: 86%;
  }
}