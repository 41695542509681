@use '../abstracts' as *;

/* --- blc --- */
.blc:not(:last-child) {
  margin-bottom: 64px;
  @include mq(sp) {
    margin-bottom: 48px;
  }
}
.blc-sm:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 24px;
  }
}
.blc-lg:not(:last-child) {
  margin-bottom: 80px;
  @include mq(sp) {
    margin-bottom: 64px;
  }
}