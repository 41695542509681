@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-image: url(../images/common/bg-pt.png);
}
.page_ttl--bg-wrap {
  height: 500px;
  @include mq(sp) {
    height: 120px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.page_ttl--list_wrap {
  @include mq(sp) {
    padding-top: 32px;
  }
}
.page_ttl--list {
  @include mq(sp) {
    gap: 4px; 
  }
}
.page_ttl--list li {
  @include mq(sp, min, ps) {
    min-height: 96px;
  }
  @include mq(sp) {
    width: calc(50% - 2px);
  }
}
.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: .5em 2.5em;
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    padding: 1em 1.5em;
    justify-content: flex-start;
    text-align: left;
    background-color: var(--bg-wht, #fff);
    border-radius: rem(5);
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: rem(30);
    transform: translateY(-50%);
    @include rect(12,6);
    background-color: var(--clr-body, #000);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: var(--transit-default, all .4s ease-out);
    @include mq(sp) {
      right: sprem(10);
    }
  }
  &:hover {
    color: var(--clr-main, #1D7FB7);
    &::before {
      background-color: var(--clr-main, #1D7FB7);
    }
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  position: relative;
}
.ttl-01:not([class*="mgn-btm"]),
.ttl-02:not([class*="mgn-btm"]),
.ttl-03:not([class*="mgn-btm"]):not(:last-child) {
  margin-bottom: 1em;
}
.ttl-01 {
  font-size: rem(40);
  letter-spacing: perc(7.2,40,em);
  @include mq(sp) {
    font-size: sprem(28);
    &.txt-ctr-sp::after {
      @include auto-margin;
    }
  }
  &:not(.txt-ctr)::after {
    content: '';
    display: block;
    width: rem(24);
    height: 2px;
    background-color: #ddd;
    margin-top: rem(16);
  }
  &.txt-ctr::before {
    content: '';
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-image: url(../images/common/icon-harikyu.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 87px;
    aspect-ratio: 87/63;
    margin-bottom: 20px;
  }
  .font-en {
    display: block;
    font-size: rem(13);
    color: #75bbbb;
    text-transform: uppercase;
    line-height: 2.85;
    letter-spacing: perc(1.3,13,em);
    font-weight: 500;
  }
}
.section-line01 .ttl-01 .font-en {
  color: var(--clr-wht, #fff);
}
.section-line02 .ttl-01 .font-en {
  color: var(--clr-main, #1D7FB7);
}
.ttl-02 {
  font-size: rem(32);
  line-height: 1.56;
  letter-spacing: perc(7.04,32,em);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-03 {
  font-size: rem(24);
  line-height: 1.83;
  letter-spacing: perc(4.8,24,em);
  @include mq(sp) {
    font-size: sprem(20);
  }
}