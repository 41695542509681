@charset "UTF-8";
:root {
  --ltr-space-default: .08em;
  --line-height-default: 1.75;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "秀英丸ゴシック B", "Shuei MaruGo B";
  --font-secondary: "秀英丸ゴシック L", "Shuei MaruGo L";
  --font-jp: "秀英丸ゴシック B", "Shuei MaruGo B";
  --font-en: "Sen", sans-serif;
  --font-noto-sans: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","メイリオ", "Meiryo", "游ゴシック", "Yu Gothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #006463;
  --clr-sub: #4aa2a2;
  --clr-body: #171923;
  --clr-dark-blue: #101560;
  --clr-link: #222;
  --clr-wht: #fff;
  --clr-blk: #000;
  --bg-main: #afd7d7;
  --bg-sub: #c3e2e2;
  --bg-wht: #fff;
  --bg-off_wht: #f3f4ee;
  --bg-blk: #000;
  --btn-clr-main: var(--clr-main);
  --btn-bg-main: transparent;
  --btn-bg-main-hvr: var(--bg-sub);
  --btn-clr-wht: var(--bg-wht);
  --btn-bg-wht: transparent;
  --btn-bg-wht-hvr: var(--bg-off_wht);
  --btn-clr-cta: var(--clr-wht);
  --btn-bg-cta: #f87567;
  --brd-main: #006463;
  --transit-default: all .4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #222;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #222;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #222;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #222;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #222);
  height: 100%;
  padding-top: 152px;
  word-break: break-word;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 1080px;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 64px;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}

.win body {
  -webkit-text-stroke: 0.2px;
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #222);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}

svg {
  transition: var(--transit-default, all 0.4s ease-out);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

.fukidashi {
  position: absolute;
  width: 160px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid 4px #2d2d2d;
  background-color: var(--bg-wht, #fff);
  border-radius: 50%;
  line-height: 1.75;
  letter-spacing: 0.18em;
}
.fukidashi::before {
  content: "";
  position: absolute;
  width: 30.625%;
  aspect-ratio: 49/4;
  background-color: var(--clr-body, #333);
}

.fukidashi-lt::before,
.fukidashi-rt::before {
  top: -4.375%;
}

.fukidashi-lb::before,
.fukidashi-rb::before {
  bottom: -4.375%;
}

.fukidashi-lt::before,
.fukidashi-rt::before {
  top: -4.375%;
}

.fukidashi-lt::before,
.fukidashi-lb::before {
  left: -5.625%;
}

.fukidashi-rt::before,
.fukidashi-rb::before {
  right: -5.625%;
}

.fukidashi-lt::before,
.fukidashi-rb::before {
  transform: rotate(44deg);
}

.fukidashi-rt::before,
.fukidashi-lb::before {
  transform: rotate(-44deg);
}

.shadow {
  box-shadow: 0 0 1rem rgba(0, 100, 99, 0.2);
}

.deco_wrap,
.deco {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.deco_wrap {
  width: 100%;
  overflow: hidden;
}

.deco {
  width: 1920px;
}
@media screen and (max-width: 47.9375em) {
  .deco {
    width: 100%;
  }
}

.deco * {
  position: absolute;
}

.wrapper {
  overflow: hidden;
}

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}

.mce-content-body ul li {
  list-style: none;
}

.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1em;
  left: -1.3em;
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid var(--clr-main, #1D7FB7);
  background-color: var(--clr-main, #1D7FB7);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  border-color: #D3D3D3;
}

.box {
  padding: 24px 32px;
  background-color: #f8f8f8;
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 47.9375em) {
  .box {
    padding: 24px;
  }
}
.box:not(:first-child) {
  margin-top: 2.5em;
}
.box:not(:last-child) {
  margin-bottom: 2.5em;
}

.box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}

.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}

.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}

.mce-content-body a {
  text-decoration: underline;
}

.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}

.mce-content-body table {
  border: none;
  height: auto !important;
}

.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  background-color: var(--bg-wht, #fff);
  vertical-align: top;
}

.mce-content-body th {
  min-width: 120px;
  text-align: left;
}

.mce-content-body sup {
  font-size: 0.75rem;
  color: #555;
}

.bold {
  font-weight: 700;
  color: var(--clr-sub, #1D7FB7);
}

.thin {
  font-weight: 400;
  font-family: var(--font-secondary);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}

.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    width: 0.5rem;
    height: 0.5rem;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  padding: 24px !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 0.5em;
}

.editor-ttl {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.7142857143rem;
  }
}

.editor-ttl2 {
  font-size: 1.5rem;
  color: var(--clr-main, #1D7FB7);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl3 {
  position: relative;
  font-size: 1.125rem;
  padding-left: 1em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}
.editor-ttl3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2777777778em;
  height: 100%;
  background-color: var(--clr-main, #1D7FB7);
  border-radius: 0.15em;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem;
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table2 th {
  background-color: var(--clr-main, #1D7FB7);
  color: var(--clr-wht, #fff);
}

.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  background-color: transparent;
}

.table1 th {
  color: var(--clr-main, #1D7FB7);
  font-size: 1.125rem;
}

.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}

.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main, #1D7FB7);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  .table1.table-sp-block td::before {
    display: none;
  }
}
/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.txt-v {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}

.txt-main,
.txt-main * {
  color: var(--clr-main, #1D7FB7);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #ccc);
}

.txt-cta,
.txt-cta * {
  color: var(--btn-bg-cta, #1D7FB7);
}

/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}

.font-en {
  font-family: var(--font-en, "Montserrat", sans-serif);
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}
/* bg
********************************************** */
.bg-off_wh {
  background-color: var(--bg-off_wht, #f7f7f7);
}

.bg-main {
  background-color: var(--bg-main, #ccc);
}

.bg-sub {
  background-color: #eee;
}

.bg-wh {
  background-color: var(--bg-wht, #fff);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

/* --- margin 　古い・削除--- */
.mgn-btm8 {
  margin-bottom: 8px;
}

.mgn-btm16 {
  margin-bottom: 16px;
}

.mgn-btm24 {
  margin-bottom: 24px;
}

.mgn-btm32 {
  margin-bottom: 32px;
}

.mgn-btm40 {
  margin-bottom: 40px;
}

.mgn-btm48 {
  margin-bottom: 48px;
}

.mgn-btm56 {
  margin-bottom: 56px;
}

.mgn-btm64 {
  margin-bottom: 64px;
}

.mgn-btm72 {
  margin-bottom: 72px;
}

.mgn-btm80 {
  margin-bottom: 80px;
}

.mgn-0 {
  margin: 0 rem;
}

.mgn-top0 {
  margin-top: 0 rem;
}

.mgn-rgt0 {
  margin-right: 0 rem;
}

.mgn-btm0 {
  margin-bottom: 0 rem;
}

.mgn-lft0 {
  margin-left: 0 rem;
}

.mgn-1 {
  margin: 0.0625 rem;
}

.mgn-top1 {
  margin-top: 0.0625 rem;
}

.mgn-rgt1 {
  margin-right: 0.0625 rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625 rem;
}

.mgn-lft1 {
  margin-left: 0.0625 rem;
}

.mgn-2 {
  margin: 0.125 rem;
}

.mgn-top2 {
  margin-top: 0.125 rem;
}

.mgn-rgt2 {
  margin-right: 0.125 rem;
}

.mgn-btm2 {
  margin-bottom: 0.125 rem;
}

.mgn-lft2 {
  margin-left: 0.125 rem;
}

.mgn-3 {
  margin: 0.1875 rem;
}

.mgn-top3 {
  margin-top: 0.1875 rem;
}

.mgn-rgt3 {
  margin-right: 0.1875 rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875 rem;
}

.mgn-lft3 {
  margin-left: 0.1875 rem;
}

.mgn-4 {
  margin: 0.25 rem;
}

.mgn-top4 {
  margin-top: 0.25 rem;
}

.mgn-rgt4 {
  margin-right: 0.25 rem;
}

.mgn-btm4 {
  margin-bottom: 0.25 rem;
}

.mgn-lft4 {
  margin-left: 0.25 rem;
}

.mgn-5 {
  margin: 0.3125 rem;
}

.mgn-top5 {
  margin-top: 0.3125 rem;
}

.mgn-rgt5 {
  margin-right: 0.3125 rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125 rem;
}

.mgn-lft5 {
  margin-left: 0.3125 rem;
}

.mgn-6 {
  margin: 0.375 rem;
}

.mgn-top6 {
  margin-top: 0.375 rem;
}

.mgn-rgt6 {
  margin-right: 0.375 rem;
}

.mgn-btm6 {
  margin-bottom: 0.375 rem;
}

.mgn-lft6 {
  margin-left: 0.375 rem;
}

.mgn-7 {
  margin: 0.4375 rem;
}

.mgn-top7 {
  margin-top: 0.4375 rem;
}

.mgn-rgt7 {
  margin-right: 0.4375 rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375 rem;
}

.mgn-lft7 {
  margin-left: 0.4375 rem;
}

.mgn-8 {
  margin: 0.5 rem;
}

.mgn-top8 {
  margin-top: 0.5 rem;
}

.mgn-rgt8 {
  margin-right: 0.5 rem;
}

.mgn-btm8 {
  margin-bottom: 0.5 rem;
}

.mgn-lft8 {
  margin-left: 0.5 rem;
}

.mgn-9 {
  margin: 0.5625 rem;
}

.mgn-top9 {
  margin-top: 0.5625 rem;
}

.mgn-rgt9 {
  margin-right: 0.5625 rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625 rem;
}

.mgn-lft9 {
  margin-left: 0.5625 rem;
}

.mgn-10 {
  margin: 0.625 rem;
}

.mgn-top10 {
  margin-top: 0.625 rem;
}

.mgn-rgt10 {
  margin-right: 0.625 rem;
}

.mgn-btm10 {
  margin-bottom: 0.625 rem;
}

.mgn-lft10 {
  margin-left: 0.625 rem;
}

.mgn-11 {
  margin: 0.6875 rem;
}

.mgn-top11 {
  margin-top: 0.6875 rem;
}

.mgn-rgt11 {
  margin-right: 0.6875 rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875 rem;
}

.mgn-lft11 {
  margin-left: 0.6875 rem;
}

.mgn-12 {
  margin: 0.75 rem;
}

.mgn-top12 {
  margin-top: 0.75 rem;
}

.mgn-rgt12 {
  margin-right: 0.75 rem;
}

.mgn-btm12 {
  margin-bottom: 0.75 rem;
}

.mgn-lft12 {
  margin-left: 0.75 rem;
}

.mgn-13 {
  margin: 0.8125 rem;
}

.mgn-top13 {
  margin-top: 0.8125 rem;
}

.mgn-rgt13 {
  margin-right: 0.8125 rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125 rem;
}

.mgn-lft13 {
  margin-left: 0.8125 rem;
}

.mgn-14 {
  margin: 0.875 rem;
}

.mgn-top14 {
  margin-top: 0.875 rem;
}

.mgn-rgt14 {
  margin-right: 0.875 rem;
}

.mgn-btm14 {
  margin-bottom: 0.875 rem;
}

.mgn-lft14 {
  margin-left: 0.875 rem;
}

.mgn-15 {
  margin: 0.9375 rem;
}

.mgn-top15 {
  margin-top: 0.9375 rem;
}

.mgn-rgt15 {
  margin-right: 0.9375 rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375 rem;
}

.mgn-lft15 {
  margin-left: 0.9375 rem;
}

.mgn-16 {
  margin: 1 rem;
}

.mgn-top16 {
  margin-top: 1 rem;
}

.mgn-rgt16 {
  margin-right: 1 rem;
}

.mgn-btm16 {
  margin-bottom: 1 rem;
}

.mgn-lft16 {
  margin-left: 1 rem;
}

.mgn-17 {
  margin: 1.0625 rem;
}

.mgn-top17 {
  margin-top: 1.0625 rem;
}

.mgn-rgt17 {
  margin-right: 1.0625 rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625 rem;
}

.mgn-lft17 {
  margin-left: 1.0625 rem;
}

.mgn-18 {
  margin: 1.125 rem;
}

.mgn-top18 {
  margin-top: 1.125 rem;
}

.mgn-rgt18 {
  margin-right: 1.125 rem;
}

.mgn-btm18 {
  margin-bottom: 1.125 rem;
}

.mgn-lft18 {
  margin-left: 1.125 rem;
}

.mgn-19 {
  margin: 1.1875 rem;
}

.mgn-top19 {
  margin-top: 1.1875 rem;
}

.mgn-rgt19 {
  margin-right: 1.1875 rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875 rem;
}

.mgn-lft19 {
  margin-left: 1.1875 rem;
}

.mgn-20 {
  margin: 1.25 rem;
}

.mgn-top20 {
  margin-top: 1.25 rem;
}

.mgn-rgt20 {
  margin-right: 1.25 rem;
}

.mgn-btm20 {
  margin-bottom: 1.25 rem;
}

.mgn-lft20 {
  margin-left: 1.25 rem;
}

.mgn-21 {
  margin: 1.3125 rem;
}

.mgn-top21 {
  margin-top: 1.3125 rem;
}

.mgn-rgt21 {
  margin-right: 1.3125 rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125 rem;
}

.mgn-lft21 {
  margin-left: 1.3125 rem;
}

.mgn-22 {
  margin: 1.375 rem;
}

.mgn-top22 {
  margin-top: 1.375 rem;
}

.mgn-rgt22 {
  margin-right: 1.375 rem;
}

.mgn-btm22 {
  margin-bottom: 1.375 rem;
}

.mgn-lft22 {
  margin-left: 1.375 rem;
}

.mgn-23 {
  margin: 1.4375 rem;
}

.mgn-top23 {
  margin-top: 1.4375 rem;
}

.mgn-rgt23 {
  margin-right: 1.4375 rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375 rem;
}

.mgn-lft23 {
  margin-left: 1.4375 rem;
}

.mgn-24 {
  margin: 1.5 rem;
}

.mgn-top24 {
  margin-top: 1.5 rem;
}

.mgn-rgt24 {
  margin-right: 1.5 rem;
}

.mgn-btm24 {
  margin-bottom: 1.5 rem;
}

.mgn-lft24 {
  margin-left: 1.5 rem;
}

.mgn-25 {
  margin: 1.5625 rem;
}

.mgn-top25 {
  margin-top: 1.5625 rem;
}

.mgn-rgt25 {
  margin-right: 1.5625 rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625 rem;
}

.mgn-lft25 {
  margin-left: 1.5625 rem;
}

.mgn-26 {
  margin: 1.625 rem;
}

.mgn-top26 {
  margin-top: 1.625 rem;
}

.mgn-rgt26 {
  margin-right: 1.625 rem;
}

.mgn-btm26 {
  margin-bottom: 1.625 rem;
}

.mgn-lft26 {
  margin-left: 1.625 rem;
}

.mgn-27 {
  margin: 1.6875 rem;
}

.mgn-top27 {
  margin-top: 1.6875 rem;
}

.mgn-rgt27 {
  margin-right: 1.6875 rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875 rem;
}

.mgn-lft27 {
  margin-left: 1.6875 rem;
}

.mgn-28 {
  margin: 1.75 rem;
}

.mgn-top28 {
  margin-top: 1.75 rem;
}

.mgn-rgt28 {
  margin-right: 1.75 rem;
}

.mgn-btm28 {
  margin-bottom: 1.75 rem;
}

.mgn-lft28 {
  margin-left: 1.75 rem;
}

.mgn-29 {
  margin: 1.8125 rem;
}

.mgn-top29 {
  margin-top: 1.8125 rem;
}

.mgn-rgt29 {
  margin-right: 1.8125 rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125 rem;
}

.mgn-lft29 {
  margin-left: 1.8125 rem;
}

.mgn-30 {
  margin: 1.875 rem;
}

.mgn-top30 {
  margin-top: 1.875 rem;
}

.mgn-rgt30 {
  margin-right: 1.875 rem;
}

.mgn-btm30 {
  margin-bottom: 1.875 rem;
}

.mgn-lft30 {
  margin-left: 1.875 rem;
}

.mgn-31 {
  margin: 1.9375 rem;
}

.mgn-top31 {
  margin-top: 1.9375 rem;
}

.mgn-rgt31 {
  margin-right: 1.9375 rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375 rem;
}

.mgn-lft31 {
  margin-left: 1.9375 rem;
}

.mgn-32 {
  margin: 2 rem;
}

.mgn-top32 {
  margin-top: 2 rem;
}

.mgn-rgt32 {
  margin-right: 2 rem;
}

.mgn-btm32 {
  margin-bottom: 2 rem;
}

.mgn-lft32 {
  margin-left: 2 rem;
}

.mgn-33 {
  margin: 2.0625 rem;
}

.mgn-top33 {
  margin-top: 2.0625 rem;
}

.mgn-rgt33 {
  margin-right: 2.0625 rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625 rem;
}

.mgn-lft33 {
  margin-left: 2.0625 rem;
}

.mgn-34 {
  margin: 2.125 rem;
}

.mgn-top34 {
  margin-top: 2.125 rem;
}

.mgn-rgt34 {
  margin-right: 2.125 rem;
}

.mgn-btm34 {
  margin-bottom: 2.125 rem;
}

.mgn-lft34 {
  margin-left: 2.125 rem;
}

.mgn-35 {
  margin: 2.1875 rem;
}

.mgn-top35 {
  margin-top: 2.1875 rem;
}

.mgn-rgt35 {
  margin-right: 2.1875 rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875 rem;
}

.mgn-lft35 {
  margin-left: 2.1875 rem;
}

.mgn-36 {
  margin: 2.25 rem;
}

.mgn-top36 {
  margin-top: 2.25 rem;
}

.mgn-rgt36 {
  margin-right: 2.25 rem;
}

.mgn-btm36 {
  margin-bottom: 2.25 rem;
}

.mgn-lft36 {
  margin-left: 2.25 rem;
}

.mgn-37 {
  margin: 2.3125 rem;
}

.mgn-top37 {
  margin-top: 2.3125 rem;
}

.mgn-rgt37 {
  margin-right: 2.3125 rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125 rem;
}

.mgn-lft37 {
  margin-left: 2.3125 rem;
}

.mgn-38 {
  margin: 2.375 rem;
}

.mgn-top38 {
  margin-top: 2.375 rem;
}

.mgn-rgt38 {
  margin-right: 2.375 rem;
}

.mgn-btm38 {
  margin-bottom: 2.375 rem;
}

.mgn-lft38 {
  margin-left: 2.375 rem;
}

.mgn-39 {
  margin: 2.4375 rem;
}

.mgn-top39 {
  margin-top: 2.4375 rem;
}

.mgn-rgt39 {
  margin-right: 2.4375 rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375 rem;
}

.mgn-lft39 {
  margin-left: 2.4375 rem;
}

.mgn-40 {
  margin: 2.5 rem;
}

.mgn-top40 {
  margin-top: 2.5 rem;
}

.mgn-rgt40 {
  margin-right: 2.5 rem;
}

.mgn-btm40 {
  margin-bottom: 2.5 rem;
}

.mgn-lft40 {
  margin-left: 2.5 rem;
}

.mgn-41 {
  margin: 2.5625 rem;
}

.mgn-top41 {
  margin-top: 2.5625 rem;
}

.mgn-rgt41 {
  margin-right: 2.5625 rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625 rem;
}

.mgn-lft41 {
  margin-left: 2.5625 rem;
}

.mgn-42 {
  margin: 2.625 rem;
}

.mgn-top42 {
  margin-top: 2.625 rem;
}

.mgn-rgt42 {
  margin-right: 2.625 rem;
}

.mgn-btm42 {
  margin-bottom: 2.625 rem;
}

.mgn-lft42 {
  margin-left: 2.625 rem;
}

.mgn-43 {
  margin: 2.6875 rem;
}

.mgn-top43 {
  margin-top: 2.6875 rem;
}

.mgn-rgt43 {
  margin-right: 2.6875 rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875 rem;
}

.mgn-lft43 {
  margin-left: 2.6875 rem;
}

.mgn-44 {
  margin: 2.75 rem;
}

.mgn-top44 {
  margin-top: 2.75 rem;
}

.mgn-rgt44 {
  margin-right: 2.75 rem;
}

.mgn-btm44 {
  margin-bottom: 2.75 rem;
}

.mgn-lft44 {
  margin-left: 2.75 rem;
}

.mgn-45 {
  margin: 2.8125 rem;
}

.mgn-top45 {
  margin-top: 2.8125 rem;
}

.mgn-rgt45 {
  margin-right: 2.8125 rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125 rem;
}

.mgn-lft45 {
  margin-left: 2.8125 rem;
}

.mgn-46 {
  margin: 2.875 rem;
}

.mgn-top46 {
  margin-top: 2.875 rem;
}

.mgn-rgt46 {
  margin-right: 2.875 rem;
}

.mgn-btm46 {
  margin-bottom: 2.875 rem;
}

.mgn-lft46 {
  margin-left: 2.875 rem;
}

.mgn-47 {
  margin: 2.9375 rem;
}

.mgn-top47 {
  margin-top: 2.9375 rem;
}

.mgn-rgt47 {
  margin-right: 2.9375 rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375 rem;
}

.mgn-lft47 {
  margin-left: 2.9375 rem;
}

.mgn-48 {
  margin: 3 rem;
}

.mgn-top48 {
  margin-top: 3 rem;
}

.mgn-rgt48 {
  margin-right: 3 rem;
}

.mgn-btm48 {
  margin-bottom: 3 rem;
}

.mgn-lft48 {
  margin-left: 3 rem;
}

.mgn-49 {
  margin: 3.0625 rem;
}

.mgn-top49 {
  margin-top: 3.0625 rem;
}

.mgn-rgt49 {
  margin-right: 3.0625 rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625 rem;
}

.mgn-lft49 {
  margin-left: 3.0625 rem;
}

.mgn-50 {
  margin: 3.125 rem;
}

.mgn-top50 {
  margin-top: 3.125 rem;
}

.mgn-rgt50 {
  margin-right: 3.125 rem;
}

.mgn-btm50 {
  margin-bottom: 3.125 rem;
}

.mgn-lft50 {
  margin-left: 3.125 rem;
}

.mgn-51 {
  margin: 3.1875 rem;
}

.mgn-top51 {
  margin-top: 3.1875 rem;
}

.mgn-rgt51 {
  margin-right: 3.1875 rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875 rem;
}

.mgn-lft51 {
  margin-left: 3.1875 rem;
}

.mgn-52 {
  margin: 3.25 rem;
}

.mgn-top52 {
  margin-top: 3.25 rem;
}

.mgn-rgt52 {
  margin-right: 3.25 rem;
}

.mgn-btm52 {
  margin-bottom: 3.25 rem;
}

.mgn-lft52 {
  margin-left: 3.25 rem;
}

.mgn-53 {
  margin: 3.3125 rem;
}

.mgn-top53 {
  margin-top: 3.3125 rem;
}

.mgn-rgt53 {
  margin-right: 3.3125 rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125 rem;
}

.mgn-lft53 {
  margin-left: 3.3125 rem;
}

.mgn-54 {
  margin: 3.375 rem;
}

.mgn-top54 {
  margin-top: 3.375 rem;
}

.mgn-rgt54 {
  margin-right: 3.375 rem;
}

.mgn-btm54 {
  margin-bottom: 3.375 rem;
}

.mgn-lft54 {
  margin-left: 3.375 rem;
}

.mgn-55 {
  margin: 3.4375 rem;
}

.mgn-top55 {
  margin-top: 3.4375 rem;
}

.mgn-rgt55 {
  margin-right: 3.4375 rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375 rem;
}

.mgn-lft55 {
  margin-left: 3.4375 rem;
}

.mgn-56 {
  margin: 3.5 rem;
}

.mgn-top56 {
  margin-top: 3.5 rem;
}

.mgn-rgt56 {
  margin-right: 3.5 rem;
}

.mgn-btm56 {
  margin-bottom: 3.5 rem;
}

.mgn-lft56 {
  margin-left: 3.5 rem;
}

.mgn-57 {
  margin: 3.5625 rem;
}

.mgn-top57 {
  margin-top: 3.5625 rem;
}

.mgn-rgt57 {
  margin-right: 3.5625 rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625 rem;
}

.mgn-lft57 {
  margin-left: 3.5625 rem;
}

.mgn-58 {
  margin: 3.625 rem;
}

.mgn-top58 {
  margin-top: 3.625 rem;
}

.mgn-rgt58 {
  margin-right: 3.625 rem;
}

.mgn-btm58 {
  margin-bottom: 3.625 rem;
}

.mgn-lft58 {
  margin-left: 3.625 rem;
}

.mgn-59 {
  margin: 3.6875 rem;
}

.mgn-top59 {
  margin-top: 3.6875 rem;
}

.mgn-rgt59 {
  margin-right: 3.6875 rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875 rem;
}

.mgn-lft59 {
  margin-left: 3.6875 rem;
}

.mgn-60 {
  margin: 3.75 rem;
}

.mgn-top60 {
  margin-top: 3.75 rem;
}

.mgn-rgt60 {
  margin-right: 3.75 rem;
}

.mgn-btm60 {
  margin-bottom: 3.75 rem;
}

.mgn-lft60 {
  margin-left: 3.75 rem;
}

.mgn-61 {
  margin: 3.8125 rem;
}

.mgn-top61 {
  margin-top: 3.8125 rem;
}

.mgn-rgt61 {
  margin-right: 3.8125 rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125 rem;
}

.mgn-lft61 {
  margin-left: 3.8125 rem;
}

.mgn-62 {
  margin: 3.875 rem;
}

.mgn-top62 {
  margin-top: 3.875 rem;
}

.mgn-rgt62 {
  margin-right: 3.875 rem;
}

.mgn-btm62 {
  margin-bottom: 3.875 rem;
}

.mgn-lft62 {
  margin-left: 3.875 rem;
}

.mgn-63 {
  margin: 3.9375 rem;
}

.mgn-top63 {
  margin-top: 3.9375 rem;
}

.mgn-rgt63 {
  margin-right: 3.9375 rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375 rem;
}

.mgn-lft63 {
  margin-left: 3.9375 rem;
}

.mgn-64 {
  margin: 4 rem;
}

.mgn-top64 {
  margin-top: 4 rem;
}

.mgn-rgt64 {
  margin-right: 4 rem;
}

.mgn-btm64 {
  margin-bottom: 4 rem;
}

.mgn-lft64 {
  margin-left: 4 rem;
}

.mgn-65 {
  margin: 4.0625 rem;
}

.mgn-top65 {
  margin-top: 4.0625 rem;
}

.mgn-rgt65 {
  margin-right: 4.0625 rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625 rem;
}

.mgn-lft65 {
  margin-left: 4.0625 rem;
}

.mgn-66 {
  margin: 4.125 rem;
}

.mgn-top66 {
  margin-top: 4.125 rem;
}

.mgn-rgt66 {
  margin-right: 4.125 rem;
}

.mgn-btm66 {
  margin-bottom: 4.125 rem;
}

.mgn-lft66 {
  margin-left: 4.125 rem;
}

.mgn-67 {
  margin: 4.1875 rem;
}

.mgn-top67 {
  margin-top: 4.1875 rem;
}

.mgn-rgt67 {
  margin-right: 4.1875 rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875 rem;
}

.mgn-lft67 {
  margin-left: 4.1875 rem;
}

.mgn-68 {
  margin: 4.25 rem;
}

.mgn-top68 {
  margin-top: 4.25 rem;
}

.mgn-rgt68 {
  margin-right: 4.25 rem;
}

.mgn-btm68 {
  margin-bottom: 4.25 rem;
}

.mgn-lft68 {
  margin-left: 4.25 rem;
}

.mgn-69 {
  margin: 4.3125 rem;
}

.mgn-top69 {
  margin-top: 4.3125 rem;
}

.mgn-rgt69 {
  margin-right: 4.3125 rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125 rem;
}

.mgn-lft69 {
  margin-left: 4.3125 rem;
}

.mgn-70 {
  margin: 4.375 rem;
}

.mgn-top70 {
  margin-top: 4.375 rem;
}

.mgn-rgt70 {
  margin-right: 4.375 rem;
}

.mgn-btm70 {
  margin-bottom: 4.375 rem;
}

.mgn-lft70 {
  margin-left: 4.375 rem;
}

.mgn-71 {
  margin: 4.4375 rem;
}

.mgn-top71 {
  margin-top: 4.4375 rem;
}

.mgn-rgt71 {
  margin-right: 4.4375 rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375 rem;
}

.mgn-lft71 {
  margin-left: 4.4375 rem;
}

.mgn-72 {
  margin: 4.5 rem;
}

.mgn-top72 {
  margin-top: 4.5 rem;
}

.mgn-rgt72 {
  margin-right: 4.5 rem;
}

.mgn-btm72 {
  margin-bottom: 4.5 rem;
}

.mgn-lft72 {
  margin-left: 4.5 rem;
}

.mgn-73 {
  margin: 4.5625 rem;
}

.mgn-top73 {
  margin-top: 4.5625 rem;
}

.mgn-rgt73 {
  margin-right: 4.5625 rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625 rem;
}

.mgn-lft73 {
  margin-left: 4.5625 rem;
}

.mgn-74 {
  margin: 4.625 rem;
}

.mgn-top74 {
  margin-top: 4.625 rem;
}

.mgn-rgt74 {
  margin-right: 4.625 rem;
}

.mgn-btm74 {
  margin-bottom: 4.625 rem;
}

.mgn-lft74 {
  margin-left: 4.625 rem;
}

.mgn-75 {
  margin: 4.6875 rem;
}

.mgn-top75 {
  margin-top: 4.6875 rem;
}

.mgn-rgt75 {
  margin-right: 4.6875 rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875 rem;
}

.mgn-lft75 {
  margin-left: 4.6875 rem;
}

.mgn-76 {
  margin: 4.75 rem;
}

.mgn-top76 {
  margin-top: 4.75 rem;
}

.mgn-rgt76 {
  margin-right: 4.75 rem;
}

.mgn-btm76 {
  margin-bottom: 4.75 rem;
}

.mgn-lft76 {
  margin-left: 4.75 rem;
}

.mgn-77 {
  margin: 4.8125 rem;
}

.mgn-top77 {
  margin-top: 4.8125 rem;
}

.mgn-rgt77 {
  margin-right: 4.8125 rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125 rem;
}

.mgn-lft77 {
  margin-left: 4.8125 rem;
}

.mgn-78 {
  margin: 4.875 rem;
}

.mgn-top78 {
  margin-top: 4.875 rem;
}

.mgn-rgt78 {
  margin-right: 4.875 rem;
}

.mgn-btm78 {
  margin-bottom: 4.875 rem;
}

.mgn-lft78 {
  margin-left: 4.875 rem;
}

.mgn-79 {
  margin: 4.9375 rem;
}

.mgn-top79 {
  margin-top: 4.9375 rem;
}

.mgn-rgt79 {
  margin-right: 4.9375 rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375 rem;
}

.mgn-lft79 {
  margin-left: 4.9375 rem;
}

.mgn-80 {
  margin: 5 rem;
}

.mgn-top80 {
  margin-top: 5 rem;
}

.mgn-rgt80 {
  margin-right: 5 rem;
}

.mgn-btm80 {
  margin-bottom: 5 rem;
}

.mgn-lft80 {
  margin-left: 5 rem;
}

.mgn-81 {
  margin: 5.0625 rem;
}

.mgn-top81 {
  margin-top: 5.0625 rem;
}

.mgn-rgt81 {
  margin-right: 5.0625 rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625 rem;
}

.mgn-lft81 {
  margin-left: 5.0625 rem;
}

.mgn-82 {
  margin: 5.125 rem;
}

.mgn-top82 {
  margin-top: 5.125 rem;
}

.mgn-rgt82 {
  margin-right: 5.125 rem;
}

.mgn-btm82 {
  margin-bottom: 5.125 rem;
}

.mgn-lft82 {
  margin-left: 5.125 rem;
}

.mgn-83 {
  margin: 5.1875 rem;
}

.mgn-top83 {
  margin-top: 5.1875 rem;
}

.mgn-rgt83 {
  margin-right: 5.1875 rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875 rem;
}

.mgn-lft83 {
  margin-left: 5.1875 rem;
}

.mgn-84 {
  margin: 5.25 rem;
}

.mgn-top84 {
  margin-top: 5.25 rem;
}

.mgn-rgt84 {
  margin-right: 5.25 rem;
}

.mgn-btm84 {
  margin-bottom: 5.25 rem;
}

.mgn-lft84 {
  margin-left: 5.25 rem;
}

.mgn-85 {
  margin: 5.3125 rem;
}

.mgn-top85 {
  margin-top: 5.3125 rem;
}

.mgn-rgt85 {
  margin-right: 5.3125 rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125 rem;
}

.mgn-lft85 {
  margin-left: 5.3125 rem;
}

.mgn-86 {
  margin: 5.375 rem;
}

.mgn-top86 {
  margin-top: 5.375 rem;
}

.mgn-rgt86 {
  margin-right: 5.375 rem;
}

.mgn-btm86 {
  margin-bottom: 5.375 rem;
}

.mgn-lft86 {
  margin-left: 5.375 rem;
}

.mgn-87 {
  margin: 5.4375 rem;
}

.mgn-top87 {
  margin-top: 5.4375 rem;
}

.mgn-rgt87 {
  margin-right: 5.4375 rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375 rem;
}

.mgn-lft87 {
  margin-left: 5.4375 rem;
}

.mgn-88 {
  margin: 5.5 rem;
}

.mgn-top88 {
  margin-top: 5.5 rem;
}

.mgn-rgt88 {
  margin-right: 5.5 rem;
}

.mgn-btm88 {
  margin-bottom: 5.5 rem;
}

.mgn-lft88 {
  margin-left: 5.5 rem;
}

.mgn-89 {
  margin: 5.5625 rem;
}

.mgn-top89 {
  margin-top: 5.5625 rem;
}

.mgn-rgt89 {
  margin-right: 5.5625 rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625 rem;
}

.mgn-lft89 {
  margin-left: 5.5625 rem;
}

.mgn-90 {
  margin: 5.625 rem;
}

.mgn-top90 {
  margin-top: 5.625 rem;
}

.mgn-rgt90 {
  margin-right: 5.625 rem;
}

.mgn-btm90 {
  margin-bottom: 5.625 rem;
}

.mgn-lft90 {
  margin-left: 5.625 rem;
}

.mgn-91 {
  margin: 5.6875 rem;
}

.mgn-top91 {
  margin-top: 5.6875 rem;
}

.mgn-rgt91 {
  margin-right: 5.6875 rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875 rem;
}

.mgn-lft91 {
  margin-left: 5.6875 rem;
}

.mgn-92 {
  margin: 5.75 rem;
}

.mgn-top92 {
  margin-top: 5.75 rem;
}

.mgn-rgt92 {
  margin-right: 5.75 rem;
}

.mgn-btm92 {
  margin-bottom: 5.75 rem;
}

.mgn-lft92 {
  margin-left: 5.75 rem;
}

.mgn-93 {
  margin: 5.8125 rem;
}

.mgn-top93 {
  margin-top: 5.8125 rem;
}

.mgn-rgt93 {
  margin-right: 5.8125 rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125 rem;
}

.mgn-lft93 {
  margin-left: 5.8125 rem;
}

.mgn-94 {
  margin: 5.875 rem;
}

.mgn-top94 {
  margin-top: 5.875 rem;
}

.mgn-rgt94 {
  margin-right: 5.875 rem;
}

.mgn-btm94 {
  margin-bottom: 5.875 rem;
}

.mgn-lft94 {
  margin-left: 5.875 rem;
}

.mgn-95 {
  margin: 5.9375 rem;
}

.mgn-top95 {
  margin-top: 5.9375 rem;
}

.mgn-rgt95 {
  margin-right: 5.9375 rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375 rem;
}

.mgn-lft95 {
  margin-left: 5.9375 rem;
}

.mgn-96 {
  margin: 6 rem;
}

.mgn-top96 {
  margin-top: 6 rem;
}

.mgn-rgt96 {
  margin-right: 6 rem;
}

.mgn-btm96 {
  margin-bottom: 6 rem;
}

.mgn-lft96 {
  margin-left: 6 rem;
}

.mgn-97 {
  margin: 6.0625 rem;
}

.mgn-top97 {
  margin-top: 6.0625 rem;
}

.mgn-rgt97 {
  margin-right: 6.0625 rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625 rem;
}

.mgn-lft97 {
  margin-left: 6.0625 rem;
}

.mgn-98 {
  margin: 6.125 rem;
}

.mgn-top98 {
  margin-top: 6.125 rem;
}

.mgn-rgt98 {
  margin-right: 6.125 rem;
}

.mgn-btm98 {
  margin-bottom: 6.125 rem;
}

.mgn-lft98 {
  margin-left: 6.125 rem;
}

.mgn-99 {
  margin: 6.1875 rem;
}

.mgn-top99 {
  margin-top: 6.1875 rem;
}

.mgn-rgt99 {
  margin-right: 6.1875 rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875 rem;
}

.mgn-lft99 {
  margin-left: 6.1875 rem;
}

.mgn-100 {
  margin: 6.25 rem;
}

.mgn-top100 {
  margin-top: 6.25 rem;
}

.mgn-rgt100 {
  margin-right: 6.25 rem;
}

.mgn-btm100 {
  margin-bottom: 6.25 rem;
}

.mgn-lft100 {
  margin-left: 6.25 rem;
}

.mgn-101 {
  margin: 6.3125 rem;
}

.mgn-top101 {
  margin-top: 6.3125 rem;
}

.mgn-rgt101 {
  margin-right: 6.3125 rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125 rem;
}

.mgn-lft101 {
  margin-left: 6.3125 rem;
}

.mgn-102 {
  margin: 6.375 rem;
}

.mgn-top102 {
  margin-top: 6.375 rem;
}

.mgn-rgt102 {
  margin-right: 6.375 rem;
}

.mgn-btm102 {
  margin-bottom: 6.375 rem;
}

.mgn-lft102 {
  margin-left: 6.375 rem;
}

.mgn-103 {
  margin: 6.4375 rem;
}

.mgn-top103 {
  margin-top: 6.4375 rem;
}

.mgn-rgt103 {
  margin-right: 6.4375 rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375 rem;
}

.mgn-lft103 {
  margin-left: 6.4375 rem;
}

.mgn-104 {
  margin: 6.5 rem;
}

.mgn-top104 {
  margin-top: 6.5 rem;
}

.mgn-rgt104 {
  margin-right: 6.5 rem;
}

.mgn-btm104 {
  margin-bottom: 6.5 rem;
}

.mgn-lft104 {
  margin-left: 6.5 rem;
}

.mgn-105 {
  margin: 6.5625 rem;
}

.mgn-top105 {
  margin-top: 6.5625 rem;
}

.mgn-rgt105 {
  margin-right: 6.5625 rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625 rem;
}

.mgn-lft105 {
  margin-left: 6.5625 rem;
}

.mgn-106 {
  margin: 6.625 rem;
}

.mgn-top106 {
  margin-top: 6.625 rem;
}

.mgn-rgt106 {
  margin-right: 6.625 rem;
}

.mgn-btm106 {
  margin-bottom: 6.625 rem;
}

.mgn-lft106 {
  margin-left: 6.625 rem;
}

.mgn-107 {
  margin: 6.6875 rem;
}

.mgn-top107 {
  margin-top: 6.6875 rem;
}

.mgn-rgt107 {
  margin-right: 6.6875 rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875 rem;
}

.mgn-lft107 {
  margin-left: 6.6875 rem;
}

.mgn-108 {
  margin: 6.75 rem;
}

.mgn-top108 {
  margin-top: 6.75 rem;
}

.mgn-rgt108 {
  margin-right: 6.75 rem;
}

.mgn-btm108 {
  margin-bottom: 6.75 rem;
}

.mgn-lft108 {
  margin-left: 6.75 rem;
}

.mgn-109 {
  margin: 6.8125 rem;
}

.mgn-top109 {
  margin-top: 6.8125 rem;
}

.mgn-rgt109 {
  margin-right: 6.8125 rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125 rem;
}

.mgn-lft109 {
  margin-left: 6.8125 rem;
}

.mgn-110 {
  margin: 6.875 rem;
}

.mgn-top110 {
  margin-top: 6.875 rem;
}

.mgn-rgt110 {
  margin-right: 6.875 rem;
}

.mgn-btm110 {
  margin-bottom: 6.875 rem;
}

.mgn-lft110 {
  margin-left: 6.875 rem;
}

.mgn-111 {
  margin: 6.9375 rem;
}

.mgn-top111 {
  margin-top: 6.9375 rem;
}

.mgn-rgt111 {
  margin-right: 6.9375 rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375 rem;
}

.mgn-lft111 {
  margin-left: 6.9375 rem;
}

.mgn-112 {
  margin: 7 rem;
}

.mgn-top112 {
  margin-top: 7 rem;
}

.mgn-rgt112 {
  margin-right: 7 rem;
}

.mgn-btm112 {
  margin-bottom: 7 rem;
}

.mgn-lft112 {
  margin-left: 7 rem;
}

.mgn-113 {
  margin: 7.0625 rem;
}

.mgn-top113 {
  margin-top: 7.0625 rem;
}

.mgn-rgt113 {
  margin-right: 7.0625 rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625 rem;
}

.mgn-lft113 {
  margin-left: 7.0625 rem;
}

.mgn-114 {
  margin: 7.125 rem;
}

.mgn-top114 {
  margin-top: 7.125 rem;
}

.mgn-rgt114 {
  margin-right: 7.125 rem;
}

.mgn-btm114 {
  margin-bottom: 7.125 rem;
}

.mgn-lft114 {
  margin-left: 7.125 rem;
}

.mgn-115 {
  margin: 7.1875 rem;
}

.mgn-top115 {
  margin-top: 7.1875 rem;
}

.mgn-rgt115 {
  margin-right: 7.1875 rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875 rem;
}

.mgn-lft115 {
  margin-left: 7.1875 rem;
}

.mgn-116 {
  margin: 7.25 rem;
}

.mgn-top116 {
  margin-top: 7.25 rem;
}

.mgn-rgt116 {
  margin-right: 7.25 rem;
}

.mgn-btm116 {
  margin-bottom: 7.25 rem;
}

.mgn-lft116 {
  margin-left: 7.25 rem;
}

.mgn-117 {
  margin: 7.3125 rem;
}

.mgn-top117 {
  margin-top: 7.3125 rem;
}

.mgn-rgt117 {
  margin-right: 7.3125 rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125 rem;
}

.mgn-lft117 {
  margin-left: 7.3125 rem;
}

.mgn-118 {
  margin: 7.375 rem;
}

.mgn-top118 {
  margin-top: 7.375 rem;
}

.mgn-rgt118 {
  margin-right: 7.375 rem;
}

.mgn-btm118 {
  margin-bottom: 7.375 rem;
}

.mgn-lft118 {
  margin-left: 7.375 rem;
}

.mgn-119 {
  margin: 7.4375 rem;
}

.mgn-top119 {
  margin-top: 7.4375 rem;
}

.mgn-rgt119 {
  margin-right: 7.4375 rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375 rem;
}

.mgn-lft119 {
  margin-left: 7.4375 rem;
}

.mgn-120 {
  margin: 7.5 rem;
}

.mgn-top120 {
  margin-top: 7.5 rem;
}

.mgn-rgt120 {
  margin-right: 7.5 rem;
}

.mgn-btm120 {
  margin-bottom: 7.5 rem;
}

.mgn-lft120 {
  margin-left: 7.5 rem;
}

.mgn-121 {
  margin: 7.5625 rem;
}

.mgn-top121 {
  margin-top: 7.5625 rem;
}

.mgn-rgt121 {
  margin-right: 7.5625 rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625 rem;
}

.mgn-lft121 {
  margin-left: 7.5625 rem;
}

.mgn-122 {
  margin: 7.625 rem;
}

.mgn-top122 {
  margin-top: 7.625 rem;
}

.mgn-rgt122 {
  margin-right: 7.625 rem;
}

.mgn-btm122 {
  margin-bottom: 7.625 rem;
}

.mgn-lft122 {
  margin-left: 7.625 rem;
}

.mgn-123 {
  margin: 7.6875 rem;
}

.mgn-top123 {
  margin-top: 7.6875 rem;
}

.mgn-rgt123 {
  margin-right: 7.6875 rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875 rem;
}

.mgn-lft123 {
  margin-left: 7.6875 rem;
}

.mgn-124 {
  margin: 7.75 rem;
}

.mgn-top124 {
  margin-top: 7.75 rem;
}

.mgn-rgt124 {
  margin-right: 7.75 rem;
}

.mgn-btm124 {
  margin-bottom: 7.75 rem;
}

.mgn-lft124 {
  margin-left: 7.75 rem;
}

.mgn-125 {
  margin: 7.8125 rem;
}

.mgn-top125 {
  margin-top: 7.8125 rem;
}

.mgn-rgt125 {
  margin-right: 7.8125 rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125 rem;
}

.mgn-lft125 {
  margin-left: 7.8125 rem;
}

.mgn-126 {
  margin: 7.875 rem;
}

.mgn-top126 {
  margin-top: 7.875 rem;
}

.mgn-rgt126 {
  margin-right: 7.875 rem;
}

.mgn-btm126 {
  margin-bottom: 7.875 rem;
}

.mgn-lft126 {
  margin-left: 7.875 rem;
}

.mgn-127 {
  margin: 7.9375 rem;
}

.mgn-top127 {
  margin-top: 7.9375 rem;
}

.mgn-rgt127 {
  margin-right: 7.9375 rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375 rem;
}

.mgn-lft127 {
  margin-left: 7.9375 rem;
}

.mgn-128 {
  margin: 8 rem;
}

.mgn-top128 {
  margin-top: 8 rem;
}

.mgn-rgt128 {
  margin-right: 8 rem;
}

.mgn-btm128 {
  margin-bottom: 8 rem;
}

.mgn-lft128 {
  margin-left: 8 rem;
}

.mgn-129 {
  margin: 8.0625 rem;
}

.mgn-top129 {
  margin-top: 8.0625 rem;
}

.mgn-rgt129 {
  margin-right: 8.0625 rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625 rem;
}

.mgn-lft129 {
  margin-left: 8.0625 rem;
}

.mgn-130 {
  margin: 8.125 rem;
}

.mgn-top130 {
  margin-top: 8.125 rem;
}

.mgn-rgt130 {
  margin-right: 8.125 rem;
}

.mgn-btm130 {
  margin-bottom: 8.125 rem;
}

.mgn-lft130 {
  margin-left: 8.125 rem;
}

.mgn-131 {
  margin: 8.1875 rem;
}

.mgn-top131 {
  margin-top: 8.1875 rem;
}

.mgn-rgt131 {
  margin-right: 8.1875 rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875 rem;
}

.mgn-lft131 {
  margin-left: 8.1875 rem;
}

.mgn-132 {
  margin: 8.25 rem;
}

.mgn-top132 {
  margin-top: 8.25 rem;
}

.mgn-rgt132 {
  margin-right: 8.25 rem;
}

.mgn-btm132 {
  margin-bottom: 8.25 rem;
}

.mgn-lft132 {
  margin-left: 8.25 rem;
}

.mgn-133 {
  margin: 8.3125 rem;
}

.mgn-top133 {
  margin-top: 8.3125 rem;
}

.mgn-rgt133 {
  margin-right: 8.3125 rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125 rem;
}

.mgn-lft133 {
  margin-left: 8.3125 rem;
}

.mgn-134 {
  margin: 8.375 rem;
}

.mgn-top134 {
  margin-top: 8.375 rem;
}

.mgn-rgt134 {
  margin-right: 8.375 rem;
}

.mgn-btm134 {
  margin-bottom: 8.375 rem;
}

.mgn-lft134 {
  margin-left: 8.375 rem;
}

.mgn-135 {
  margin: 8.4375 rem;
}

.mgn-top135 {
  margin-top: 8.4375 rem;
}

.mgn-rgt135 {
  margin-right: 8.4375 rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375 rem;
}

.mgn-lft135 {
  margin-left: 8.4375 rem;
}

.mgn-136 {
  margin: 8.5 rem;
}

.mgn-top136 {
  margin-top: 8.5 rem;
}

.mgn-rgt136 {
  margin-right: 8.5 rem;
}

.mgn-btm136 {
  margin-bottom: 8.5 rem;
}

.mgn-lft136 {
  margin-left: 8.5 rem;
}

.mgn-137 {
  margin: 8.5625 rem;
}

.mgn-top137 {
  margin-top: 8.5625 rem;
}

.mgn-rgt137 {
  margin-right: 8.5625 rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625 rem;
}

.mgn-lft137 {
  margin-left: 8.5625 rem;
}

.mgn-138 {
  margin: 8.625 rem;
}

.mgn-top138 {
  margin-top: 8.625 rem;
}

.mgn-rgt138 {
  margin-right: 8.625 rem;
}

.mgn-btm138 {
  margin-bottom: 8.625 rem;
}

.mgn-lft138 {
  margin-left: 8.625 rem;
}

.mgn-139 {
  margin: 8.6875 rem;
}

.mgn-top139 {
  margin-top: 8.6875 rem;
}

.mgn-rgt139 {
  margin-right: 8.6875 rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875 rem;
}

.mgn-lft139 {
  margin-left: 8.6875 rem;
}

.mgn-140 {
  margin: 8.75 rem;
}

.mgn-top140 {
  margin-top: 8.75 rem;
}

.mgn-rgt140 {
  margin-right: 8.75 rem;
}

.mgn-btm140 {
  margin-bottom: 8.75 rem;
}

.mgn-lft140 {
  margin-left: 8.75 rem;
}

.mgn-141 {
  margin: 8.8125 rem;
}

.mgn-top141 {
  margin-top: 8.8125 rem;
}

.mgn-rgt141 {
  margin-right: 8.8125 rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125 rem;
}

.mgn-lft141 {
  margin-left: 8.8125 rem;
}

.mgn-142 {
  margin: 8.875 rem;
}

.mgn-top142 {
  margin-top: 8.875 rem;
}

.mgn-rgt142 {
  margin-right: 8.875 rem;
}

.mgn-btm142 {
  margin-bottom: 8.875 rem;
}

.mgn-lft142 {
  margin-left: 8.875 rem;
}

.mgn-143 {
  margin: 8.9375 rem;
}

.mgn-top143 {
  margin-top: 8.9375 rem;
}

.mgn-rgt143 {
  margin-right: 8.9375 rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375 rem;
}

.mgn-lft143 {
  margin-left: 8.9375 rem;
}

.mgn-144 {
  margin: 9 rem;
}

.mgn-top144 {
  margin-top: 9 rem;
}

.mgn-rgt144 {
  margin-right: 9 rem;
}

.mgn-btm144 {
  margin-bottom: 9 rem;
}

.mgn-lft144 {
  margin-left: 9 rem;
}

.mgn-145 {
  margin: 9.0625 rem;
}

.mgn-top145 {
  margin-top: 9.0625 rem;
}

.mgn-rgt145 {
  margin-right: 9.0625 rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625 rem;
}

.mgn-lft145 {
  margin-left: 9.0625 rem;
}

.mgn-146 {
  margin: 9.125 rem;
}

.mgn-top146 {
  margin-top: 9.125 rem;
}

.mgn-rgt146 {
  margin-right: 9.125 rem;
}

.mgn-btm146 {
  margin-bottom: 9.125 rem;
}

.mgn-lft146 {
  margin-left: 9.125 rem;
}

.mgn-147 {
  margin: 9.1875 rem;
}

.mgn-top147 {
  margin-top: 9.1875 rem;
}

.mgn-rgt147 {
  margin-right: 9.1875 rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875 rem;
}

.mgn-lft147 {
  margin-left: 9.1875 rem;
}

.mgn-148 {
  margin: 9.25 rem;
}

.mgn-top148 {
  margin-top: 9.25 rem;
}

.mgn-rgt148 {
  margin-right: 9.25 rem;
}

.mgn-btm148 {
  margin-bottom: 9.25 rem;
}

.mgn-lft148 {
  margin-left: 9.25 rem;
}

.mgn-149 {
  margin: 9.3125 rem;
}

.mgn-top149 {
  margin-top: 9.3125 rem;
}

.mgn-rgt149 {
  margin-right: 9.3125 rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125 rem;
}

.mgn-lft149 {
  margin-left: 9.3125 rem;
}

.mgn-150 {
  margin: 9.375 rem;
}

.mgn-top150 {
  margin-top: 9.375 rem;
}

.mgn-rgt150 {
  margin-right: 9.375 rem;
}

.mgn-btm150 {
  margin-bottom: 9.375 rem;
}

.mgn-lft150 {
  margin-left: 9.375 rem;
}

@media screen and (max-width: 47.9375em) {
  /* --- margin --- 古い・削除*/
  .mgn-btm16 {
    margin-bottom: 8px;
  }
  .mgn-btm24 {
    margin-bottom: 16px;
  }
  .mgn-btm32 {
    margin-bottom: 16px;
  }
  .mgn-btm40 {
    margin-bottom: 24px;
  }
  .mgn-btm48 {
    margin-bottom: 24px;
  }
  .mgn-btm56 {
    margin-bottom: 32px;
  }
  .mgn-btm64 {
    margin-bottom: 32px;
  }
  .mgn-btm72 {
    margin-bottom: 40px;
  }
  .mgn-btm80 {
    margin-bottom: 40px;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0px;
}

.pdg-top0 {
  padding-top: 0px;
}

.pdg-rgt0 {
  padding-right: 0px;
}

.pdg-btm0 {
  padding-bottom: 0px;
}

.pdg-lft0 {
  padding-left: 0px;
}

.pdg-1 {
  padding: 1px;
}

.pdg-top1 {
  padding-top: 1px;
}

.pdg-rgt1 {
  padding-right: 1px;
}

.pdg-btm1 {
  padding-bottom: 1px;
}

.pdg-lft1 {
  padding-left: 1px;
}

.pdg-2 {
  padding: 2px;
}

.pdg-top2 {
  padding-top: 2px;
}

.pdg-rgt2 {
  padding-right: 2px;
}

.pdg-btm2 {
  padding-bottom: 2px;
}

.pdg-lft2 {
  padding-left: 2px;
}

.pdg-3 {
  padding: 3px;
}

.pdg-top3 {
  padding-top: 3px;
}

.pdg-rgt3 {
  padding-right: 3px;
}

.pdg-btm3 {
  padding-bottom: 3px;
}

.pdg-lft3 {
  padding-left: 3px;
}

.pdg-4 {
  padding: 4px;
}

.pdg-top4 {
  padding-top: 4px;
}

.pdg-rgt4 {
  padding-right: 4px;
}

.pdg-btm4 {
  padding-bottom: 4px;
}

.pdg-lft4 {
  padding-left: 4px;
}

.pdg-5 {
  padding: 5px;
}

.pdg-top5 {
  padding-top: 5px;
}

.pdg-rgt5 {
  padding-right: 5px;
}

.pdg-btm5 {
  padding-bottom: 5px;
}

.pdg-lft5 {
  padding-left: 5px;
}

.pdg-6 {
  padding: 6px;
}

.pdg-top6 {
  padding-top: 6px;
}

.pdg-rgt6 {
  padding-right: 6px;
}

.pdg-btm6 {
  padding-bottom: 6px;
}

.pdg-lft6 {
  padding-left: 6px;
}

.pdg-7 {
  padding: 7px;
}

.pdg-top7 {
  padding-top: 7px;
}

.pdg-rgt7 {
  padding-right: 7px;
}

.pdg-btm7 {
  padding-bottom: 7px;
}

.pdg-lft7 {
  padding-left: 7px;
}

.pdg-8 {
  padding: 8px;
}

.pdg-top8 {
  padding-top: 8px;
}

.pdg-rgt8 {
  padding-right: 8px;
}

.pdg-btm8 {
  padding-bottom: 8px;
}

.pdg-lft8 {
  padding-left: 8px;
}

.pdg-9 {
  padding: 9px;
}

.pdg-top9 {
  padding-top: 9px;
}

.pdg-rgt9 {
  padding-right: 9px;
}

.pdg-btm9 {
  padding-bottom: 9px;
}

.pdg-lft9 {
  padding-left: 9px;
}

.pdg-10 {
  padding: 10px;
}

.pdg-top10 {
  padding-top: 10px;
}

.pdg-rgt10 {
  padding-right: 10px;
}

.pdg-btm10 {
  padding-bottom: 10px;
}

.pdg-lft10 {
  padding-left: 10px;
}

.pdg-11 {
  padding: 11px;
}

.pdg-top11 {
  padding-top: 11px;
}

.pdg-rgt11 {
  padding-right: 11px;
}

.pdg-btm11 {
  padding-bottom: 11px;
}

.pdg-lft11 {
  padding-left: 11px;
}

.pdg-12 {
  padding: 12px;
}

.pdg-top12 {
  padding-top: 12px;
}

.pdg-rgt12 {
  padding-right: 12px;
}

.pdg-btm12 {
  padding-bottom: 12px;
}

.pdg-lft12 {
  padding-left: 12px;
}

.pdg-13 {
  padding: 13px;
}

.pdg-top13 {
  padding-top: 13px;
}

.pdg-rgt13 {
  padding-right: 13px;
}

.pdg-btm13 {
  padding-bottom: 13px;
}

.pdg-lft13 {
  padding-left: 13px;
}

.pdg-14 {
  padding: 14px;
}

.pdg-top14 {
  padding-top: 14px;
}

.pdg-rgt14 {
  padding-right: 14px;
}

.pdg-btm14 {
  padding-bottom: 14px;
}

.pdg-lft14 {
  padding-left: 14px;
}

.pdg-15 {
  padding: 15px;
}

.pdg-top15 {
  padding-top: 15px;
}

.pdg-rgt15 {
  padding-right: 15px;
}

.pdg-btm15 {
  padding-bottom: 15px;
}

.pdg-lft15 {
  padding-left: 15px;
}

.pdg-16 {
  padding: 16px;
}

.pdg-top16 {
  padding-top: 16px;
}

.pdg-rgt16 {
  padding-right: 16px;
}

.pdg-btm16 {
  padding-bottom: 16px;
}

.pdg-lft16 {
  padding-left: 16px;
}

.pdg-17 {
  padding: 17px;
}

.pdg-top17 {
  padding-top: 17px;
}

.pdg-rgt17 {
  padding-right: 17px;
}

.pdg-btm17 {
  padding-bottom: 17px;
}

.pdg-lft17 {
  padding-left: 17px;
}

.pdg-18 {
  padding: 18px;
}

.pdg-top18 {
  padding-top: 18px;
}

.pdg-rgt18 {
  padding-right: 18px;
}

.pdg-btm18 {
  padding-bottom: 18px;
}

.pdg-lft18 {
  padding-left: 18px;
}

.pdg-19 {
  padding: 19px;
}

.pdg-top19 {
  padding-top: 19px;
}

.pdg-rgt19 {
  padding-right: 19px;
}

.pdg-btm19 {
  padding-bottom: 19px;
}

.pdg-lft19 {
  padding-left: 19px;
}

.pdg-20 {
  padding: 20px;
}

.pdg-top20 {
  padding-top: 20px;
}

.pdg-rgt20 {
  padding-right: 20px;
}

.pdg-btm20 {
  padding-bottom: 20px;
}

.pdg-lft20 {
  padding-left: 20px;
}

.pdg-21 {
  padding: 21px;
}

.pdg-top21 {
  padding-top: 21px;
}

.pdg-rgt21 {
  padding-right: 21px;
}

.pdg-btm21 {
  padding-bottom: 21px;
}

.pdg-lft21 {
  padding-left: 21px;
}

.pdg-22 {
  padding: 22px;
}

.pdg-top22 {
  padding-top: 22px;
}

.pdg-rgt22 {
  padding-right: 22px;
}

.pdg-btm22 {
  padding-bottom: 22px;
}

.pdg-lft22 {
  padding-left: 22px;
}

.pdg-23 {
  padding: 23px;
}

.pdg-top23 {
  padding-top: 23px;
}

.pdg-rgt23 {
  padding-right: 23px;
}

.pdg-btm23 {
  padding-bottom: 23px;
}

.pdg-lft23 {
  padding-left: 23px;
}

.pdg-24 {
  padding: 24px;
}

.pdg-top24 {
  padding-top: 24px;
}

.pdg-rgt24 {
  padding-right: 24px;
}

.pdg-btm24 {
  padding-bottom: 24px;
}

.pdg-lft24 {
  padding-left: 24px;
}

.pdg-25 {
  padding: 25px;
}

.pdg-top25 {
  padding-top: 25px;
}

.pdg-rgt25 {
  padding-right: 25px;
}

.pdg-btm25 {
  padding-bottom: 25px;
}

.pdg-lft25 {
  padding-left: 25px;
}

.pdg-26 {
  padding: 26px;
}

.pdg-top26 {
  padding-top: 26px;
}

.pdg-rgt26 {
  padding-right: 26px;
}

.pdg-btm26 {
  padding-bottom: 26px;
}

.pdg-lft26 {
  padding-left: 26px;
}

.pdg-27 {
  padding: 27px;
}

.pdg-top27 {
  padding-top: 27px;
}

.pdg-rgt27 {
  padding-right: 27px;
}

.pdg-btm27 {
  padding-bottom: 27px;
}

.pdg-lft27 {
  padding-left: 27px;
}

.pdg-28 {
  padding: 28px;
}

.pdg-top28 {
  padding-top: 28px;
}

.pdg-rgt28 {
  padding-right: 28px;
}

.pdg-btm28 {
  padding-bottom: 28px;
}

.pdg-lft28 {
  padding-left: 28px;
}

.pdg-29 {
  padding: 29px;
}

.pdg-top29 {
  padding-top: 29px;
}

.pdg-rgt29 {
  padding-right: 29px;
}

.pdg-btm29 {
  padding-bottom: 29px;
}

.pdg-lft29 {
  padding-left: 29px;
}

.pdg-30 {
  padding: 30px;
}

.pdg-top30 {
  padding-top: 30px;
}

.pdg-rgt30 {
  padding-right: 30px;
}

.pdg-btm30 {
  padding-bottom: 30px;
}

.pdg-lft30 {
  padding-left: 30px;
}

.pdg-31 {
  padding: 31px;
}

.pdg-top31 {
  padding-top: 31px;
}

.pdg-rgt31 {
  padding-right: 31px;
}

.pdg-btm31 {
  padding-bottom: 31px;
}

.pdg-lft31 {
  padding-left: 31px;
}

.pdg-32 {
  padding: 32px;
}

.pdg-top32 {
  padding-top: 32px;
}

.pdg-rgt32 {
  padding-right: 32px;
}

.pdg-btm32 {
  padding-bottom: 32px;
}

.pdg-lft32 {
  padding-left: 32px;
}

.pdg-33 {
  padding: 33px;
}

.pdg-top33 {
  padding-top: 33px;
}

.pdg-rgt33 {
  padding-right: 33px;
}

.pdg-btm33 {
  padding-bottom: 33px;
}

.pdg-lft33 {
  padding-left: 33px;
}

.pdg-34 {
  padding: 34px;
}

.pdg-top34 {
  padding-top: 34px;
}

.pdg-rgt34 {
  padding-right: 34px;
}

.pdg-btm34 {
  padding-bottom: 34px;
}

.pdg-lft34 {
  padding-left: 34px;
}

.pdg-35 {
  padding: 35px;
}

.pdg-top35 {
  padding-top: 35px;
}

.pdg-rgt35 {
  padding-right: 35px;
}

.pdg-btm35 {
  padding-bottom: 35px;
}

.pdg-lft35 {
  padding-left: 35px;
}

.pdg-36 {
  padding: 36px;
}

.pdg-top36 {
  padding-top: 36px;
}

.pdg-rgt36 {
  padding-right: 36px;
}

.pdg-btm36 {
  padding-bottom: 36px;
}

.pdg-lft36 {
  padding-left: 36px;
}

.pdg-37 {
  padding: 37px;
}

.pdg-top37 {
  padding-top: 37px;
}

.pdg-rgt37 {
  padding-right: 37px;
}

.pdg-btm37 {
  padding-bottom: 37px;
}

.pdg-lft37 {
  padding-left: 37px;
}

.pdg-38 {
  padding: 38px;
}

.pdg-top38 {
  padding-top: 38px;
}

.pdg-rgt38 {
  padding-right: 38px;
}

.pdg-btm38 {
  padding-bottom: 38px;
}

.pdg-lft38 {
  padding-left: 38px;
}

.pdg-39 {
  padding: 39px;
}

.pdg-top39 {
  padding-top: 39px;
}

.pdg-rgt39 {
  padding-right: 39px;
}

.pdg-btm39 {
  padding-bottom: 39px;
}

.pdg-lft39 {
  padding-left: 39px;
}

.pdg-40 {
  padding: 40px;
}

.pdg-top40 {
  padding-top: 40px;
}

.pdg-rgt40 {
  padding-right: 40px;
}

.pdg-btm40 {
  padding-bottom: 40px;
}

.pdg-lft40 {
  padding-left: 40px;
}

.pdg-41 {
  padding: 41px;
}

.pdg-top41 {
  padding-top: 41px;
}

.pdg-rgt41 {
  padding-right: 41px;
}

.pdg-btm41 {
  padding-bottom: 41px;
}

.pdg-lft41 {
  padding-left: 41px;
}

.pdg-42 {
  padding: 42px;
}

.pdg-top42 {
  padding-top: 42px;
}

.pdg-rgt42 {
  padding-right: 42px;
}

.pdg-btm42 {
  padding-bottom: 42px;
}

.pdg-lft42 {
  padding-left: 42px;
}

.pdg-43 {
  padding: 43px;
}

.pdg-top43 {
  padding-top: 43px;
}

.pdg-rgt43 {
  padding-right: 43px;
}

.pdg-btm43 {
  padding-bottom: 43px;
}

.pdg-lft43 {
  padding-left: 43px;
}

.pdg-44 {
  padding: 44px;
}

.pdg-top44 {
  padding-top: 44px;
}

.pdg-rgt44 {
  padding-right: 44px;
}

.pdg-btm44 {
  padding-bottom: 44px;
}

.pdg-lft44 {
  padding-left: 44px;
}

.pdg-45 {
  padding: 45px;
}

.pdg-top45 {
  padding-top: 45px;
}

.pdg-rgt45 {
  padding-right: 45px;
}

.pdg-btm45 {
  padding-bottom: 45px;
}

.pdg-lft45 {
  padding-left: 45px;
}

.pdg-46 {
  padding: 46px;
}

.pdg-top46 {
  padding-top: 46px;
}

.pdg-rgt46 {
  padding-right: 46px;
}

.pdg-btm46 {
  padding-bottom: 46px;
}

.pdg-lft46 {
  padding-left: 46px;
}

.pdg-47 {
  padding: 47px;
}

.pdg-top47 {
  padding-top: 47px;
}

.pdg-rgt47 {
  padding-right: 47px;
}

.pdg-btm47 {
  padding-bottom: 47px;
}

.pdg-lft47 {
  padding-left: 47px;
}

.pdg-48 {
  padding: 48px;
}

.pdg-top48 {
  padding-top: 48px;
}

.pdg-rgt48 {
  padding-right: 48px;
}

.pdg-btm48 {
  padding-bottom: 48px;
}

.pdg-lft48 {
  padding-left: 48px;
}

.pdg-49 {
  padding: 49px;
}

.pdg-top49 {
  padding-top: 49px;
}

.pdg-rgt49 {
  padding-right: 49px;
}

.pdg-btm49 {
  padding-bottom: 49px;
}

.pdg-lft49 {
  padding-left: 49px;
}

.pdg-50 {
  padding: 50px;
}

.pdg-top50 {
  padding-top: 50px;
}

.pdg-rgt50 {
  padding-right: 50px;
}

.pdg-btm50 {
  padding-bottom: 50px;
}

.pdg-lft50 {
  padding-left: 50px;
}

.pdg-51 {
  padding: 51px;
}

.pdg-top51 {
  padding-top: 51px;
}

.pdg-rgt51 {
  padding-right: 51px;
}

.pdg-btm51 {
  padding-bottom: 51px;
}

.pdg-lft51 {
  padding-left: 51px;
}

.pdg-52 {
  padding: 52px;
}

.pdg-top52 {
  padding-top: 52px;
}

.pdg-rgt52 {
  padding-right: 52px;
}

.pdg-btm52 {
  padding-bottom: 52px;
}

.pdg-lft52 {
  padding-left: 52px;
}

.pdg-53 {
  padding: 53px;
}

.pdg-top53 {
  padding-top: 53px;
}

.pdg-rgt53 {
  padding-right: 53px;
}

.pdg-btm53 {
  padding-bottom: 53px;
}

.pdg-lft53 {
  padding-left: 53px;
}

.pdg-54 {
  padding: 54px;
}

.pdg-top54 {
  padding-top: 54px;
}

.pdg-rgt54 {
  padding-right: 54px;
}

.pdg-btm54 {
  padding-bottom: 54px;
}

.pdg-lft54 {
  padding-left: 54px;
}

.pdg-55 {
  padding: 55px;
}

.pdg-top55 {
  padding-top: 55px;
}

.pdg-rgt55 {
  padding-right: 55px;
}

.pdg-btm55 {
  padding-bottom: 55px;
}

.pdg-lft55 {
  padding-left: 55px;
}

.pdg-56 {
  padding: 56px;
}

.pdg-top56 {
  padding-top: 56px;
}

.pdg-rgt56 {
  padding-right: 56px;
}

.pdg-btm56 {
  padding-bottom: 56px;
}

.pdg-lft56 {
  padding-left: 56px;
}

.pdg-57 {
  padding: 57px;
}

.pdg-top57 {
  padding-top: 57px;
}

.pdg-rgt57 {
  padding-right: 57px;
}

.pdg-btm57 {
  padding-bottom: 57px;
}

.pdg-lft57 {
  padding-left: 57px;
}

.pdg-58 {
  padding: 58px;
}

.pdg-top58 {
  padding-top: 58px;
}

.pdg-rgt58 {
  padding-right: 58px;
}

.pdg-btm58 {
  padding-bottom: 58px;
}

.pdg-lft58 {
  padding-left: 58px;
}

.pdg-59 {
  padding: 59px;
}

.pdg-top59 {
  padding-top: 59px;
}

.pdg-rgt59 {
  padding-right: 59px;
}

.pdg-btm59 {
  padding-bottom: 59px;
}

.pdg-lft59 {
  padding-left: 59px;
}

.pdg-60 {
  padding: 60px;
}

.pdg-top60 {
  padding-top: 60px;
}

.pdg-rgt60 {
  padding-right: 60px;
}

.pdg-btm60 {
  padding-bottom: 60px;
}

.pdg-lft60 {
  padding-left: 60px;
}

.pdg-61 {
  padding: 61px;
}

.pdg-top61 {
  padding-top: 61px;
}

.pdg-rgt61 {
  padding-right: 61px;
}

.pdg-btm61 {
  padding-bottom: 61px;
}

.pdg-lft61 {
  padding-left: 61px;
}

.pdg-62 {
  padding: 62px;
}

.pdg-top62 {
  padding-top: 62px;
}

.pdg-rgt62 {
  padding-right: 62px;
}

.pdg-btm62 {
  padding-bottom: 62px;
}

.pdg-lft62 {
  padding-left: 62px;
}

.pdg-63 {
  padding: 63px;
}

.pdg-top63 {
  padding-top: 63px;
}

.pdg-rgt63 {
  padding-right: 63px;
}

.pdg-btm63 {
  padding-bottom: 63px;
}

.pdg-lft63 {
  padding-left: 63px;
}

.pdg-64 {
  padding: 64px;
}

.pdg-top64 {
  padding-top: 64px;
}

.pdg-rgt64 {
  padding-right: 64px;
}

.pdg-btm64 {
  padding-bottom: 64px;
}

.pdg-lft64 {
  padding-left: 64px;
}

.pdg-65 {
  padding: 65px;
}

.pdg-top65 {
  padding-top: 65px;
}

.pdg-rgt65 {
  padding-right: 65px;
}

.pdg-btm65 {
  padding-bottom: 65px;
}

.pdg-lft65 {
  padding-left: 65px;
}

.pdg-66 {
  padding: 66px;
}

.pdg-top66 {
  padding-top: 66px;
}

.pdg-rgt66 {
  padding-right: 66px;
}

.pdg-btm66 {
  padding-bottom: 66px;
}

.pdg-lft66 {
  padding-left: 66px;
}

.pdg-67 {
  padding: 67px;
}

.pdg-top67 {
  padding-top: 67px;
}

.pdg-rgt67 {
  padding-right: 67px;
}

.pdg-btm67 {
  padding-bottom: 67px;
}

.pdg-lft67 {
  padding-left: 67px;
}

.pdg-68 {
  padding: 68px;
}

.pdg-top68 {
  padding-top: 68px;
}

.pdg-rgt68 {
  padding-right: 68px;
}

.pdg-btm68 {
  padding-bottom: 68px;
}

.pdg-lft68 {
  padding-left: 68px;
}

.pdg-69 {
  padding: 69px;
}

.pdg-top69 {
  padding-top: 69px;
}

.pdg-rgt69 {
  padding-right: 69px;
}

.pdg-btm69 {
  padding-bottom: 69px;
}

.pdg-lft69 {
  padding-left: 69px;
}

.pdg-70 {
  padding: 70px;
}

.pdg-top70 {
  padding-top: 70px;
}

.pdg-rgt70 {
  padding-right: 70px;
}

.pdg-btm70 {
  padding-bottom: 70px;
}

.pdg-lft70 {
  padding-left: 70px;
}

.pdg-71 {
  padding: 71px;
}

.pdg-top71 {
  padding-top: 71px;
}

.pdg-rgt71 {
  padding-right: 71px;
}

.pdg-btm71 {
  padding-bottom: 71px;
}

.pdg-lft71 {
  padding-left: 71px;
}

.pdg-72 {
  padding: 72px;
}

.pdg-top72 {
  padding-top: 72px;
}

.pdg-rgt72 {
  padding-right: 72px;
}

.pdg-btm72 {
  padding-bottom: 72px;
}

.pdg-lft72 {
  padding-left: 72px;
}

.pdg-73 {
  padding: 73px;
}

.pdg-top73 {
  padding-top: 73px;
}

.pdg-rgt73 {
  padding-right: 73px;
}

.pdg-btm73 {
  padding-bottom: 73px;
}

.pdg-lft73 {
  padding-left: 73px;
}

.pdg-74 {
  padding: 74px;
}

.pdg-top74 {
  padding-top: 74px;
}

.pdg-rgt74 {
  padding-right: 74px;
}

.pdg-btm74 {
  padding-bottom: 74px;
}

.pdg-lft74 {
  padding-left: 74px;
}

.pdg-75 {
  padding: 75px;
}

.pdg-top75 {
  padding-top: 75px;
}

.pdg-rgt75 {
  padding-right: 75px;
}

.pdg-btm75 {
  padding-bottom: 75px;
}

.pdg-lft75 {
  padding-left: 75px;
}

.pdg-76 {
  padding: 76px;
}

.pdg-top76 {
  padding-top: 76px;
}

.pdg-rgt76 {
  padding-right: 76px;
}

.pdg-btm76 {
  padding-bottom: 76px;
}

.pdg-lft76 {
  padding-left: 76px;
}

.pdg-77 {
  padding: 77px;
}

.pdg-top77 {
  padding-top: 77px;
}

.pdg-rgt77 {
  padding-right: 77px;
}

.pdg-btm77 {
  padding-bottom: 77px;
}

.pdg-lft77 {
  padding-left: 77px;
}

.pdg-78 {
  padding: 78px;
}

.pdg-top78 {
  padding-top: 78px;
}

.pdg-rgt78 {
  padding-right: 78px;
}

.pdg-btm78 {
  padding-bottom: 78px;
}

.pdg-lft78 {
  padding-left: 78px;
}

.pdg-79 {
  padding: 79px;
}

.pdg-top79 {
  padding-top: 79px;
}

.pdg-rgt79 {
  padding-right: 79px;
}

.pdg-btm79 {
  padding-bottom: 79px;
}

.pdg-lft79 {
  padding-left: 79px;
}

.pdg-80 {
  padding: 80px;
}

.pdg-top80 {
  padding-top: 80px;
}

.pdg-rgt80 {
  padding-right: 80px;
}

.pdg-btm80 {
  padding-bottom: 80px;
}

.pdg-lft80 {
  padding-left: 80px;
}

.pdg-81 {
  padding: 81px;
}

.pdg-top81 {
  padding-top: 81px;
}

.pdg-rgt81 {
  padding-right: 81px;
}

.pdg-btm81 {
  padding-bottom: 81px;
}

.pdg-lft81 {
  padding-left: 81px;
}

.pdg-82 {
  padding: 82px;
}

.pdg-top82 {
  padding-top: 82px;
}

.pdg-rgt82 {
  padding-right: 82px;
}

.pdg-btm82 {
  padding-bottom: 82px;
}

.pdg-lft82 {
  padding-left: 82px;
}

.pdg-83 {
  padding: 83px;
}

.pdg-top83 {
  padding-top: 83px;
}

.pdg-rgt83 {
  padding-right: 83px;
}

.pdg-btm83 {
  padding-bottom: 83px;
}

.pdg-lft83 {
  padding-left: 83px;
}

.pdg-84 {
  padding: 84px;
}

.pdg-top84 {
  padding-top: 84px;
}

.pdg-rgt84 {
  padding-right: 84px;
}

.pdg-btm84 {
  padding-bottom: 84px;
}

.pdg-lft84 {
  padding-left: 84px;
}

.pdg-85 {
  padding: 85px;
}

.pdg-top85 {
  padding-top: 85px;
}

.pdg-rgt85 {
  padding-right: 85px;
}

.pdg-btm85 {
  padding-bottom: 85px;
}

.pdg-lft85 {
  padding-left: 85px;
}

.pdg-86 {
  padding: 86px;
}

.pdg-top86 {
  padding-top: 86px;
}

.pdg-rgt86 {
  padding-right: 86px;
}

.pdg-btm86 {
  padding-bottom: 86px;
}

.pdg-lft86 {
  padding-left: 86px;
}

.pdg-87 {
  padding: 87px;
}

.pdg-top87 {
  padding-top: 87px;
}

.pdg-rgt87 {
  padding-right: 87px;
}

.pdg-btm87 {
  padding-bottom: 87px;
}

.pdg-lft87 {
  padding-left: 87px;
}

.pdg-88 {
  padding: 88px;
}

.pdg-top88 {
  padding-top: 88px;
}

.pdg-rgt88 {
  padding-right: 88px;
}

.pdg-btm88 {
  padding-bottom: 88px;
}

.pdg-lft88 {
  padding-left: 88px;
}

.pdg-89 {
  padding: 89px;
}

.pdg-top89 {
  padding-top: 89px;
}

.pdg-rgt89 {
  padding-right: 89px;
}

.pdg-btm89 {
  padding-bottom: 89px;
}

.pdg-lft89 {
  padding-left: 89px;
}

.pdg-90 {
  padding: 90px;
}

.pdg-top90 {
  padding-top: 90px;
}

.pdg-rgt90 {
  padding-right: 90px;
}

.pdg-btm90 {
  padding-bottom: 90px;
}

.pdg-lft90 {
  padding-left: 90px;
}

.pdg-91 {
  padding: 91px;
}

.pdg-top91 {
  padding-top: 91px;
}

.pdg-rgt91 {
  padding-right: 91px;
}

.pdg-btm91 {
  padding-bottom: 91px;
}

.pdg-lft91 {
  padding-left: 91px;
}

.pdg-92 {
  padding: 92px;
}

.pdg-top92 {
  padding-top: 92px;
}

.pdg-rgt92 {
  padding-right: 92px;
}

.pdg-btm92 {
  padding-bottom: 92px;
}

.pdg-lft92 {
  padding-left: 92px;
}

.pdg-93 {
  padding: 93px;
}

.pdg-top93 {
  padding-top: 93px;
}

.pdg-rgt93 {
  padding-right: 93px;
}

.pdg-btm93 {
  padding-bottom: 93px;
}

.pdg-lft93 {
  padding-left: 93px;
}

.pdg-94 {
  padding: 94px;
}

.pdg-top94 {
  padding-top: 94px;
}

.pdg-rgt94 {
  padding-right: 94px;
}

.pdg-btm94 {
  padding-bottom: 94px;
}

.pdg-lft94 {
  padding-left: 94px;
}

.pdg-95 {
  padding: 95px;
}

.pdg-top95 {
  padding-top: 95px;
}

.pdg-rgt95 {
  padding-right: 95px;
}

.pdg-btm95 {
  padding-bottom: 95px;
}

.pdg-lft95 {
  padding-left: 95px;
}

.pdg-96 {
  padding: 96px;
}

.pdg-top96 {
  padding-top: 96px;
}

.pdg-rgt96 {
  padding-right: 96px;
}

.pdg-btm96 {
  padding-bottom: 96px;
}

.pdg-lft96 {
  padding-left: 96px;
}

.pdg-97 {
  padding: 97px;
}

.pdg-top97 {
  padding-top: 97px;
}

.pdg-rgt97 {
  padding-right: 97px;
}

.pdg-btm97 {
  padding-bottom: 97px;
}

.pdg-lft97 {
  padding-left: 97px;
}

.pdg-98 {
  padding: 98px;
}

.pdg-top98 {
  padding-top: 98px;
}

.pdg-rgt98 {
  padding-right: 98px;
}

.pdg-btm98 {
  padding-bottom: 98px;
}

.pdg-lft98 {
  padding-left: 98px;
}

.pdg-99 {
  padding: 99px;
}

.pdg-top99 {
  padding-top: 99px;
}

.pdg-rgt99 {
  padding-right: 99px;
}

.pdg-btm99 {
  padding-bottom: 99px;
}

.pdg-lft99 {
  padding-left: 99px;
}

.pdg-100 {
  padding: 100px;
}

.pdg-top100 {
  padding-top: 100px;
}

.pdg-rgt100 {
  padding-right: 100px;
}

.pdg-btm100 {
  padding-bottom: 100px;
}

.pdg-lft100 {
  padding-left: 100px;
}

.pdg-101 {
  padding: 101px;
}

.pdg-top101 {
  padding-top: 101px;
}

.pdg-rgt101 {
  padding-right: 101px;
}

.pdg-btm101 {
  padding-bottom: 101px;
}

.pdg-lft101 {
  padding-left: 101px;
}

.pdg-102 {
  padding: 102px;
}

.pdg-top102 {
  padding-top: 102px;
}

.pdg-rgt102 {
  padding-right: 102px;
}

.pdg-btm102 {
  padding-bottom: 102px;
}

.pdg-lft102 {
  padding-left: 102px;
}

.pdg-103 {
  padding: 103px;
}

.pdg-top103 {
  padding-top: 103px;
}

.pdg-rgt103 {
  padding-right: 103px;
}

.pdg-btm103 {
  padding-bottom: 103px;
}

.pdg-lft103 {
  padding-left: 103px;
}

.pdg-104 {
  padding: 104px;
}

.pdg-top104 {
  padding-top: 104px;
}

.pdg-rgt104 {
  padding-right: 104px;
}

.pdg-btm104 {
  padding-bottom: 104px;
}

.pdg-lft104 {
  padding-left: 104px;
}

.pdg-105 {
  padding: 105px;
}

.pdg-top105 {
  padding-top: 105px;
}

.pdg-rgt105 {
  padding-right: 105px;
}

.pdg-btm105 {
  padding-bottom: 105px;
}

.pdg-lft105 {
  padding-left: 105px;
}

.pdg-106 {
  padding: 106px;
}

.pdg-top106 {
  padding-top: 106px;
}

.pdg-rgt106 {
  padding-right: 106px;
}

.pdg-btm106 {
  padding-bottom: 106px;
}

.pdg-lft106 {
  padding-left: 106px;
}

.pdg-107 {
  padding: 107px;
}

.pdg-top107 {
  padding-top: 107px;
}

.pdg-rgt107 {
  padding-right: 107px;
}

.pdg-btm107 {
  padding-bottom: 107px;
}

.pdg-lft107 {
  padding-left: 107px;
}

.pdg-108 {
  padding: 108px;
}

.pdg-top108 {
  padding-top: 108px;
}

.pdg-rgt108 {
  padding-right: 108px;
}

.pdg-btm108 {
  padding-bottom: 108px;
}

.pdg-lft108 {
  padding-left: 108px;
}

.pdg-109 {
  padding: 109px;
}

.pdg-top109 {
  padding-top: 109px;
}

.pdg-rgt109 {
  padding-right: 109px;
}

.pdg-btm109 {
  padding-bottom: 109px;
}

.pdg-lft109 {
  padding-left: 109px;
}

.pdg-110 {
  padding: 110px;
}

.pdg-top110 {
  padding-top: 110px;
}

.pdg-rgt110 {
  padding-right: 110px;
}

.pdg-btm110 {
  padding-bottom: 110px;
}

.pdg-lft110 {
  padding-left: 110px;
}

.pdg-111 {
  padding: 111px;
}

.pdg-top111 {
  padding-top: 111px;
}

.pdg-rgt111 {
  padding-right: 111px;
}

.pdg-btm111 {
  padding-bottom: 111px;
}

.pdg-lft111 {
  padding-left: 111px;
}

.pdg-112 {
  padding: 112px;
}

.pdg-top112 {
  padding-top: 112px;
}

.pdg-rgt112 {
  padding-right: 112px;
}

.pdg-btm112 {
  padding-bottom: 112px;
}

.pdg-lft112 {
  padding-left: 112px;
}

.pdg-113 {
  padding: 113px;
}

.pdg-top113 {
  padding-top: 113px;
}

.pdg-rgt113 {
  padding-right: 113px;
}

.pdg-btm113 {
  padding-bottom: 113px;
}

.pdg-lft113 {
  padding-left: 113px;
}

.pdg-114 {
  padding: 114px;
}

.pdg-top114 {
  padding-top: 114px;
}

.pdg-rgt114 {
  padding-right: 114px;
}

.pdg-btm114 {
  padding-bottom: 114px;
}

.pdg-lft114 {
  padding-left: 114px;
}

.pdg-115 {
  padding: 115px;
}

.pdg-top115 {
  padding-top: 115px;
}

.pdg-rgt115 {
  padding-right: 115px;
}

.pdg-btm115 {
  padding-bottom: 115px;
}

.pdg-lft115 {
  padding-left: 115px;
}

.pdg-116 {
  padding: 116px;
}

.pdg-top116 {
  padding-top: 116px;
}

.pdg-rgt116 {
  padding-right: 116px;
}

.pdg-btm116 {
  padding-bottom: 116px;
}

.pdg-lft116 {
  padding-left: 116px;
}

.pdg-117 {
  padding: 117px;
}

.pdg-top117 {
  padding-top: 117px;
}

.pdg-rgt117 {
  padding-right: 117px;
}

.pdg-btm117 {
  padding-bottom: 117px;
}

.pdg-lft117 {
  padding-left: 117px;
}

.pdg-118 {
  padding: 118px;
}

.pdg-top118 {
  padding-top: 118px;
}

.pdg-rgt118 {
  padding-right: 118px;
}

.pdg-btm118 {
  padding-bottom: 118px;
}

.pdg-lft118 {
  padding-left: 118px;
}

.pdg-119 {
  padding: 119px;
}

.pdg-top119 {
  padding-top: 119px;
}

.pdg-rgt119 {
  padding-right: 119px;
}

.pdg-btm119 {
  padding-bottom: 119px;
}

.pdg-lft119 {
  padding-left: 119px;
}

.pdg-120 {
  padding: 120px;
}

.pdg-top120 {
  padding-top: 120px;
}

.pdg-rgt120 {
  padding-right: 120px;
}

.pdg-btm120 {
  padding-bottom: 120px;
}

.pdg-lft120 {
  padding-left: 120px;
}

.pdg-121 {
  padding: 121px;
}

.pdg-top121 {
  padding-top: 121px;
}

.pdg-rgt121 {
  padding-right: 121px;
}

.pdg-btm121 {
  padding-bottom: 121px;
}

.pdg-lft121 {
  padding-left: 121px;
}

.pdg-122 {
  padding: 122px;
}

.pdg-top122 {
  padding-top: 122px;
}

.pdg-rgt122 {
  padding-right: 122px;
}

.pdg-btm122 {
  padding-bottom: 122px;
}

.pdg-lft122 {
  padding-left: 122px;
}

.pdg-123 {
  padding: 123px;
}

.pdg-top123 {
  padding-top: 123px;
}

.pdg-rgt123 {
  padding-right: 123px;
}

.pdg-btm123 {
  padding-bottom: 123px;
}

.pdg-lft123 {
  padding-left: 123px;
}

.pdg-124 {
  padding: 124px;
}

.pdg-top124 {
  padding-top: 124px;
}

.pdg-rgt124 {
  padding-right: 124px;
}

.pdg-btm124 {
  padding-bottom: 124px;
}

.pdg-lft124 {
  padding-left: 124px;
}

.pdg-125 {
  padding: 125px;
}

.pdg-top125 {
  padding-top: 125px;
}

.pdg-rgt125 {
  padding-right: 125px;
}

.pdg-btm125 {
  padding-bottom: 125px;
}

.pdg-lft125 {
  padding-left: 125px;
}

.pdg-126 {
  padding: 126px;
}

.pdg-top126 {
  padding-top: 126px;
}

.pdg-rgt126 {
  padding-right: 126px;
}

.pdg-btm126 {
  padding-bottom: 126px;
}

.pdg-lft126 {
  padding-left: 126px;
}

.pdg-127 {
  padding: 127px;
}

.pdg-top127 {
  padding-top: 127px;
}

.pdg-rgt127 {
  padding-right: 127px;
}

.pdg-btm127 {
  padding-bottom: 127px;
}

.pdg-lft127 {
  padding-left: 127px;
}

.pdg-128 {
  padding: 128px;
}

.pdg-top128 {
  padding-top: 128px;
}

.pdg-rgt128 {
  padding-right: 128px;
}

.pdg-btm128 {
  padding-bottom: 128px;
}

.pdg-lft128 {
  padding-left: 128px;
}

.pdg-129 {
  padding: 129px;
}

.pdg-top129 {
  padding-top: 129px;
}

.pdg-rgt129 {
  padding-right: 129px;
}

.pdg-btm129 {
  padding-bottom: 129px;
}

.pdg-lft129 {
  padding-left: 129px;
}

.pdg-130 {
  padding: 130px;
}

.pdg-top130 {
  padding-top: 130px;
}

.pdg-rgt130 {
  padding-right: 130px;
}

.pdg-btm130 {
  padding-bottom: 130px;
}

.pdg-lft130 {
  padding-left: 130px;
}

.pdg-131 {
  padding: 131px;
}

.pdg-top131 {
  padding-top: 131px;
}

.pdg-rgt131 {
  padding-right: 131px;
}

.pdg-btm131 {
  padding-bottom: 131px;
}

.pdg-lft131 {
  padding-left: 131px;
}

.pdg-132 {
  padding: 132px;
}

.pdg-top132 {
  padding-top: 132px;
}

.pdg-rgt132 {
  padding-right: 132px;
}

.pdg-btm132 {
  padding-bottom: 132px;
}

.pdg-lft132 {
  padding-left: 132px;
}

.pdg-133 {
  padding: 133px;
}

.pdg-top133 {
  padding-top: 133px;
}

.pdg-rgt133 {
  padding-right: 133px;
}

.pdg-btm133 {
  padding-bottom: 133px;
}

.pdg-lft133 {
  padding-left: 133px;
}

.pdg-134 {
  padding: 134px;
}

.pdg-top134 {
  padding-top: 134px;
}

.pdg-rgt134 {
  padding-right: 134px;
}

.pdg-btm134 {
  padding-bottom: 134px;
}

.pdg-lft134 {
  padding-left: 134px;
}

.pdg-135 {
  padding: 135px;
}

.pdg-top135 {
  padding-top: 135px;
}

.pdg-rgt135 {
  padding-right: 135px;
}

.pdg-btm135 {
  padding-bottom: 135px;
}

.pdg-lft135 {
  padding-left: 135px;
}

.pdg-136 {
  padding: 136px;
}

.pdg-top136 {
  padding-top: 136px;
}

.pdg-rgt136 {
  padding-right: 136px;
}

.pdg-btm136 {
  padding-bottom: 136px;
}

.pdg-lft136 {
  padding-left: 136px;
}

.pdg-137 {
  padding: 137px;
}

.pdg-top137 {
  padding-top: 137px;
}

.pdg-rgt137 {
  padding-right: 137px;
}

.pdg-btm137 {
  padding-bottom: 137px;
}

.pdg-lft137 {
  padding-left: 137px;
}

.pdg-138 {
  padding: 138px;
}

.pdg-top138 {
  padding-top: 138px;
}

.pdg-rgt138 {
  padding-right: 138px;
}

.pdg-btm138 {
  padding-bottom: 138px;
}

.pdg-lft138 {
  padding-left: 138px;
}

.pdg-139 {
  padding: 139px;
}

.pdg-top139 {
  padding-top: 139px;
}

.pdg-rgt139 {
  padding-right: 139px;
}

.pdg-btm139 {
  padding-bottom: 139px;
}

.pdg-lft139 {
  padding-left: 139px;
}

.pdg-140 {
  padding: 140px;
}

.pdg-top140 {
  padding-top: 140px;
}

.pdg-rgt140 {
  padding-right: 140px;
}

.pdg-btm140 {
  padding-bottom: 140px;
}

.pdg-lft140 {
  padding-left: 140px;
}

.pdg-141 {
  padding: 141px;
}

.pdg-top141 {
  padding-top: 141px;
}

.pdg-rgt141 {
  padding-right: 141px;
}

.pdg-btm141 {
  padding-bottom: 141px;
}

.pdg-lft141 {
  padding-left: 141px;
}

.pdg-142 {
  padding: 142px;
}

.pdg-top142 {
  padding-top: 142px;
}

.pdg-rgt142 {
  padding-right: 142px;
}

.pdg-btm142 {
  padding-bottom: 142px;
}

.pdg-lft142 {
  padding-left: 142px;
}

.pdg-143 {
  padding: 143px;
}

.pdg-top143 {
  padding-top: 143px;
}

.pdg-rgt143 {
  padding-right: 143px;
}

.pdg-btm143 {
  padding-bottom: 143px;
}

.pdg-lft143 {
  padding-left: 143px;
}

.pdg-144 {
  padding: 144px;
}

.pdg-top144 {
  padding-top: 144px;
}

.pdg-rgt144 {
  padding-right: 144px;
}

.pdg-btm144 {
  padding-bottom: 144px;
}

.pdg-lft144 {
  padding-left: 144px;
}

.pdg-145 {
  padding: 145px;
}

.pdg-top145 {
  padding-top: 145px;
}

.pdg-rgt145 {
  padding-right: 145px;
}

.pdg-btm145 {
  padding-bottom: 145px;
}

.pdg-lft145 {
  padding-left: 145px;
}

.pdg-146 {
  padding: 146px;
}

.pdg-top146 {
  padding-top: 146px;
}

.pdg-rgt146 {
  padding-right: 146px;
}

.pdg-btm146 {
  padding-bottom: 146px;
}

.pdg-lft146 {
  padding-left: 146px;
}

.pdg-147 {
  padding: 147px;
}

.pdg-top147 {
  padding-top: 147px;
}

.pdg-rgt147 {
  padding-right: 147px;
}

.pdg-btm147 {
  padding-bottom: 147px;
}

.pdg-lft147 {
  padding-left: 147px;
}

.pdg-148 {
  padding: 148px;
}

.pdg-top148 {
  padding-top: 148px;
}

.pdg-rgt148 {
  padding-right: 148px;
}

.pdg-btm148 {
  padding-bottom: 148px;
}

.pdg-lft148 {
  padding-left: 148px;
}

.pdg-149 {
  padding: 149px;
}

.pdg-top149 {
  padding-top: 149px;
}

.pdg-rgt149 {
  padding-right: 149px;
}

.pdg-btm149 {
  padding-bottom: 149px;
}

.pdg-lft149 {
  padding-left: 149px;
}

.pdg-150 {
  padding: 150px;
}

.pdg-top150 {
  padding-top: 150px;
}

.pdg-rgt150 {
  padding-right: 150px;
}

.pdg-btm150 {
  padding-bottom: 150px;
}

.pdg-lft150 {
  padding-left: 150px;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 16px 0;
}

.breadcrumbs a {
  color: var(--clr-main, #1D7FB7);
  text-decoration: underline;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}

.btn a {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 0.9375rem;
  padding: 1.0666666667em 2.6666666667em;
  letter-spacing: 0.14em;
  border-radius: 9999px;
  width: fit-content;
  min-width: min(264px, 100%);
  color: var(--btn-clr-main, #1D7FB7);
  text-align: center;
  background-color: var(--btn-bg-main, transparent);
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    min-width: 100%;
  }
}
.btn a:hover {
  background-color: var(--btn-bg-main-hvr, #f7f7f7);
}
.btn a svg {
  fill: var(--btn-clr-main, #1D7FB7);
}

.flex > .btn a {
  min-width: 100%;
}

.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}
.btn.txt-rgt a {
  margin-left: auto;
}
.btn:not(.btn-cta) a {
  border: 2px solid;
}

.btn.btn-next_page a {
  min-width: min(372px, 100%);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #fff);
  background-color: var(--btn-bg-wht, transparent);
}
.btn-wh a:hover {
  background-color: var(--btn-bg-wht-hvr, #f7f7f7);
}
.btn-wh a svg {
  fill: var(--btn-clr-wht, #fff);
}

/* --- btn-cta --- */
.btn-cta a {
  color: var(--btn-clr-cta, #fff);
  background-color: var(--btn-bg-cta, #1D7FB7);
}
.btn-cta a:hover {
  opacity: 0.7;
  background-color: var(--btn-bg-cta, #1D7FB7);
}
.btn-cta a svg {
  fill: var(--btn-clr-cta, #fff);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* ##############################################################################

  FORMY

############################################################################## */
#formy_form {
  background-color: var(--bg-off_wht, #f7f7f7);
  border-radius: 1.25rem;
  padding: 32px 40px;
}
@media screen and (max-width: 47.9375em) {
  #formy_form {
    padding: 24px;
  }
}

#formy_form * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 1rem;
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.3125rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  #formy_form table input[type=text],
  #formy_form table input[type=email],
  #formy_form table input[type=tel],
  #formy_form table input[type=date],
  #formy_form table input[type=password],
  #formy_form select,
  #formy_form textarea {
    font-size: 16px;
  }
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: #fff;
}

.help_text {
  font-size: 0.875rem;
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: var(--btn-bg-cta, #1D7FB7);
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 0.75rem;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.3125rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 0.3125rem;
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  transition: var(--transit-default, all 0.4s ease-out);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
  left: 1rem;
  top: 0.75rem;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: var(--btn-bg-cta, #1D7FB7);
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: var(--btn-bg-cta, #1D7FB7);
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid var(--btn-bg-cta, #1D7FB7);
  border-bottom: 2px solid var(--btn-bg-cta, #1D7FB7);
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: var(--clr-main, #1D7FB7);
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: 0.9375rem;
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: var(--btn-bg-cta, #1D7FB7);
}

#total_required {
  padding: 1rem;
  color: var(--btn-bg-cta, #1D7FB7);
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* --- section_pdg --- */
.section_pdg {
  padding-top: 124px;
  padding-bottom: 124px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 62px;
    padding-bottom: 62px;
  }
}

.section_pdg-sm {
  padding-top: 64px;
  padding-bottom: 64px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

/* --- section-line --- */
.section-line {
  position: relative;
}
.section-line::before {
  content: "";
  position: absolute;
  top: -101px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 101px;
  background-repeat: repeat-x;
  background-position: center;
}
@media screen and (max-width: 47.9375em) {
  .section-line::before {
    top: -12.077294686vw;
    height: 12.077294686vw;
    background-size: contain;
  }
}

.section-line01 {
  background-color: var(--bg-main, #ccc);
}
.section-line01::before {
  background-image: url(../images/common/line01.png);
}
@media screen and (max-width: 47.9375em) {
  .section-line01::before {
    background-image: url(../images/common/line01_sp.png);
  }
}

.section-line02 {
  background-image: url(../images/common/bg-pt.png);
  background-position: calc(50% - 41px) -20px;
}
@media screen and (max-width: 47.9375em) {
  .section-line02 {
    background-position: calc(50% - 4.8309178744vw) 3.6231884058vw;
    background-size: 15.4589371981vw;
  }
}
.section-line02::before {
  background-image: url(../images/common/line02.png);
}
@media screen and (max-width: 47.9375em) {
  .section-line02::before {
    background-image: url(../images/common/line02_sp.png);
  }
}

.section-line03 {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.section-line03::before {
  background-image: url(../images/common/line03.png);
}
@media screen and (max-width: 47.9375em) {
  .section-line03::before {
    background-image: url(../images/common/line03_sp.png);
  }
}

.section-line04::before {
  background-image: url(../images/common/line04.png);
}
@media screen and (max-width: 47.9375em) {
  .section-line04::before {
    background-image: url(../images/common/line04_sp.png);
  }
}

.section-line-double .bg03 {
  position: absolute;
  top: -111px;
  left: 50%;
  transform: translateX(-50%);
  width: max(1920px, 100%);
  height: 111px;
  background-image: url(../images/common/bg03.png);
  background-repeat: repeat-x;
  background-position: center bottom -2px;
  mix-blend-mode: multiply;
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .section-line-double .bg03 {
    top: -13.2850241546vw;
    width: 100%;
    background-image: url(../images/common/bg03_sp.png);
    background-size: contain;
    height: 13.2850241546vw;
  }
}

/* --- section-hari --- */
.section-hari {
  position: relative;
}
.section-hari::after {
  content: "";
  position: absolute;
  top: -279px;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 504.66px;
  background-repeat: no-repeat;
  background-position: left -24px center;
  background-size: contain;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .section-hari::after {
    top: -33.6956521739vw;
    width: 100%;
    height: 40.6328502415vw;
  }
}
.section-hari .inner {
  position: relative;
  z-index: 1;
}

.section-hari-rgt::after {
  top: -189px;
  background-position: left -32px center;
  transform: translateX(-50%) scaleX(-1);
}
@media screen and (max-width: 47.9375em) {
  .section-hari-rgt::after {
    top: -22.8260869565vw;
    background-position: left -3.8647342995vw center;
  }
}

.section-hari-wh::after {
  background-image: url(../images/common/hari01.svg);
  filter: brightness(0) invert(1);
}

.section-hari01::after {
  background-image: url(../images/common/hari01.svg);
}

.section-hari02::after {
  background-image: url(../images/common/hari02.svg);
}

.section-hari03::after {
  background-image: url(../images/common/hari03.svg);
}

/* --- section-bg --- */
.section-bg {
  position: relative;
  z-index: 1;
}
.section-bg + .section-line {
  z-index: 1;
}

.bg01 {
  position: absolute;
  top: -359px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: max(1920px, 100%);
  height: 359px;
  z-index: -1;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .bg01 {
    top: -29.9516908213vw;
    width: 100%;
    height: 22.2222222222vw;
  }
}
.bg01::before, .bg01::after {
  content: "";
  display: block;
  background-repeat: no-repeat, no-repeat;
  width: 532px;
  height: 100%;
  background-image: url(../images/common/bg01.png);
}
@media screen and (max-width: 47.9375em) {
  .bg01::before, .bg01::after {
    width: 26.8115942029vw;
    background-image: url(../images/common/bg01_sp.png);
    background-size: cover;
  }
}
.bg01::before {
  background-position: left;
}
.bg01::after {
  background-position: right;
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 960px;
}

.inner-xs {
  max-width: 720px;
}

.inner-md {
  max-width: 1400px;
}

.inner-md2 {
  max-width: 1320px;
}

.inner-md3 {
  max-width: 1308px;
}

.inner-lg {
  max-width: 1440px;
}

.inner-xl {
  max-width: 89.5833333333%;
}
@media screen and (max-width: 67.5em) {
  .inner-xl {
    max-width: 90%;
  }
}
@media screen and (max-width: 47.9375em) {
  .inner-xl {
    max-width: 86%;
  }
}

/* --- blc --- */
.blc:not(:last-child) {
  margin-bottom: 64px;
}
@media screen and (max-width: 47.9375em) {
  .blc:not(:last-child) {
    margin-bottom: 48px;
  }
}

.blc-sm:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .blc-sm:not(:last-child) {
    margin-bottom: 24px;
  }
}

.blc-lg:not(:last-child) {
  margin-bottom: 80px;
}
@media screen and (max-width: 47.9375em) {
  .blc-lg:not(:last-child) {
    margin-bottom: 64px;
  }
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px; /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -64px; /* 固定ヘッダーの高さ */
  }
}

/* section-contact
********************************************** */
/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4,
.flex-col5 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col5 > * {
  width: 20%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col5.gap {
  gap: 1%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

.flex-col5.gap > * {
  width: 19.2%;
  margin-bottom: 1%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col5.gap-wide {
    gap: 3%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col5.gap-wide > * {
    width: 17.6%;
    margin-bottom: 3%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > *,
  .flex-col5.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > *,
  .flex-col5.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > *,
  .flex-col5.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child,
  .flex-col5.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-col4.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col4.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col5.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col5.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col3.gap.flex-sp-col2 > * {
    width: 48.5%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  position: relative;
  z-index: 1;
}

/* footer_top
********************************************** */
.footer--logo a {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
@media print, screen and (min-width: 48em) {
  .footer--logo a {
    min-width: min(370px, 100%);
  }
}

.footer--logo img {
  display: block;
}
.footer--logo img.logo-mark {
  width: 19.4594594595%;
}
.footer--logo img.logo-mark:not(:last-child) {
  margin-bottom: 1.25rem;
}
.footer--logo img.logo-txt {
  width: 100%;
}

@media print, screen and (min-width: 48em) {
  .footer--txt {
    letter-spacing: 0.14em;
  }
}

@media print, screen and (min-width: 48em) {
  .footer--cta li:not(:last-child) {
    margin-right: 50px;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer--cta li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.footer--tel a svg {
  fill: var(--clr-main, #1D7FB7) !important;
}

/* footer_md
********************************************** */
.footer_md {
  max-width: 1067px;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .footer_md {
    display: grid;
    grid-template-columns: 39.3626991565% auto;
    column-gap: 8.1537019681%;
    align-items: center;
  }
}

@media screen and (max-width: 47.9375em) {
  .footer_md-lft {
    margin-bottom: 24px;
  }
}

.footer--pa {
  padding-bottom: 1.125rem;
  border-bottom: 1px solid rgba(0, 100, 99, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .footer--pa {
    margin-bottom: 1.125rem;
  }
}

.footer--pa img {
  margin-bottom: 12px;
}

.footer--pa p {
  display: grid;
  grid-template-columns: 45px auto;
  column-gap: 12px;
  align-items: center;
  line-height: 1.5;
  letter-spacing: 0.04em;
}

.icon-pa {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-wht, #fff);
  background-color: var(--clr-main, #1D7FB7);
  margin-left: auto;
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-weight: 700;
}

@media print, screen and (min-width: 48em) {
  .fnav--menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .fnav--menu li {
    width: 50%;
  }
}

.fnav--menu a {
  position: relative;
  display: block;
  padding: 0.3125em 0 0.3125em 1.3125em;
  letter-spacing: 0.22em;
}
.fnav--menu a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0.5rem;
  height: 0.5rem;
  color: var(--clr-main, #1D7FB7);
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
}
.fnav--menu a:hover {
  color: var(--clr-main, #1D7FB7);
}

.sns--list li:not(:last-child) {
  margin-right: 1rem;
}

.sns--list a {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.sns--list a:hover {
  opacity: 0.7;
}

.sns--list svg {
  width: 1.875rem;
  height: 1.875rem;
  fill: var(--clr-main, #1D7FB7);
}

.footer--map {
  aspect-ratio: 1/1;
  border: solid 4px #75bbbb;
  opacity: 0.98;
  border-radius: 1rem;
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: 8px 0;
}

@media print, screen and (min-width: 48em) {
  .copyright {
    margin-right: 1em;
  }
}
@media screen and (max-width: 47.9375em) {
  .copyright {
    margin-bottom: 0.5em;
  }
}

.pbl a,
.pbl img {
  display: block;
}

.pbl a {
  opacity: 0.5;
}
.pbl a:hover {
  opacity: 1;
}

.copyright {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 152px;
  background-color: var(--bg-wht, #fff);
  z-index: 9999;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 64px;
    box-shadow: 0 0 1rem rgba(0, 100, 99, 0.2);
  }
}
.header > .inner {
  height: 100%;
}
@media screen and (max-width: 100em) {
  .header > .inner {
    width: 95%;
    max-width: calc(100% - 80px);
  }
}
@media screen and (max-width: 83.75em) {
  .header > .inner {
    max-width: calc(100% - 56px);
  }
}
@media screen and (max-width: 67.5em) {
  .header > .inner {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
@media screen and (max-width: 47.9375em) {
  .header > .inner {
    max-width: 90%;
  }
}

.page-contact .header {
  position: absolute;
}

@media print, screen and (min-width: 67.5625em) {
  .header--logo {
    margin-right: 3.1976744186%;
  }
}
@media screen and (min-width: 67.5625em) and (max-width: 100em) {
  .header--logo {
    margin-right: 1.7441860465%;
  }
}

.header--logo a,
.footer--logo a {
  display: block;
  width: fit-content;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

.header--logo img {
  max-width: 359px;
}
@media screen and (max-width: 100em) {
  .header--logo img {
    max-width: 300px;
  }
}
@media screen and (max-width: 83.75em) {
  .header--logo img {
    max-width: 240px;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .header--logo img {
    max-width: 200px;
  }
}

.header--txt {
  letter-spacing: 0.1em;
}
@media screen and (max-width: 100em) {
  .header--txt {
    font-size: 0.75rem;
  }
  .header--txt .fz-24 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 67.5em) {
  .header--txt {
    line-height: 1.2;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    height: 80px;
    box-shadow: 0 0 1rem rgba(0, 100, 99, 0.2);
  }
}
/* gnav
********************************************** */
.gnav a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gnav--link {
  padding: 0 1.25rem;
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
}

.menu-item-has-children {
  position: relative;
}

.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  align-items: center;
  letter-spacing: -0.04em;
  line-height: var(--line-height-hdr, 1.4);
}
@media screen and (max-width: 47.9375em) {
  .cta_tel a {
    justify-content: center;
  }
}
.cta_tel a svg {
  display: block;
  width: 2rem;
  fill: #75bbbb;
  margin-right: 0.2857142857em;
}

.cta_tel a > span {
  display: block;
}

.cta_contact a {
  font-size: 1.5rem;
  padding: 0.375em 1em;
  letter-spacing: 0.08em;
}

.gnav--contact a {
  font-size: 1.125rem;
  border-radius: 0.5rem;
  padding: 0.8888888889em 1.4722222222em;
}
@media screen and (max-width: 100em) {
  .gnav--contact a {
    font-size: 1.0625rem;
    padding: 0.8888888889em 1.2222222222em;
  }
}

.gnav--contact svg {
  fill: #fff;
  margin-right: 8px;
}

.gnav--cta .tel {
  font-size: 1.25rem;
}

.gnav--cta .num {
  font-size: 1.5rem;
}

.gnav--cta .hours {
  font-size: 0.875rem;
}

@media print, screen and (min-width: 67.5625em) {
  .gnav li,
  .gnav a {
    height: 100%;
  }
  .gnav {
    display: flex;
    height: 56px;
    margin-left: auto;
  }
  /* ---CTA --- */
}
@media screen and (min-width: 67.5625em) and (max-width: 100em) {
  .gnav {
    font-size: 0.9375rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav_btn,
  .tel_btn {
    display: none;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li:not(:last-child)::after {
    content: "";
    display: block;
    width: 0.125rem;
    height: 0.625rem;
    background-color: #ddd;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-main, #1D7FB7);
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a {
    background-color: #fff;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--cta {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 67.5625em) and (max-width: 100em) {
  .gnav--cta {
    margin-left: 0.5rem;
  }
}
@media screen and (min-width: 67.5625em) and (max-width: 83.75em) {
  .gnav--cta {
    position: absolute;
    top: calc(100% + 8px);
    right: 28px;
    margin-left: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav {
    position: fixed;
    top: 64px;
    right: 0;
    width: 100%;
    height: calc(100% - 64px);
    padding: 24px 7%;
    pointer-events: none;
    background-color: var(--bg-off_wht, #f7f7f7);
    overflow: scroll;
    z-index: 300;
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default, all 0.4s ease-out);
    opacity: 0;
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu {
    display: block;
    margin-bottom: 24px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 2px dotted rgba(0, 100, 99, 0.5);
  }
  .gnav--menu a {
    display: block;
    padding: 1.13em 1em;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.625rem;
    margin: auto;
    width: 0.4375rem;
    height: 0.4375rem;
    border-top: 2px solid var(--clr-main, #1D7FB7);
    border-right: 2px solid var(--clr-main, #1D7FB7);
    transform: rotate(45deg);
  }
  .gnav--cta {
    display: none;
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1D7FB7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -1.25rem;
    width: 4rem;
    height: 4rem;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1D7FB7);
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.6875rem;
    height: 1px;
    border-bottom: 2px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
    background-color: var(--bg-main, #ccc);
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1D7FB7);
  }
  .gnav_btn {
    right: 0;
    background-color: var(--clr-main, #1D7FB7);
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--bg-wht, #fff);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }
  /* ---CTA --- */
}
@media screen and (max-width: 47.9375em) {
  .gnav--menu a {
    padding: 19px 16px;
  }
}
/* hero
********************************************** */
.hero {
  position: relative;
  height: 720px;
}
@media screen and (max-width: 67.5em) {
  .hero {
    padding-top: 54px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero {
    height: 576px;
    padding-top: 7vw;
  }
  .hero .inner {
    max-width: 90%;
  }
}

.hero_slide {
  position: absolute !important;
  top: 0;
  left: 0;
  border-radius: 1rem;
  overflow: hidden;
}
.hero_slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/hero-frame.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide::before {
    background-image: url(../images/hero-frame_sp.png);
  }
}

.hero .inner,
.hero--txtarea {
  height: 100%;
}

.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}

.hero_slide .slick-list {
  position: relative;
  z-index: -1;
}

.hero_slide li {
  aspect-ratio: 1720/720;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide li {
    border-radius: 1rem;
    overflow: hidden;
  }
}

.hero_slide .slick-arrows {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  aspect-ratio: 80/64;
  color: var(--clr-main, #1D7FB7);
  background-color: var(--bg-wht, #fff);
  z-index: 1;
  pointer-events: auto;
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide .slick-arrows {
    width: 56px;
  }
}
.hero_slide .slick-arrows::after {
  content: "";
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  transform: rotate(45deg);
}
.hero_slide .slick-prev {
  left: 0;
}
.hero_slide .slick-prev::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
  width: 2px;
  height: 37.5%;
  border-right: 2px solid #ddd;
}
.hero_slide .slick-prev::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.hero_slide .slick-next {
  left: 80px;
  border-radius: 0 0.625rem 0 0;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide .slick-next {
    left: 56px;
  }
}
.hero_slide .slick-next::after {
  border-top: 2px solid;
  border-right: 2px solid;
}

.hero--txtarea {
  width: 90%;
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .hero--txtarea {
    align-items: flex-start;
    padding-top: 40%;
  }
}

.hero--lead {
  position: relative;
  line-height: 2.77;
  letter-spacing: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media print, screen and (min-width: 48em) {
  .hero--lead {
    width: 441px;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero--lead {
    line-height: var(--line-height-default, 1.8);
    width: fit-content;
    min-width: min(8em, 100%);
    margin-left: auto;
    font-size: 1rem;
  }
}
.hero--lead::before, .hero--lead::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.hero--lead::before {
  top: 0;
  left: 0;
  width: 11.1111111111%;
  aspect-ratio: 49/50;
  background-image: url(../images/common/icon-kyu.svg);
}
@media screen and (max-width: 47.9375em) {
  .hero--lead::before {
    top: inherit;
    bottom: -30%;
    left: 35%;
    transform: translateX(-50%);
    width: 22.2727272727%;
  }
}
.hero--lead::after {
  bottom: 0;
  right: 0;
  width: 7.4829931973%;
  aspect-ratio: 33/63;
  background-image: url(../images/common/icon-hari.svg);
}
@media screen and (max-width: 47.9375em) {
  .hero--lead::after {
    bottom: -45%;
    left: 65%;
    right: inherit;
    transform: translateX(-50%);
    width: 15%;
  }
}

.hero--copyright {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2.0833333333%;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .hero--copyright {
    display: none;
  }
}

/* schedule
********************************************** */
.schedule:not(:last-child):not(.schedule-fx) {
  margin-bottom: 0.875rem;
}

.schedule-fx {
  border-radius: 1rem 0 0 1rem;
  background-color: var(--bg-wht, #fff);
}
@media print, screen and (min-width: 67.5625em) {
  .schedule-fx {
    position: fixed;
    bottom: 24px;
    right: 0;
    display: grid;
    grid-template-columns: 66px auto;
    width: 568px;
    height: 284px;
    transform: translateX(500px);
    transition: var(--transit-default, all 0.4s ease-out);
    z-index: 9990;
  }
  .schedule-fx.active {
    transform: translateX(0);
  }
}
@media screen and (max-width: 67.5em) {
  .schedule-fx {
    border-radius: 1rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .schedule-fx {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: -24px;
    margin-left: -7%;
    margin-right: -7%;
  }
}
.schedule-fx .schedule--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 67.5em) {
  .schedule-fx .schedule--btn {
    display: none;
  }
}
.schedule-fx .schedule--btn:hover {
  color: var(--clr-sub, #ccc);
}
.schedule-fx .schedule--btn svg {
  margin-bottom: 11px;
  fill: #75bbbb;
}
.schedule-fx .schedule--blc {
  position: relative;
  padding: 24px 44px 36px 36px;
}
@media screen and (max-width: 47.9375em) {
  .schedule-fx .schedule--blc {
    padding: 16px 7% 24px;
  }
}
.schedule-fx .schedule--blc::before {
  content: "";
  position: absolute;
  top: 35px;
  bottom: 35px;
  left: 0;
  border-left: 2px solid #ddd;
  width: 2px;
}
@media screen and (max-width: 67.5em) {
  .schedule-fx .schedule--blc::before {
    display: none;
  }
}
.schedule-fx .schedule--table {
  background-color: var(--bg-off_wht, #f7f7f7);
  border-radius: 0.5rem;
}
.schedule-fx .schedule--table tr:last-child th::before,
.schedule-fx .schedule--table tr:last-child td::before {
  display: none;
}
.schedule-fx .schedule--table th:first-child,
.schedule-fx .schedule--table td:first-child {
  padding-left: 1.125rem;
}
.schedule-fx .schedule--table th:first-child::before,
.schedule-fx .schedule--table td:first-child::before {
  left: 1.125rem;
}
.schedule-fx .schedule--table th:last-child,
.schedule-fx .schedule--table td:last-child {
  padding-right: 1.125rem;
}
.schedule-fx .schedule--table th:last-child::before,
.schedule-fx .schedule--table td:last-child::before {
  right: 1.125rem;
}
.schedule-fx .schedule--table th.txt-main,
.schedule-fx .schedule--table td.txt-main {
  color: #75bbbb;
}
.schedule-fx .schedule--map {
  border-color: #75bbbb;
}
.schedule-fx .schedule--info {
  padding: 0.875rem 0 0.5rem;
}

.schedule--ttl.ttl-03 {
  width: fit-content;
  line-height: 1.83;
  letter-spacing: 0.08em;
  padding: 0 0.2916666667em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 30.625em) {
  .schedule--ttl.ttl-03 {
    font-size: 3.6734693878vw;
  }
}
.schedule--ttl.ttl-03:not(:last-child) {
  margin-bottom: 0 !important;
}
.schedule--ttl.ttl-03 span {
  position: relative;
  display: block;
  padding: 0 1em;
}

.schedule--ttl::before, .schedule--ttl::after,
.schedule--ttl span::before,
.schedule--ttl span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 1.875rem;
  border-right: 2px solid;
}
.schedule--ttl::before,
.schedule--ttl span::before {
  left: 0;
  transform: rotate(-36deg);
}
.schedule--ttl::after,
.schedule--ttl span::after {
  right: 0;
  transform: rotate(36deg);
}

.schedule--table {
  width: 100%;
}

.schedule--table th,
.schedule--table td {
  position: relative;
  padding: 0.40625rem 0.5rem;
  text-align: center;
}
.schedule--table th:first-child,
.schedule--table td:first-child {
  padding-right: 1.125rem;
  text-align: left;
}
.schedule--table th::before,
.schedule--table td::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(185, 177, 164, 0.3);
}

.schedule--info {
  display: flex;
  align-items: center;
  padding-top: 1.125rem;
}

.schedule--address {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.85;
  letter-spacing: 0.052em;
}
@media screen and (max-width: 47.9375em) {
  .schedule--address .fz-13 {
    display: block;
    width: 100%;
  }
}
.schedule--address svg {
  display: block;
  fill: var(--clr-main, #1D7FB7);
  margin-right: 0.5625rem;
}

.schedule--map {
  width: fit-content;
  padding: 0 0.625rem;
  border-radius: 9999px;
  color: #75bbbb;
  background-color: var(--bg-wht, #fff);
  border: 1px solid var(--clr-wht, #fff);
  letter-spacing: 0.06em;
  margin-left: auto;
  white-space: nowrap;
}
.schedule--map:hover {
  color: var(--clr-wht, #fff);
  border-color: #75bbbb;
  background-color: #75bbbb;
}

.schedule .schedule--list {
  padding-top: 0.5625rem;
  border-top: 1px solid #e8e8e8;
}
@media print, screen and (min-width: 48em) {
  .schedule .schedule--list li:not(:last-child) {
    margin-right: 23px;
  }
}
.schedule .schedule--tel svg {
  width: 1.3125rem;
  margin-right: 0.5rem;
}
.schedule .schedule--list .schedule--contact {
  width: fit-content;
  min-width: min(210px, 100%);
}
@media screen and (max-width: 47.9375em) {
  .schedule .schedule--list .schedule--contact {
    margin-left: auto;
    margin-right: auto;
  }
}
.schedule .schedule--contact a {
  font-size: 1rem;
}

/* home_news
********************************************** */
.home_news {
  padding-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .home_news {
    margin-top: -102px;
    padding-bottom: 24px !important;
  }
  .home_news .news_list {
    max-width: 480px;
    height: 40px;
    background-color: var(--bg-wht, #fff);
    border-radius: 9999px;
    padding: 0 32px;
    margin-left: 192px;
  }
  .home_news .news_list--ttl {
    margin-left: 208px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_news {
    padding-top: 40px;
  }
  .home_news .news_list {
    box-shadow: none;
    margin-bottom: 24px;
  }
}
.home_news .news_list--ttl {
  line-height: 2.85;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .home_news .news_list--ttl {
    font-size: 1.7142857143rem;
    color: var(--clr-main, #1D7FB7);
    text-align: center;
  }
}

/* home_bnr
********************************************** */
.home_bnr {
  margin-top: -247px;
}
@media print, screen and (min-width: 48em) {
  .home_bnr {
    display: grid;
    grid-template-columns: 52.7857142857% auto;
    gap: 5.1612903226% 4%;
  }
  .home_bnr .bnr-support {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_bnr {
    margin-top: -124px;
  }
}

.home_bnr > * {
  display: block;
  border-radius: 0.5rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_bnr > *:not(:last-child) {
    margin-bottom: 8px;
  }
}

.home_bnr a:hover {
  opacity: 0.7;
}

/* home_about
********************************************** */
.home_about {
  margin-top: 237px;
}
.home_about::after {
  top: -122px;
}
@media screen and (max-width: 47.9375em) {
  .home_about::after {
    top: -14.7342995169vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about {
    margin-top: 118px;
  }
}

@media print, screen and (min-width: 48em) {
  .home_about--blc {
    display: grid;
    grid-template-columns: auto 47.9285714286%;
    align-items: center;
  }
}

@media print, screen and (min-width: 48em) {
  .home_about--txtarea {
    padding-left: 5.7142857143%;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea {
    margin-bottom: 24px;
  }
}
.home_about--txtarea .mce-content-body {
  line-height: 2.13;
}

.home_about--imgarea {
  position: relative;
  aspect-ratio: 671/467;
}
@media print, screen and (min-width: 48em) {
  .home_about--imgarea {
    min-width: 560px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about--imgarea {
    width: min(588px, 100%);
    margin-left: auto;
    margin-right: auto;
  }
}
.home_about--imgarea img {
  position: absolute;
}
.home_about--imgarea .fukidashi {
  width: 23.8450074516%;
}
@media screen and (max-width: 98.1875em) {
  .home_about--imgarea .fukidashi {
    border-width: max(3.3049013367px, 0.254614895vw);
    font-size: max(13.2196053469px, 1.0184595799vw);
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about--imgarea .fukidashi {
    border-width: min(4px, 0.6861063465vw);
    font-size: min(1rem, 2.4013722127vw);
  }
}

.home_about--img01 {
  top: 5.1391862955%;
  left: 20.1192250373%;
  width: 46.4977645306%;
}

.home_about--img02 {
  top: 34.6895074946%;
  right: 0;
  width: 34.7242921013%;
}

.home_about--fukidashi01 {
  top: 0;
  left: 0;
}

.home_about--fukidashi02 {
  top: 0;
  right: 5.8122205663%;
}

.home_about--fukidashi03 {
  bottom: 0;
  left: 32.7868852459%;
}

.home_about--icon01 {
  bottom: 7.9229122056%;
  left: 7.6005961252%;
  width: 4.9180327869%;
}

.home_about--icon02 {
  bottom: 16.7023554604%;
  left: 16.9895678092%;
  width: 7.302533532%;
}

/* home_menu
********************************************** */
.home_menu::after {
  top: -286px;
  background-position: left -64px center;
}
@media screen and (max-width: 47.9375em) {
  .home_menu::after {
    top: -34.5410628019vw;
    background-position: left -7.729468599vw center;
  }
}

.home_menu--txt {
  line-height: 1.75;
  letter-spacing: 0.08em;
}

.home_menu--list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2.2727272727%;
}
@media screen and (max-width: 47.9375em) {
  .home_menu--list {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

.home_menu--list a {
  position: relative;
  display: grid;
  grid-template-rows: 89px auto;
  align-items: center;
  border-radius: 1rem;
  background-color: var(--bg-wht, #fff);
  padding: 2.375rem 1.5rem 2.625rem;
}
.home_menu--list a:hover {
  box-shadow: 0 0 1.5rem rgba(0, 100, 99, 0.3);
}
.home_menu--list a:hover svg {
  fill: #ffa69d;
}
.home_menu--list a svg {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  fill: #75bbbb;
}

.home_menu--ttl {
  line-height: 1.54;
  letter-spacing: 0.22em;
}

.home_menu--ttl:not(:last-child) {
  margin-bottom: 0.15em !important;
}

.home_menu--list .font-en {
  letter-spacing: 0.1em;
  text-align: center;
  color: #ffa59c;
}

/* home_voice
********************************************** */
.home_voice::after {
  top: -337px;
}
@media screen and (max-width: 47.9375em) {
  .home_voice::after {
    top: -40.7004830918vw;
  }
}

.voice_slide {
  z-index: 1;
}

.voice_slide .slick-list {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.voice_slide .slick-arrows {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  width: 6.25rem;
  height: 6.25rem;
  background-color: #75bbbb;
  border-radius: 50%;
  box-shadow: 0 0 1rem rgba(0, 100, 99, 0.2);
  z-index: 1;
}
.voice_slide .slick-arrows::before {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 1.125rem;
  background-image: url(../images/common/icon-link.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: brightness(0) invert(1);
}
.voice_slide .slick-arrows:hover {
  background-color: var(--clr-main, #1D7FB7);
}

.voice_slide .slick-prev {
  left: calc(50% - 650px);
}
.voice_slide .slick-prev::before {
  transform: rotate(-135deg);
}

.voice_slide .slick-next {
  right: calc(50% - 650px);
}
.voice_slide .slick-next::before {
  transform: rotate(45deg);
}

.voice_slide li {
  background-color: var(--bg-wht, #fff);
  border-radius: 1.25rem 2.5rem 1.25rem 1.25rem;
  margin-left: 40px;
  margin-right: 40px;
}
@media print, screen and (min-width: 48em) {
  .voice_slide li {
    display: grid !important;
    grid-template-columns: 33.3333333333% auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .voice_slide li {
    margin-left: 7vw;
    margin-right: 7vw;
  }
}

.home_voice--imgarea {
  border-radius: 1.25rem 0 0 1.25rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_voice--imgarea {
    border-radius: 1.25rem 1.25rem 0 0;
  }
  .home_voice--imgarea img {
    aspect-ratio: 3/2;
  }
}

.home_voice--txtarea {
  position: relative;
  padding: 3.5rem 2.5rem 3rem;
}
@media screen and (max-width: 47.9375em) {
  .home_voice--txtarea {
    padding: 24px;
  }
}

.home_voice--ttl {
  line-height: 1.83;
  letter-spacing: 0.2em;
}
.home_voice--ttl::after {
  content: "";
  display: block;
  width: 1em;
  height: 2px;
  border-bottom: 2px solid #ddd;
  margin-top: 0.8em;
}

.home_voice--txt {
  line-height: 1.75;
  letter-spacing: 0.08em;
}

.home_voice--name {
  line-height: 2.43;
  letter-spacing: 0.08em;
}

.home_voice--comment {
  padding: 16px 32px 24px;
}
@media screen and (max-width: 47.9375em) {
  .home_voice--comment {
    padding: 16px 12px 16px 24px;
  }
}
.home_voice--comment h3.font-en {
  color: #75bbbb;
  line-height: 2.85;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 47.9375em) {
  .home_voice--scroll {
    max-height: 200px;
    overflow: auto;
    padding-right: 12px;
  }
}

.home_voice--icon {
  top: -15px;
  right: -15px;
}
@media screen and (max-width: 47.9375em) {
  .home_voice--icon {
    top: -60px;
    width: 96px;
    height: auto;
  }
}

/* cta
********************************************** */
.cta--ttl {
  width: fit-content;
  line-height: 1.38;
  letter-spacing: 0.12em;
  padding: 0 0.34375em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .cta--ttl {
    font-size: 1.4285714286rem !important;
  }
}

.cta--ttl span:not(.sp-none-inline) {
  display: block;
}

.cta--ttl > span {
  position: relative;
  padding: 0 1.90625em;
}
@media screen and (max-width: 47.9375em) {
  .cta--ttl > span {
    padding: 0 1.25em;
  }
}

.cta--ttl::before, .cta--ttl::after,
.cta--ttl > span::before,
.cta--ttl > span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 3.875rem;
  border-right: 4px solid #2d2d2d;
}
@media screen and (max-width: 47.9375em) {
  .cta--ttl::before, .cta--ttl::after,
  .cta--ttl > span::before,
  .cta--ttl > span::after {
    width: 2px;
    border-width: 2px;
  }
}
.cta--ttl::before,
.cta--ttl > span::before {
  left: 0;
  transform: rotate(-38deg);
}
.cta--ttl::after,
.cta--ttl > span::after {
  right: 0;
  transform: rotate(38deg);
}

.cta--ttl .fz-13 {
  line-height: 1.85;
  letter-spacing: 0.08em;
}

@media screen and (max-width: 47.9375em) {
  .cta--list {
    display: block;
  }
}

@media print, screen and (min-width: 48em) {
  .cta--list li:not(:last-child) {
    margin-right: 45px;
  }
}
@media screen and (max-width: 47.9375em) {
  .cta--list li:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media print, screen and (min-width: 48em) {
  .cta--list .cta_contact {
    width: 316px;
  }
}

/* home_faq
********************************************** */
.home_faq--deco_wrap {
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco_wrap {
    position: relative;
    height: 95.652173913vw;
    margin-top: -23.1884057971vw;
  }
}

.home_faq--deco01 {
  top: 318px;
  left: 395px;
  width: 64px;
  transform: rotate(-30deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco01 {
    top: 38.4057971014vw;
    left: 23.5507246377vw;
    width: 7.729468599vw;
  }
}

.home_faq--deco01-2 {
  top: 706px;
  right: 427px;
  width: 64px;
  transform: rotate(15deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco01-2 {
    top: 85.2657004831vw;
    right: 27.4154589372vw;
    width: 7.729468599vw;
  }
}

.home_faq--deco02 {
  top: 271px;
  left: 330px;
  width: 59px;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco02 {
    top: 32.729468599vw;
    left: 15.7004830918vw;
    width: 7.1256038647vw;
  }
}

.home_faq--deco02-2 {
  top: 587px;
  right: 407px;
  width: 59px;
  transform: scale(-1, -1);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco02-2 {
    top: 70.8937198068vw;
    right: 25vw;
    width: 7.1256038647vw;
  }
}

.home_faq--deco03 {
  top: 517px;
  left: 444px;
  transform: rotate(15deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco03 {
    top: 62.4396135266vw;
    left: 29.4685990338vw;
    width: 4.7101449275vw;
  }
}

.home_faq--deco03-2 {
  top: 615px;
  left: 273px;
  transform: rotate(-30deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco03-2 {
    top: 75.4830917874vw;
    left: 8.8164251208vw;
    width: 4.7101449275vw;
  }
}

.home_faq--deco03-3 {
  top: 247px;
  right: 502px;
  transform: rotate(-30deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco03-3 {
    top: 29.8309178744vw;
    right: 36.4734299517vw;
    width: 4.7101449275vw;
  }
}

.home_faq--deco03-4 {
  top: 344px;
  right: 291px;
  transform: rotate(30deg);
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco03-4 {
    top: 41.5458937198vw;
    right: 10.9903381643vw;
    width: 4.7101449275vw;
  }
}

.home_faq--deco04 {
  top: 589px;
  left: 347px;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco04 {
    top: 71.1352657005vw;
    left: 17.7536231884vw;
    width: 16.5458937198vw;
  }
}

.home_faq--deco05 {
  top: 284px;
  right: 366px;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--deco05 {
    top: 34.2995169082vw;
    right: 20.0483091787vw;
    width: 19.6859903382vw;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_faq--fukidashi {
    width: 19.3236714976vw;
    border-width: 0.4830917874vw;
    font-size: 1.9323671498vw;
  }
}

.home_faq--fukidashi01 {
  top: 382px;
  left: 219px;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--fukidashi01 {
    top: 46.1352657005vw;
    left: 2.2946859903vw;
  }
}

.home_faq--fukidashi02 {
  top: 550px;
  right: 221px;
}
@media screen and (max-width: 47.9375em) {
  .home_faq--fukidashi02 {
    top: 66.4251207729vw;
    right: 2.5362318841vw;
  }
}

/* home_message
********************************************** */
.home_message::after {
  top: -283px;
}
@media screen and (max-width: 47.9375em) {
  .home_message::after {
    top: -34.1787439614vw;
  }
}

@media print, screen and (min-width: 48em) {
  .home_message--blc {
    display: grid;
    grid-template-columns: 55.0458715596% auto;
    column-gap: 8.4097859327%;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_message--txtarea {
    margin-bottom: 24px;
  }
}
.home_message--txtarea .mce-content-body {
  line-height: 2.13;
}
.home_message--txtarea .mce-content-body p:not(:last-child) {
  margin-bottom: 1.5em;
}

.home_message--imgarea {
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .home_message--imgarea {
    height: 480px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_message--imgarea {
    width: min(421px, 100%);
    margin-left: auto;
    margin-right: auto;
    transform: scale(0.9);
  }
}
.home_message--imgarea::before, .home_message--imgarea::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.home_message--imgarea::before {
  top: 6.25%;
  right: 2.7083333333%;
  width: 13.125%;
  aspect-ratio: 63/64;
  background-image: url(../images/common/icon-kyu.svg);
}
.home_message--imgarea::after {
  bottom: 5.625%;
  left: -7.5%;
  width: 12.2916666667%;
  aspect-ratio: 59/79;
  background-image: url(../images/common/icon-hari.svg);
}
.home_message--imgarea img {
  border-radius: 50%;
  border: solid 4px #2d2d2d;
}
@media screen and (max-width: 47.9375em) {
  .home_message--imgarea img {
    border-width: min(4px, 0.8196721311vw);
  }
}

.home_message--fukidashi01 {
  top: -1.6666666667%;
  left: -12.0833333333%;
  width: 33.3333333333%;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .home_message--fukidashi01 {
    border-width: min(4px, 0.8196721311vw);
    font-size: min(1rem, 2.868852459vw);
  }
  .home_message--fukidashi01::before {
    height: min(4px, 0.8196721311vw);
  }
}

.home_message--profile {
  position: relative;
  border-radius: 9999px;
  padding: 3rem 3.5rem 3rem 19.625rem;
}
@media print, screen and (min-width: 48em) {
  .home_message--profile {
    display: grid;
    grid-template-columns: 248px auto;
    align-items: flex-start;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_message--profile {
    padding: 24px;
    border-radius: 1.25rem;
  }
}
.home_message--profile .ttl-03 {
  padding-left: 1.875em;
  line-height: 1.83;
  letter-spacing: 0.26em;
}
.home_message--profile .ttl-03::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.375rem;
  height: 4.125rem;
  transform: translateY(-50%);
  background-color: #75bbbb;
  border-radius: 9999px;
}
.home_message--profile .mce-content-body {
  line-height: 1.71;
  letter-spacing: 0.08em;
}
.home_message--profile .mce-content-body p:not(:last-child) {
  margin-bottom: 2em;
}

.home_message--img01 {
  position: absolute;
  top: -28px;
}
@media print, screen and (min-width: 48em) {
  .home_message--img01 {
    left: -45px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_message--img01 {
    top: -56px;
    right: -24px;
    width: 116px;
    height: auto;
  }
}

/* home_contact
********************************************** */
.home_contact {
  padding-top: 80px;
  padding-bottom: 180px;
}
@media print, screen and (min-width: 48em) {
  .home_contact {
    margin-bottom: -101px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_contact {
    padding-top: 40px;
    padding-bottom: 0 !important;
  }
  .home_contact .inner {
    padding-bottom: 40px;
  }
}

@media print, screen and (min-width: 48em) {
  .home_contact--txtarea {
    margin-left: auto;
    width: 536px;
  }
}

.home_contact--txt {
  line-height: 2.13;
}

.home_contact--deco_wrap {
  top: -101px;
  bottom: 101px;
}
@media screen and (max-width: 47.9375em) {
  .home_contact--deco_wrap {
    position: relative;
    top: inherit;
    bottom: inherit;
    height: 72.2222222222vw;
  }
}

.home_contact--deco {
  background-image: url(../images/common/cta-bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_contact--deco {
    background-image: url(../images/common/cta-bg_sp.png);
    background-size: contain;
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-image: url(../images/common/bg-pt.png);
}

.page_ttl--bg-wrap {
  height: 500px;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg-wrap {
    height: 120px;
  }
}

.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.page_ttl-jp {
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 2rem;
  }
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 1.1428571429rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .page_ttl--list_wrap {
    padding-top: 32px;
  }
}

@media screen and (max-width: 47.9375em) {
  .page_ttl--list {
    gap: 4px;
  }
}

@media print, screen and (min-width: 48em) {
  .page_ttl--list li {
    min-height: 96px;
  }
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list li {
    width: calc(50% - 2px);
  }
}

.page_ttl--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr, 1.4);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a {
    padding: 1em 1.5em;
    justify-content: flex-start;
    text-align: left;
    background-color: var(--bg-wht, #fff);
    border-radius: 0.3125rem;
  }
}
.page_ttl--list a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.875rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.375rem;
  background-color: var(--clr-body, #000);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--list a::before {
    right: 0.7142857143rem;
  }
}
.page_ttl--list a:hover {
  color: var(--clr-main, #1D7FB7);
}
.page_ttl--list a:hover::before {
  background-color: var(--clr-main, #1D7FB7);
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  position: relative;
}

.ttl-01:not([class*=mgn-btm]),
.ttl-02:not([class*=mgn-btm]),
.ttl-03:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

.ttl-01 {
  font-size: 2.5rem;
  letter-spacing: 0.18em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2rem;
  }
  .ttl-01.txt-ctr-sp::after {
    margin-left: auto;
    margin-right: auto;
  }
}
.ttl-01:not(.txt-ctr)::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 2px;
  background-color: #ddd;
  margin-top: 1rem;
}
.ttl-01.txt-ctr::before {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../images/common/icon-harikyu.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 87px;
  aspect-ratio: 87/63;
  margin-bottom: 20px;
}
.ttl-01 .font-en {
  display: block;
  font-size: 0.8125rem;
  color: #75bbbb;
  text-transform: uppercase;
  line-height: 2.85;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.section-line01 .ttl-01 .font-en {
  color: var(--clr-wht, #fff);
}

.section-line02 .ttl-01 .font-en {
  color: var(--clr-main, #1D7FB7);
}

.ttl-02 {
  font-size: 2rem;
  line-height: 1.56;
  letter-spacing: 0.22em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 1.7142857143rem;
  }
}

.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.ttl-03 {
  font-size: 1.5rem;
  line-height: 1.83;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}

.post--img .img-contain {
  max-width: 80%;
}

.hover-lg .post--img {
  overflow: hidden;
}
.hover-lg .post--img img {
  transition: all 0.8s ease-out;
}
.hover-lg:hover .post--img img {
  transform: scale(1.05);
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8125rem;
  background-color: var(--clr-main, #1D7FB7);
  color: #fff;
  padding: 0.25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  border-radius: 9999px;
}

.cat_list a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 264px;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 4rem;
}

.side--ttl {
  letter-spacing: 0.1em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}

.side--ttl small {
  display: block;
  opacity: 0.5;
  margin-top: 0.2em;
}

/* --- list --- */
.side--list li {
  position: relative;
  padding-left: 1em;
}
.side--list li::before {
  content: "";
  position: absolute;
  top: 1em;
  left: 0;
  width: 0.5em;
  height: 2px;
  border-bottom: 2px solid var(--clr-sub, #ccc);
}

.side--list a {
  display: block;
  padding: 0.2em 0;
}
.side--list a:hover {
  color: var(--clr-main, #1D7FB7);
}

/* --- post --- */
.posts-side .post {
  display: grid;
  grid-template-columns: 4rem auto;
  column-gap: 1rem;
}
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

/* --- archive --- */
.side_column .archive_list {
  padding-left: 0;
}
.side_column .archive_list::before {
  display: none;
}

.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: var(--line-height-default, 1.8);
  padding: 0.2em 0;
  transition: var(--transit-default, all 0.4s ease-out);
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.archive_list--ttl:hover {
  color: var(--clr-main, #1D7FB7);
}

.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}

.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.archive-pulldown .archive_list a:hover {
  background-color: var(--bg-sub, #ddd);
}

.archive-pulldown .archive_list--label {
  margin-right: 8px;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ddd;
  color: inherit;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: var(--line-height-default, 1.8);
  letter-spacing: var(--ltr-space-default, 0.08em);
  transition: var(--transit-default, all 0.4s ease-out);
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.25rem;
  right: 0.75rem;
  margin: auto;
  width: 0.5rem;
  height: 0.5rem;
  color: var(--clr-main, #1D7FB7);
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: var(--transit-default, all 0.4s ease-out);
}
.archive-pulldown .archive_list--btn:hover {
  background-color: var(--bg-sub, #ddd);
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: 0;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default, all 0.4s ease-out);
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  border-radius: 1.25rem;
  overflow: hidden;
}
@media print, screen and (min-width: 48em) {
  .posts-blog .post {
    display: grid;
    grid-template-columns: 34.0909090909% auto;
    align-items: center;
  }
}
.posts-blog .post:not(:last-child) {
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .posts-blog .post--img {
    aspect-ratio: 4/3;
  }
}

.posts-blog .post--txtarea {
  padding: 40px;
}
@media screen and (max-width: 47.9375em) {
  .posts-blog .post--txtarea {
    padding: 24px;
  }
}

.posts-blog .post--date {
  color: #aaa;
}

.posts-blog .post--ttl {
  letter-spacing: 0.1em;
}

.posts-blog .post--link:not([href="javascript:void(0);"]):hover,
.posts-side .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}

/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: #fff;
}

/* --- news_list --- */
@media print, screen and (min-width: 48em) {
  .news_list.slick-slider::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.5rem;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    color: #75bbbb;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }
}

.news_list .post {
  padding-bottom: 1em;
  padding-top: 1em;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
  }
}

@media print, screen and (min-width: 48em) {
  .news_list.slick-slider .post {
    display: flex !important;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}

.news_list:not(.slick-slider) .post {
  border-bottom: 1px solid #ddd;
}
.news_list:not(.slick-slider) .post:first-child {
  border-top: 1px solid #ddd;
}
.news_list:not(.slick-slider) .post .post--link:not([href="javascript:void(0);"])::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.5rem;
  margin: auto;
  width: 0.5rem;
  height: 0.5rem;
  color: #75bbbb;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
}

@media print, screen and (min-width: 48em) {
  .news_list .post--info {
    margin-right: 1em;
  }
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info {
    margin-bottom: 0.5em;
  }
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1D7FB7);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main, #1D7FB7);
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}

#search-list .ttl-01 {
  margin: 70px 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 3rem 40px 40px;
  background-color: var(--bg-off_wht, #f7f7f7);
  border-radius: 1.25rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  div#ez-toc-container {
    padding: 3rem 24px 24px;
  }
}
div#ez-toc-container:not(:first-child) {
  margin-top: 2em;
}
div#ez-toc-container:not(:last-child) {
  margin-bottom: 2em;
}

.ez-toc-title-container {
  margin-top: -3rem;
  margin-bottom: 32px;
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
  background-color: var(--clr-main, #1D7FB7);
  padding: 0.625rem 1em;
}
@media screen and (max-width: 47.9375em) {
  .ez-toc-title-container {
    margin-bottom: 16px;
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);
  }
}

div#ez-toc-container p.ez-toc-title {
  color: var(--clr-wht, #fff);
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
div#ez-toc-container p.ez-toc-title::before {
  content: "";
  display: block;
  width: 1rem;
  aspect-ratio: 1/1;
  background-image: url(../images/common/icon-list.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 0.5em;
  filter: brightness(0) invert(1);
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 0;
}

div#ez-toc-container ul li {
  position: relative;
  padding-left: 1em;
}
div#ez-toc-container ul li::before {
  display: none;
}
div#ez-toc-container ul li::after {
  content: "";
  position: absolute;
  top: 1em;
  left: 0;
  width: 0.5em;
  height: 2px;
  border-bottom: 2px solid var(--clr-sub, #ccc);
}

div#ez-toc-container ul.ez-toc-list a {
  padding: 0.2em 0;
  color: var(--clr-main, #1D7FB7);
}
div#ez-toc-container ul.ez-toc-list a:hover {
  color: var(--clr-sub, #ccc);
  text-decoration: none;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* author
**************************************** */
@media print, screen and (min-width: 48em) {
  .author {
    display: grid;
    grid-template-columns: 30% auto;
    column-gap: 5%;
    padding: 32px;
  }
}

.author--img {
  object-fit: cover;
}
@media screen and (max-width: 47.9375em) {
  .author--img {
    display: block;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }
}

.author--ttl {
  position: relative;
  padding-left: 1em;
  letter-spacing: 0.1em;
}
.author--ttl::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.2em;
  height: 90%;
  background-color: #75bbbb;
  border-radius: 9999px;
}

/* blog--share
**************************************** */
.blog--share_ttl {
  width: fit-content;
  padding: 0 0.2916666667em;
  margin-left: auto;
  margin-right: auto;
}
.blog--share_ttl span {
  position: relative;
  display: block;
  padding: 0 1em;
}

.blog--share_ttl::before, .blog--share_ttl::after,
.blog--share_ttl span::before,
.blog--share_ttl span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 1.875rem;
  border-right: 2px solid;
}
.blog--share_ttl::before,
.blog--share_ttl span::before {
  left: 0;
  transform: rotate(-36deg);
}
.blog--share_ttl::after,
.blog--share_ttl span::after {
  right: 0;
  transform: rotate(36deg);
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem;
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 0.625rem;
  background-color: #f8f8f8;
}
.blog--share_list a:hover {
  background-color: var(--bg-off_wht, #f7f7f7);
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 0.625rem;
}

/* blog--related
**************************************** */
.posts_scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts_scroll .post {
    width: 70%;
  }
}

.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts_scroll .post--img {
  margin-bottom: 1rem;
}

.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts_scroll::-webkit-scrollbar {
  height: 0.375rem;
}

.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
  color: var(--clr-main, #1D7FB7);
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.375rem 1rem;
  color: var(--clr-main, #1D7FB7);
  background-color: var(--bg-main, #ccc);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wp-pagenavi a:hover {
  background-color: var(--bg-sub, #ddd);
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 2.5rem;
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 0.25rem;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 0.25rem;
}

.wp-pagenavi .extend {
  width: 1.5rem !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5rem;
  height: 0.5rem;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}
@media print, screen and (min-width: 48em) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.4074074074%;
  }
}
@media screen and (max-width: 47.9375em) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}

.sitemap--menu a:hover {
  color: var(--clr-main, #1D7FB7);
}

.sitemap--menu > li > a {
  font-size: 1.125rem;
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}

.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 1.25em;
}

.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main, #1D7FB7);
  border-right: 1px solid var(--clr-main, #1D7FB7);
  transform: rotate(45deg);
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--button.flex,
  .lps_parts--button .inner {
    width: fit-content;
    min-width: min(960px, 90%);
  }
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }
  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(300px, 100%);
  }
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 24px;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 40px;
}

/* 白背景 */
.lps_parts--column .column_item.bg-wh {
  border-radius: 1.25rem;
}
.lps_parts--column .column_item.bg-wh .img img {
  border-radius: 1.25rem 1.25rem 0 0;
}

/* 白背景なし */
.lps_parts--column .column_item:not(.bg-wh) .img img {
  border-radius: 1.25rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
  .lps_parts--column .lps_parts--child {
    display: block;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item {
    width: 100%;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 24px;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 10;
  padding: 16px !important;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
.faq_item {
  position: relative;
  border-radius: 1rem;
  background-color: var(--bg-wht, #fff);
  padding: 1.75rem 2.75rem;
  cursor: pointer;
}
.faq_item:not(:last-child) {
  margin-bottom: 1rem;
}
.faq_item .toggle {
  position: absolute;
  top: 1.25rem;
  right: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ffa59c;
  border-radius: 50%;
  transition: var(--transit-default, all 0.4s ease-out);
}
.faq_item .toggle::before, .faq_item .toggle::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.8125rem;
  height: 3px;
  background-color: var(--bg-wht, #fff);
  border-radius: 9999px;
  transition: var(--transit-default, all 0.4s ease-out);
}
.faq_item .toggle::after {
  transform: rotate(90deg);
}
.faq_item .toggle.active {
  transform: rotate(180deg);
  background-color: var(--bg-sub, #ddd);
}
.faq_item .toggle.active::after {
  opacity: 0;
}

.faq_item .ttl-03 {
  margin-bottom: 0 !important;
  font-size: 1.125rem;
  padding-left: 2em;
  padding-right: 1.6666666667em;
  margin-left: -1.1666666667em;
  line-height: 1.44;
  letter-spacing: 0.08em;
}
.faq_item .ttl-03::after {
  content: "Q.";
  position: absolute;
  top: -0.25em;
  left: 0;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-size: 1.375rem;
  line-height: 1.68;
  letter-spacing: 0.1em;
  color: #75bbbb;
}

.faq_item .mce-content-body {
  line-height: 1.75;
  letter-spacing: 0.08em;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: none;
}

@media print, screen and (min-width: 48em) {
  .faq_item .mce-content-body img.alignleft {
    margin-right: 32px;
  }
  .faq_item .mce-content-body img.alignright {
    margin-left: 32px;
  }
}
.faq_item .mce-content-body img.alignleft, .faq_item .mce-content-body img.alignright {
  margin-top: 0;
  overflow: hidden;
  max-width: 248px;
}
@media screen and (max-width: 47.9375em) {
  .faq_item .mce-content-body img.alignleft, .faq_item .mce-content-body img.alignright {
    float: none !important;
    margin-bottom: 16px !important;
  }
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 86px;
  counter-increment: number;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 48px;
  }
}

.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 20px;
  }
}

.lps_parts--flow .ttl-03 {
  font-size: 2rem;
  color: var(--clr-main, #1D7FB7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03 {
    font-size: 1.4285714286rem;
  }
}
.lps_parts--flow .ttl-03::after {
  content: counter(number);
  position: absolute;
  top: -6px;
  left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: var(--clr-main, #1D7FB7);
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03::after {
    top: -4px;
    left: -48px;
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.2857142857rem;
  }
}

.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 66px;
  left: 36px;
  display: block;
  width: 2px;
  height: calc(100% - 80px);
  border-right: 2px solid var(--clr-main, #1D7FB7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 38px;
    left: 18px;
    height: calc(100% - 48px);
  }
}

.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}

.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}

.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}

@media print, screen and (min-width: 48em) {
  .flow_item .mce-content-body img.alignleft {
    margin-right: 32px;
  }
  .flow_item .mce-content-body img.alignright {
    margin-left: 32px;
  }
}
.flow_item .mce-content-body img.alignleft, .flow_item .mce-content-body img.alignright {
  margin-top: 0;
  overflow: hidden;
  max-width: 248px;
}
@media screen and (max-width: 47.9375em) {
  .flow_item .mce-content-body img.alignleft, .flow_item .mce-content-body img.alignright {
    float: none !important;
    margin-bottom: 16px !important;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  transition: var(--transit-default, all 0.4s ease-out);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.lps_sec {
  position: relative;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 40px;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 16px;
  }
}

.lps_parts--button + .lps_parts--button {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--button + .lps_parts--button {
    margin-top: 8px;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}

.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}

.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}

.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}

.lps_parts--img_text .text {
  justify-content: flex-start;
}

.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}

/* 白背景 */
.lps_parts--img_text .lps_parts--child.bg-wh {
  border-radius: 1.25rem;
}
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--img img {
  border-radius: 1.25rem 0 0 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .lps_parts--child.bg-wh .img_text--img img {
    border-radius: 1.25rem 1.25rem 0 0;
  }
}
@media screen and (min-width: 48em) {
  .lps_parts--img_text .lps_parts--child.bg-wh.flex-reverse .img_text--img img {
    border-radius: 0 1.25rem 1.25rem 0;
  }
}

/* 白背景なし */
.lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img img {
  border-radius: 1.25rem;
}

/* レイアウトL
   ※Lも全部角丸にしたかったら削除！ */
.lps_parts--img_text .lps_parts--child.inner-lg .img_text--img img {
  border-radius: 0;
}

@media screen and (min-width: 48em) {
  .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)) .img_text--img img {
    border-radius: 0 1.25rem 1.25rem 0;
  }
  .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)).flex-reverse .img_text--img img {
    border-radius: 1.25rem 0 0 1.25rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .img img {
  display: inline-block;
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  transform: rotate(45deg);
  color: var(--clr-main, #1D7FB7);
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}

.lps_parts--slide .slick-prev::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
}

.lps_parts--slide .slick-next::after {
  border-top: 2px solid;
  border-right: 2px solid;
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--bg-main, #ccc);
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 0.375rem;
}
.lps_parts--slide .slick-dots li.slick-active, .lps_parts--slide .slick-dots li:hover {
  background-color: var(--clr-main, #1D7FB7);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}