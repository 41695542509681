@use '../abstracts/' as *;

/* home_voice
********************************************** */
.home_voice::after {
  top: -337px;
  @include mq(sp) {
    top: spvw(-168.5);
  }
}
.voice_slide {
  z-index: 1;
}
.voice_slide .slick-list {
  padding-top: rem(16) !important;
  padding-bottom: rem(16) !important;
}
.voice_slide .slick-arrows {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  @include rect(100);
  background-color: #75bbbb;
  border-radius: 50%;
  box-shadow: 0 0 rem(16) rgba($color: $clr-main, $alpha: .2);
  z-index: 1;
  &::before {
    content: '';
    display: block;
    @include rect(17,18);
    background-image: url(../images/common/icon-link.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: brightness(0) invert(1);
  }
  &:hover {
    background-color: var(--clr-main, #1D7FB7);
  }
}
.voice_slide .slick-prev {
  left: calc(50% - 650px);
  &::before {
    transform: rotate(-135deg);
  }
}
.voice_slide .slick-next {
  right: calc(50% - 650px);
  &::before {
    transform: rotate(45deg);
  }
}
.voice_slide li {
  background-color: var(--bg-wht, #fff);
  border-radius: rem(20) rem(40) rem(20) rem(20);
  margin-left: 40px;
  margin-right: 40px;
  @include mq(sp, min, ps) {
    display: grid !important;
    grid-template-columns: perc(320,960,'%') auto;
  }
  @include mq(sp) {
    margin-left: 7vw;
    margin-right: 7vw;
  }
}
.home_voice--imgarea {
  border-radius: rem(20) 0 0 rem(20);
  overflow: hidden;
  @include mq(sp) {
    border-radius: rem(20) rem(20) 0 0;
    img {
      aspect-ratio: 3/2;
    }
  }
}
.home_voice--txtarea {
  position: relative;
  padding: rem(56) rem(40) rem(48);
  @include mq(sp) {
    padding: 24px;
  }
}
.home_voice--ttl {
  line-height: 1.83;
  letter-spacing: perc(4.8,24,em);
  &::after {
    content: '';
    display: block;
    width: 1em;
    height: 2px;
    border-bottom: 2px solid #ddd;
    margin-top: .8em;
  }
}
.home_voice--txt {
  line-height: 1.75;
  letter-spacing: perc(1.28,16,em);
}
.home_voice--name {
  line-height: 2.43;
  letter-spacing: perc(1.12,14,em);
}
.home_voice--comment {
  padding: 16px 32px 24px;
  @include mq(sp) {
    padding: 16px 12px 16px 24px;
  }
  h3.font-en {
    color: #75bbbb;
    line-height: 2.85;
    letter-spacing: perc(1.3,13,em);
  }
}
.home_voice--scroll {
  @include mq(sp) {
    max-height: 200px;
    overflow: auto;
    padding-right: 12px;
  }
}
.home_voice--icon {
  top: -15px;
  right: -15px;
  @include mq(sp) {
    top: -60px;
    width: 96px;
    height: auto;
  }
}

/* cta
********************************************** */
.cta--ttl {
  width: fit-content;
  line-height: 1.38;
  letter-spacing: perc(3.84,32,em);
  padding: 0 perc(11,32,em);
  @include auto-margin;
  @include mq(sp) {
    font-size: sprem(20) !important;
  }
}
.cta--ttl span:not(.sp-none-inline) {
  display: block;
}
.cta--ttl > span {
  position: relative;
  padding: 0 perc(61,32,em);
  @include mq(sp) {
    padding: 0 perc(40,32,em);
  }
}
.cta--ttl,
.cta--ttl > span {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 4px;
    height: rem(62);
    border-right: 4px solid #2d2d2d;
    @include mq(sp) {
      width: 2px;
      border-width: 2px;
    }
  }
  &::before {
    left: 0;
    transform: rotate(-38deg);
  }
  &::after {
    right: 0;
    transform: rotate(38deg);
  }
}
.cta--ttl .fz-13 {
  line-height: 1.85;
  letter-spacing: perc(1.04,13,em);
}
.cta--list {
  @include mq(sp) {
    display: block;
  }
}
.cta--list li:not(:last-child) {
  @include mq(sp, min, ps) {
    margin-right: 45px;
  }
  @include mq(sp) {
    margin-bottom: 8px;
  }
}
.cta--list .cta_contact {
  @include mq(sp, min, ps) {
    width: 316px;
  }
}