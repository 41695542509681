@use './variables' as *;
@use 'sass:math';

/// メディアクエリーを簡単に呼び出す
/// @param {文字列｜数字} $mq-breakpoint (デフォルト：SP)
///    ターゲットのブレーキポイント。数字を入れたら、その値を使う
/// @param {文字列} $mq-width (デフォルト：max)
///    {$mq-width}-widthのブレーキポイントを生成。maxまたmin入力可能。minを使う場合、入れた値に１pxが足される
/// @param {文字列} $mq-type (デフォルト：screen)
///    媒体ターゲット。ps (print, screen) と p (print) 入れられます。
/// @param {マップ} $mq-breakpoints
///    _variables の $em-breakpointsのマップ。デフォルトで、em単位を使うバージョンですが、 $px-breakpoints もあります。（未対応）
/// @return {文字列} @media {$mq-type} and ({$mq-width}-width:em) {}

@mixin mq($mq-breakpoint: 'sp', $mq-width: 'max', $mq-type: 'screen', $mq-breakpoints: $em-breakpoints) {
  // デフォルトで $em-breakpointsのマップを使って、単位をem、1pxの値を0.0625にする
    $unit: 'em';
    $onepx: 0.0625;
  // pxを入れる場合、単位と1pxの値を変換する
  @if $mq-breakpoints == 'px' {
    $mq-breakpoints: $px-breakpoints;
    $onepx: 1;
    $unit: 'px';
  }
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
      $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  @else {
    @if $mq-breakpoints == $em-breakpoints {
      $mq-breakpoint: math.div($mq-breakpoint, 16);
    }
  }
  @if $mq-type == 'ps' {
    $mq-type: 'print, screen';
  }
  @if $mq-type == 'p' {
    $mq-type: 'print';
  }
  @if $mq-type == 's' {
    $mq-type: 'screen';
  }
  // min-widthの場合１pxを追加
  @if $mq-width == 'min' {
    @media #{$mq-type} and (#{$mq-width}-width: #{($mq-breakpoint + $onepx  + $unit)}) {
        @content;
    }
  } @else {
    @media #{$mq-type} and (#{$mq-width}-width: #{($mq-breakpoint + $unit)}) {
      @content;
    }
  }
}

/// 1行で左と右のマージンを設定
/// @param {数字} $margin-size
///   値入れなければ、autoになります。入れると、pxで左と右のマージんがつけられます
@mixin auto-margin($margin-size: 'auto') {
  @if $margin-size != 'auto' {
    $margin-size: #{$margin-size + 'px'};
  }
  margin-left: #{$margin-size};
  margin-right: #{$margin-size};
}

// flexで中央センター。因数なし
@mixin center-flex {
  justify-content: center;
  align-items: center;
}

/// padding を早く出す。単位無し。
/// @param {数字＋単位} $padding-top
/// @param {数字+単位} $padding-left
///   上と左のパーディング。適切なミックスインで、デフォルトの値になる
@mixin p-tb($padding-top, $padding-btm: $padding-top) {
  padding-top: #{$padding-top};
  padding-bottom: #{$padding-btm};
}
@mixin p-lr($padding-left, $padding-right: $padding-left) {
  padding-left: #{$padding-left};
  padding-right: #{$padding-right};
}
@mixin p-all ($padding-top, $prgt: $padding-top, $pbtm: $padding-top, $plft: $padding-top) {
  padding-top: #{$padding-top};
  padding-right: #{$prgt};
  padding-bottom: #{$pbtm};
  padding-left: #{$plft};
}

@mixin resp_rem {
  // ToDo
  //　REMを使ってそれぞれの端末に対応しているフォントサイズ
}

// @mixin font-resp($size, $screens...)  {
//   @if length($screens) == 0 {
//     $screens: 'sp';
//     font-size: math.div($size, 16);
//     @media screen and (max-width: 767px) {
//       font-size: math.div(($i - 1), 14) + rem ;
//     }
//   }
//   @else {
//     @each $screen in $screens {

//     }
//   }

// }

//　四辺形を生成する
/// @param {数字} $width
///   幅の値
/// @param {数字(任意)} $height
///   高さの値。何も入力されていない場合、$widthを使い正方形作る、
/// @return width: $width; height: $height;
@mixin rect($width, $height: $width) {
  width: math.div($width, $fz-pc) + rem;
  height: math.div($height, $fz-pc) + rem;
}
@mixin sprect($width, $height: $width) {
  width: math.div($width, $fz-sp) + rem;
  height: math.div($height, $fz-sp) + rem;
}

@mixin border-tb($size: '1px', $color: $clr-main, $type: 'solid'  ) {
  border-top: #{$size} #{$type} #{$color};
  border-bottom: #{$size} #{$type} #{$color};
}

@mixin lh ($font-size, $line-height) {
  line-height: math.div($line-height, $font-size);
}

// 文字縦書き 因数なし
@mixin write-v {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  -webkit-font-feature-settings: 'pkna';
        font-feature-settings: 'pkna';
}

/// 簡易なグラデーション指定 始点0%,終点100%のみ
/// @param {数字} $direction //単位degなしで記述
/// @param {文字列} $color-1 //始点の色指定
/// @param {文字列} $color-2 //終点の色指定
/// 角度、始点終点の色を指定してグラデーション呼び出し 始点0%,終点100%のみ
@mixin gradient($direction, $color-1, $color-2) {
  background-image: linear-gradient(#{$direction}deg, #{$color-1} 0%, #{$color-2} 100%);
}

/// 文章省略 CSS呼び出し
/// @param {数字} $line
///   指定がなければ、1行用のスタイルを生成、あればその行数の内容で生成
@mixin clip-txt($line: 1) {
  overflow: hidden;
  @if $line == 1 {
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}