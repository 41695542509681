@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 48px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.lps_parts--flow .ttl-03 {
  font-size: rem(32);
  color: var(--clr-main, #1D7FB7);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &::after {
    content: counter(number);
    position: absolute;
    top: -6px;
    left: -80px;
    display: flex;
    @include center-flex;
    @include rect(64);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: var(--clr-main, #1D7FB7);
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-weight: 600;
    font-size: rem(26);
    letter-spacing: 0;
    @include mq(sp) {
      top: -4px;
      left: -48px;
      @include rect(36);
      font-size: sprem(18);
    }
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 66px;
  left: 36px;
  display: block;
  width: 2px;
  height: calc(100% - 80px);
  border-right: 2px solid var(--clr-main, #1D7FB7);
  @include mq(sp) {
    top: 38px;
    left: 18px;
    height: calc(100% - 48px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}
.flow_item .mce-content-body img {
  @include mq(sp, min, ps) {
    &.alignleft {
      margin-right: 32px;
    }
    &.alignright {
      margin-left: 32px;
    }
  }
  &.alignleft,
  &.alignright {
    margin-top: 0;
    overflow: hidden;
    max-width: 248px;
    @include mq(sp) {
      float: none !important;
      margin-bottom: 16px !important;
    }
  }
}