@use '../abstracts/' as *;

/* home_faq
********************************************** */
.home_faq--deco_wrap {
  z-index: 1;
  @include mq(sp) {
    position: relative;
    height: spvw(396);
    margin-top: spvw(-96);
  }
}
.home_faq--deco01 {
  top: 318px;
  left: 395px;
  width: 64px;
  transform: rotate(-30deg);
  @include mq(sp) {
    top: spvw(159);
    left: spvw(97.5);
    width: spvw(32);
  }
}
.home_faq--deco01-2 {
  top: 706px;
  right: 427px;
  width: 64px;
  transform: rotate(15deg);
  @include mq(sp) {
    top: spvw(353);
    right: spvw(113.5);
    width: spvw(32);
  }
}
.home_faq--deco02 {
  top: 271px;
  left: 330px;
  width: 59px;
  @include mq(sp) {
    top: spvw(135.5);
    left: spvw(65);
    width: spvw(29.5);
  }
}
.home_faq--deco02-2 {
  top: 587px;
  right: 407px;
  width: 59px;
  transform: scale(-1,-1);
  @include mq(sp) {
    top: spvw(293.5);
    right: spvw(103.5);
    width: spvw(29.5);
  }
}
.home_faq--deco03 {
  top: 517px;
  left: 444px;
  transform: rotate(15deg);
  @include mq(sp) {
    top: spvw(258.5);
    left: spvw(122);
    width: spvw(19.5);
  }
}
.home_faq--deco03-2 {
  top: 615px;
  left: 273px;
  transform: rotate(-30deg);
  @include mq(sp) {
    top: spvw(312.5);
    left: spvw(36.5);
    width: spvw(19.5);
  }
}
.home_faq--deco03-3 {
  top: 247px;
  right: 502px;
  transform: rotate(-30deg);
  @include mq(sp) {
    top: spvw(123.5);
    right: spvw(151);
    width: spvw(19.5);
  }
}
.home_faq--deco03-4 {
  top: 344px;
  right: 291px;
  transform: rotate(30deg);
  @include mq(sp) {
    top: spvw(172);
    right: spvw(45.5);
    width: spvw(19.5);
  }
}
.home_faq--deco04 {
  top: 589px;
  left: 347px;
  @include mq(sp) {
    top: spvw(294.5);
    left: spvw(73.5);
    width: spvw(68.5);
  }
}
.home_faq--deco05 {
  top: 284px;
  right: 366px;
  @include mq(sp) {
    top: spvw(142);
    right: spvw(83);
    width: spvw(81.5);
  }
}
.home_faq--fukidashi {
  @include mq(sp) {
    width: spvw(80);
    border-width: spvw(2);
    font-size: spvw(8);
  }
}
.home_faq--fukidashi01 {
  top: 382px;
  left: 219px;
  @include mq(sp) {
    top: spvw(191);
    left: spvw(9.5);
  }
}
.home_faq--fukidashi02 {
  top: 550px;
  right: 221px;
  @include mq(sp) {
    top: spvw(275);
    right: spvw(10.5);
  }
}