@use '../abstracts' as *;

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form {
  background-color: var(--bg-off_wht, #f7f7f7);
  border-radius: rem(20);
  padding: 32px 40px;
  @include mq(sp) {
    padding: 24px;
  }
}
#formy_form * {  
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
}
#formy_form table { width: 100%; }
#formy_form th,
#formy_form td {
  padding: rem(16);
  vertical-align: middle;
  @include mq(sp) {
    display: block;
    width: auto !important;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  @include mq(sp, min, ps) {
    width: 34%;
  }
  @include mq(sp) {
    width: 100%;
    border-bottom: none;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table textarea {
  width: 100%;
  border-radius: rem(5);
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: rem(5) rem(15);
  border: 1px solid #ccc;
  font-size: 1rem;
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp) {
    font-size: 16px;
  }
}
#formy_form textarea { height: rem(100); }
#formy_form select { height: rem(40); }
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover { opacity: .7; }
#formy_form textarea:hover { opacity: .7; }
#formy_form input:focus { outline: none; }
#formy_form .parsley-validated { background-color: #eee; }
#formy_form .parsley-error { background-color: #fee; }
#formy_form .parsley-success { background-color: #fff; }
.help_text {
  font-size: rem(14);
  color: #999;
}
.hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: rem(140);
  border: solid 1px #ccc;
  font-size: rem(14);
  padding: rem(8) rem(16);
}
.requiredIcon {
  background-color: var(--btn-bg-cta, #1D7FB7);
  color: #fff;
  margin: 0 0 0 1em;
  font-size: rem(12);
  padding: rem(2) rem(5);
  border-radius: rem(5);
  float: right;
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: rem(32);
  text-align: center;
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: rem(5);
  padding: rem(16) rem(32);
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  transition: var(--transit-default, all .4s ease-out);
  &:not(:first-child) {
    margin-bottom: rem(24);
  }
  @include mq(sp) {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"] { display: none !important; }
#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: rem(8) rem(8) rem(8) rem(40);
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all .4s ease-out);
}
#formy_form ul li label:hover { opacity: .7; }
#formy_form ul li label::before {
  content: '';
  position: absolute;
  width: rem(16);
  height: rem(16);
  border: 2px solid #788b93;
  left: rem(16);
  top: rem(12);
}
#formy_form ul li input[type="radio"] + label::before { border-radius: rem(10); }
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label {
  color: var(--btn-bg-cta, #1D7FB7);
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before {
  border-color: var(--btn-bg-cta, #1D7FB7);
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after {
  content: '';
  width: rem(10);
  height: rem(18);
  top: rem(4);
  left: rem(20);
  border-right: 2px solid var(--btn-bg-cta, #1D7FB7);
  border-bottom: 2px solid var(--btn-bg-cta, #1D7FB7);
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: var(--clr-main, #1D7FB7); }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: rem(8) rem(27);
  min-width: auto;
  font-size: rem(15);
  border: 1px solid #aaa;
  border-radius: rem(32);
}
.formy_send { background-color: var(--btn-bg-cta, #1D7FB7); }
#total_required {
  padding: rem(16);
  color: var(--btn-bg-cta, #1D7FB7);
  text-align: center;
}

@include mq(sp, min, ps) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td { padding-top: 0; }
  .autoConfirmBack { margin-bottom: sprem(8); }
}