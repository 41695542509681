@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: $fz-pc+px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: $fz-sp+px;
  }
}
body {
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, .08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #222);
  height: 100%;
  padding-top: 152px;
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: 64px;
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
.win body {
  -webkit-text-stroke: .2px;
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #222);
  text-decoration: none;
  transition: var(--transit-default, all .4s ease-out);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}
svg {
  transition: var(--transit-default, all .4s ease-out);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
.fukidashi {
  position: absolute;
  width: 160px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid 4px #2d2d2d;
  background-color: var(--bg-wht, #fff);
  border-radius: 50%;
  line-height: 1.75;
  letter-spacing: perc(2.88,16,em);
  &::before {
    content: '';
    position: absolute;
    width: perc(49,160,'%');
    aspect-ratio: 49/4;
    background-color: var(--clr-body, #333);
  }
}
.fukidashi-lt,
.fukidashi-rt {
  &::before {
    top: perc(-7,160,'%');
  }
}
.fukidashi-lb,
.fukidashi-rb {
  &::before {
    bottom: perc(-7,160,'%');
  }
}
.fukidashi-lt,
.fukidashi-rt {
  &::before {
    top: perc(-7,160,'%');
  }
}
.fukidashi-lt,
.fukidashi-lb {
  &::before {
    left: perc(-9,160,'%');
  }
}
.fukidashi-rt,
.fukidashi-rb {
  &::before {
    right: perc(-9,160,'%');
  }
}
.fukidashi-lt,
.fukidashi-rb {
  &::before {
    transform: rotate(44deg);
  }
}
.fukidashi-rt,
.fukidashi-lb {
  &::before {
    transform: rotate(-44deg);
  }
}
.shadow {
  box-shadow: 0 0 rem(16) rgba($color: $clr-main, $alpha: .2);
}
.deco_wrap,
.deco {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
.deco_wrap {
  width: 100%;
  overflow: hidden;
}
.deco {
  width: $wid-pc+px;
  @include mq(sp) {
    width: 100%;
  }
}
.deco * {
  position: absolute;
}
.wrapper {
  overflow: hidden;
}