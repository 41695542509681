@use '../abstracts' as *;
@use 'sass:math';

/* img
********************************************** */
.img-ctr {
  display: block;
  @include auto-margin;
}
.img-trim {
  display: flex;
  @include center-flex;
}
@each $name, $ratio in $aspect-ratios {
  .trim-#{$name} {
    aspect-ratio: #{$ratio};
  }
}
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}