@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 152px;
  background-color: var(--bg-wht, #fff);
  z-index: z(header);
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med) {
    height: 64px;
    box-shadow: 0 0 rem(16) rgba($color: $clr-main, $alpha: .2);
  }
  & > .inner {
    height: 100%;
    @include mq(1600) {
      width: 95%;
      max-width: calc(100% - 80px);
    }
    @include mq(1340) {
      max-width: calc(100% - 56px);
    }
    @include mq(med) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    @include mq(sp) {
      max-width: 90%;
    }
  }
}
.page-contact .header {
  position: absolute;
}
.header--logo {
  @include mq(med, min, ps) {
    margin-right: perc(55,1720,'%');
    @include mq(1600) {
      margin-right: perc(30,1720,'%');
    }
  }
}
.header--logo a,
.footer--logo a {
  display: block;
  width: fit-content;
  &:hover {
    opacity: .7;
  }
}
.header--logo img {
  max-width: 359px;
  @include mq(1600) {
    max-width: 300px;
  }
  @include mq(1340) {
    max-width: 240px;
  }
  @include mq(med) {
    width: auto;
    max-height: 40px;
  }
  @include mq(sp) {
    max-width: 200px;
  }
}
.header--txt {
  letter-spacing: perc(1.4,14,em);
  @include mq(1600) {
    font-size: rem(12);
    .fz-24 {
      font-size: rem(22);
    }
  }
  @include mq(med) {
    line-height: 1.2;
  }
}

@include mq(med, min, ps) {
  .header-sm {
    height: 80px;
    box-shadow: 0 0 rem(16) rgba($color: $clr-main, $alpha: .2);
  }
}

/* gnav
********************************************** */
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--link {
  padding: 0 rem(20);
  @include mq(1600) {
  }
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default, all .4s ease-out);
  opacity: 0;
  pointer-events: none;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  align-items: center;
  letter-spacing: perc(-1.68,42,em);
  line-height: var(--line-height-hdr, 1.4);
  @include mq(sp) {
    justify-content: center;
  }
  svg {
    display: block;
    width: rem(32);
    fill: #75bbbb;
    margin-right: perc(12,42,em);
  }
}
.cta_tel a > span {
  display: block;
}
.cta_contact a {
  font-size: rem(24);
  padding: perc(9,24,em) 1em;
  letter-spacing: perc(1.92,24,em);
}
.gnav--contact a {
  font-size: rem(18);
  border-radius: rem(8);
  padding: perc(16,18,em) perc(26.5,18,em);
  @include mq(1600) {
    font-size: rem(17);
    padding: perc(16,18,em) perc(22,18,em);
  }
}
.gnav--contact svg {
  fill: #fff;
  margin-right: 8px;
}
.gnav--cta .tel {
  font-size: rem(20);
}
.gnav--cta .num {
  font-size: rem(24);
}
.gnav--cta .hours {
  font-size: rem(14);
}

@include mq(med, min, ps) {
  .gnav li,
  .gnav a {
    height: 100%;
  }
  .gnav {
    display: flex;
    height: 56px;
    margin-left: auto;
    @include mq(1600) {
      font-size: rem(15);
    }
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--menu > li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gnav--menu > li:not(:last-child)::after {
    content: '';
    display: block;
    @include rect(2,10);
    background-color: #ddd;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-main, #1D7FB7);
  }
  .head_sub_nav a {
    background-color: #fff;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: .7;
  }

  /* ---CTA --- */
  .gnav--cta {
    margin-left: rem(16);
    @include mq(1600) {
      margin-left: rem(8);
    }
    @include mq(1340) {
      position: absolute;
      top: calc(100% + 8px);
      right: 28px;
      margin-left: 0;
    }
  }
}
@include mq(med) {
  .gnav {
    position: fixed;
    top: 64px;
    right: 0;
    width: 100%;
    height: calc(100% - 64px);
    padding: 24px 7%;
    pointer-events: none;
    background-color: var(--bg-off_wht, #f7f7f7);
    overflow: scroll;
    z-index: 300;
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default, all .4s ease-out);
    opacity: 0;
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav--menu {
    display: block;
    margin-bottom: 24px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 2px dotted rgba($color: $clr-main, $alpha: .5);
  }
  .gnav--menu a {
    display: block;
    padding: 1.13em 1em;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: rem(10);
    margin: auto;
    @include rect(7);
    border-top: 2px solid var(--clr-main, #1D7FB7);
    border-right: 2px solid var(--clr-main, #1D7FB7);
    transform: rotate(45deg);
  }
  .gnav--cta {
    display: none;
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1D7FB7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include rect(64);
    transition: var(--transit-default, all .4s ease-out);
    color: var(--clr-main, #1D7FB7);
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 2px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
    background-color: var(--bg-main, #ccc);
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    fill: var(--clr-main, #1D7FB7);
  }
  .gnav_btn {
    right: 0;
    background-color: var(--clr-main, #1D7FB7);
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all .4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--bg-wht, #fff);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }

  /* ---CTA --- */
}
@include mq(sp) {
  .gnav--menu a {
    padding: 19px 16px;
  }
}