@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 124px;
  padding-bottom: 124px;
  @include mq(sp) {
    padding-top: 62px;
    padding-bottom: 62px;
  }
}
.section_pdg-sm {
  padding-top: 64px;
  padding-bottom: 64px;
  @include mq(sp) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

/* --- section-line --- */
.section-line {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -101px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 101px;
    background-repeat: repeat-x;
    background-position: center;
    @include mq(sp) {
      top: spvw(-50);
      height: spvw(50);
      background-size: contain;
    }
  }
}
.section-line01 {
  background-color: var(--bg-main, #ccc);
  &::before {
    background-image: url(../images/common/line01.png);
    @include mq(sp) {
      background-image: url(../images/common/line01_sp.png);
    }
  }
}
.section-line02 {
  background-image: url(../images/common/bg-pt.png);
  background-position: calc(50% - 41px) -20px;
  @include mq(sp) {
    background-position: calc(50% - spvw(20)) spvw(15);
    background-size: spvw(64);
  }
  &::before {
    background-image: url(../images/common/line02.png);
    @include mq(sp) {
      background-image: url(../images/common/line02_sp.png);
    }
  }
}
.section-line03 {
  background-color: var(--bg-off_wht, #f7f7f7);
  &::before {
    background-image: url(../images/common/line03.png);
    @include mq(sp) {
      background-image: url(../images/common/line03_sp.png);
    }
  }
}
.section-line04::before {
  background-image: url(../images/common/line04.png);
  @include mq(sp) {
    background-image: url(../images/common/line04_sp.png);
  }
}
.section-line-double {
  .bg03 {
    position: absolute;
    top: -111px;
    left: 50%;
    transform: translateX(-50%);
    width: max(1920px,100%);
    height: 111px;
    background-image: url(../images/common/bg03.png);
    background-repeat: repeat-x;
    background-position: center bottom -2px;
    mix-blend-mode: multiply;
    z-index: -1;
    pointer-events: none;
    @include mq(sp) {
      top: spvw(-55);
      width: 100%;
      background-image: url(../images/common/bg03_sp.png);
      background-size: contain;
      height: spvw(55);
    }
  }
}

/* --- section-hari --- */
.section-hari {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -279px;
    left: 50%;
    transform: translateX(-50%);
    width: $wid-pc+px;
    height: 504.66px;
    background-repeat: no-repeat;
    background-position: left -24px center;
    background-size: contain;
    pointer-events: none;
    @include mq(sp) {
      top: spvw(-139.5);
      width: 100%;
      height: spvw(168.22);
    }
  }
  .inner {
    position: relative;
    z-index: 1;
  }
}
.section-hari-rgt::after {
  top: -189px;
  background-position: left -32px center;
  transform: translateX(-50%) scaleX(-1);
  @include mq(sp) {
    top: spvw(-94.5);
    background-position: left spvw(-16) center;
  }
}
.section-hari-wh::after {
  background-image: url(../images/common/hari01.svg);
  filter: brightness(0) invert(1);
}
.section-hari01::after {
  background-image: url(../images/common/hari01.svg);
}
.section-hari02::after {
  background-image: url(../images/common/hari02.svg);
}
.section-hari03::after {
  background-image: url(../images/common/hari03.svg);
}

/* --- section-bg --- */
.section-bg {
  position: relative;
  z-index: 1;
  & + .section-line {
    z-index: 1;
  }
}
.bg01 {
  position: absolute;
  top: -359px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: max(1920px,100%);
  height: 359px;
  z-index: -1;
  pointer-events: none;
  @include mq(sp) {
    top: spvw(-124);
    width: 100%;
    height: spvw(92);
  }
  &::before,
  &::after {
    content: '';
    display: block;
    background-repeat: no-repeat, no-repeat;
    width: 532px;
    height: 100%;
    background-image: url(../images/common/bg01.png);
    @include mq(sp) {
      width: spvw(111);
      background-image: url(../images/common/bg01_sp.png);
      background-size: cover;
    }
  }
  &::before {
    background-position: left;
  }
  &::after {
    background-position: right;
  }
}