@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */
  .footer {
    position: relative;
    z-index: 1;
  }

/* footer_top
********************************************** */
  .footer--logo a {
    @include auto-margin;
    width: fit-content;
    @include mq(sp, min, ps) {
      min-width: min(370px,100%);
    }
  }
  .footer--logo img {
    display: block;
    &.logo-mark {
      width: perc(72,370,'%');
      &:not(:last-child) {
        margin-bottom: rem(20);
      }
    }
    &.logo-txt {
      width: 100%;
    }
  }
  .footer--txt {
    @include mq(sp, min, ps) {
      letter-spacing: perc(2.8,20,em);
    }
  }
  .footer--cta li:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: 50px;
    }
    @include mq(sp) {
      margin-bottom: 8px;
    }
  }
  .footer--tel a svg {
    fill: var(--clr-main, #1D7FB7) !important;
  }

/* footer_md
********************************************** */
  .footer_md {
    max-width: 1067px;
    @include auto-margin;
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: perc(420,1067,'%') auto;
      column-gap: perc(87,1067,'%');
      align-items: center;
    }
  }
  .footer_md-lft {
    @include mq(sp) {
      margin-bottom: 24px;
    }
  }
  .footer--pa {
    padding-bottom: rem(18);
    border-bottom: 1px solid rgba($color: $clr-main, $alpha: .2);
    @include mq(sp) {
      margin-bottom: rem(18);
    }
  }
  .footer--pa img {
    margin-bottom: 12px;
  }
  .footer--pa p {
    display: grid;
    grid-template-columns: 45px auto;
    column-gap: 12px;
    align-items: center;
    line-height: 1.5;
    letter-spacing: perc(0.48,12,em);
  }
  .icon-pa {
    display: flex;
    @include center-flex;
    color: var(--clr-wht, #fff);
    background-color: var(--clr-main, #1D7FB7);
    margin-left: auto;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    font-weight: 700;
  }
  .fnav--menu {
    @include mq(sp, min, ps) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      li {
        width: 50%;
      }
    }
  }
  .fnav--menu a {
    position: relative;
    display: block;
    padding: perc(5,16,em) 0 perc(5,16,em) perc(21,16,em);
    letter-spacing: perc(3.52,16,em);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      @include rect(8);
      color: var(--clr-main, #1D7FB7);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
    }
    &:hover {
      color: var(--clr-main, #1D7FB7);
    }
  }
  .sns--list li:not(:last-child) {
    margin-right: rem(16);
  }
  .sns--list a {
    display: grid;
    place-items: center;
    @include rect(40);
    &:hover {
      opacity: .7;
    }
  }
  .sns--list svg {
    @include rect(30);
    fill: var(--clr-main, #1D7FB7);
  }
  .footer--map {
    aspect-ratio: 1/1;
    border: solid 4px #75bbbb;
    opacity: .98;
    border-radius: rem(16);
  }


/* footer_btm
*************************************************** */
  .footer_btm {
    padding: 8px 0;
  }
  .copyright {
    @include mq(sp, min, ps) {
      margin-right: 1em;
    }
    @include mq(sp) {
      margin-bottom: .5em;
    }
  }
  .pbl a,
  .pbl img {
    display: block;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  .copyright {
    text-transform: uppercase;
    letter-spacing: .1em;
  }