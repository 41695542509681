@use '../abstracts/' as *;

/* home_contact
********************************************** */
.home_contact {
  padding-top: 80px;
  padding-bottom: 180px;
  @include mq(sp, min, ps) {
    margin-bottom: -101px;
  }
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 0 !important;
    .inner {
      padding-bottom: 40px;
    }
  }
}
.home_contact--txtarea {
  @include mq(sp, min, ps) {
    margin-left: auto;
    width: 536px;
  }
}
.home_contact--txt {
  line-height: 2.13;
}
.home_contact--deco_wrap {
  top: -101px;
  bottom: 101px;
  @include mq(sp) {
    position: relative;
    top: inherit;
    bottom: inherit;
    height: spvw(299);
  }
}
.home_contact--deco {
  background-image: url(../images/common/cta-bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  @include mq(sp) {
    background-image: url(../images/common/cta-bg_sp.png);
    background-size: contain;
  }
}