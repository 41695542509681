@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: 720px;
  @include mq(med) {
    padding-top: 54px;
  }
  @include mq(sp) {
    height: 576px;
    padding-top: 7vw;
    .inner {
      max-width: 90%;
    }
  }
}
.hero_slide {
  position: absolute !important;
  top: 0;
  left: 0;
  border-radius: rem(16);
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/hero-frame.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
    @include mq(sp) {
      background-image: url(../images/hero-frame_sp.png);
    }
  }
}
.hero .inner,
.hero--txtarea {
  height: 100%;
}
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  z-index: -1;
}
.hero_slide li {
  aspect-ratio: 1720/720;
  @include mq(sp) {
    border-radius: rem(16);
    overflow: hidden;
  }
}
.hero_slide {
  .slick-arrows {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    aspect-ratio: 80/64;
    color: var(--clr-main, #1D7FB7);
    background-color: var(--bg-wht, #fff);
    z-index: 1;
    pointer-events: auto;
    cursor: pointer;
    @include mq(sp) {
      width: 56px;
    }
    &::after {
      content: '';
      display: block;
      @include rect(10);
      transform: rotate(45deg);
    }
  }
  .slick-prev {
    left: 0;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      width: 2px;
      height: perc(24,64,'%');
      border-right: 2px solid #ddd;
    }
    &::after {
      border-bottom: 2px solid;
      border-left: 2px solid;
    }
  }
  .slick-next {
    left: 80px;
    border-radius: 0 rem(10) 0 0;
    @include mq(sp) {
      left: 56px;
    }
    &::after {
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }
}
.hero--txtarea {
  width: 90%;
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  @include mq(sp) {
    align-items: flex-start;
    padding-top: 40%;
  }
}
.hero--lead {
  position: relative;
  line-height: 2.77;
  letter-spacing: perc(2.6,26,em);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(sp, min, ps) {
    width: 441px;
  }
  @include mq(sp) {
    line-height: var(--line-height-default, 1.8);
    width: fit-content;
    min-width: min(8em,100%);
    margin-left: auto;
    font-size: sprem(14);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &::before {
    top: 0;
    left: 0;
    width: perc(49,441,'%');
    aspect-ratio: 49/50;
    background-image: url(../images/common/icon-kyu.svg);
    @include mq(sp) {
      top: inherit;
      bottom: -30%;
      left: 35%;
      transform: translateX(-50%);
      width: perc(49,220,'%');
    }
  }
  &::after {
    bottom: 0;
    right: 0;
    width: perc(33,441,'%');
    aspect-ratio: 33/63;
    background-image: url(../images/common/icon-hari.svg);
    @include mq(sp) {
      bottom: -45%;
      left: 65%;
      right: inherit;
      transform: translateX(-50%);
      width: perc(33,220,'%');
    }
  }
}
.hero--copyright {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: perc(40,1920,'%');
  white-space: nowrap;
  @include mq(sp) {
    display: none;
  }
}