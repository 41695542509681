@use '../abstracts/' as *;

/* home_menu
********************************************** */
.home_menu::after {
  top: -286px;
  background-position: left -64px center;
  @include mq(sp) {
    top: spvw(-143);
    background-position: left spvw(-32) center;
  }
}
.home_menu--txt {
  line-height: 1.75;
  letter-spacing: perc(1.28,16,em);
}
.home_menu--list {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  column-gap: perc(30,1320,'%');
  @include mq(sp) {
    grid-template-columns: repeat(2,1fr);
    gap: 8px;
  }
}
.home_menu--list a {
  position: relative;
  display: grid;
  grid-template-rows: 89px auto;
  align-items: center;
  border-radius: rem(16);
  background-color: var(--bg-wht, #fff);
  padding: rem(38) rem(24) rem(42);
  &:hover {
    box-shadow: 0 0 rem(24) rgba($color: $clr-main, $alpha: .3);
    svg {
      fill: #ffa69d
    }
  }
  svg {
    position: absolute;
    top: rem(24);
    right: rem(24);
    fill: #75bbbb;
  }
}
.home_menu--ttl {
  line-height: 1.54;
  letter-spacing: perc(5.28,24,em);
}
.home_menu--ttl:not(:last-child) {
  margin-bottom: .15em !important;
}
.home_menu--list .font-en {
  letter-spacing: perc(1.3,13,em);
  text-align: center;
  color: #ffa59c;
}