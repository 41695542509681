@use '../abstracts' as *;

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child { margin-left: auto; }
.lps_parts--button .btn:last-child { margin-right: auto; }

@include mq(sp, min, ps) {
  .lps_parts--button.flex,
  .lps_parts--button .inner {
    width: fit-content;
    min-width: min(960px,90%);
  }
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }
  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(300px,100%);
  }
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button { margin-top: 0; }
}