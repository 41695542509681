@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;

// 色
$clr-main: #006463;

// font-families
$font-primary: "秀英丸ゴシック B", "Shuei MaruGo B";
$font-secondary: "秀英丸ゴシック L", "Shuei MaruGo L";
$font-jp: "秀英丸ゴシック B", "Shuei MaruGo B";
$font-en: 'Sen', sans-serif;
$font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// CSS変数
:root {
  // typography
  --ltr-space-default: .08em;
  --line-height-default: 1.75;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "秀英丸ゴシック B", "Shuei MaruGo B";  
  --font-secondary: "秀英丸ゴシック L", "Shuei MaruGo L";
  --font-jp: "秀英丸ゴシック B", "Shuei MaruGo B";
  --font-en: 'Sen', sans-serif;
  --font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;



  // text colors
  --clr-main: #006463;
  --clr-sub: #4aa2a2;
  --clr-body: #171923;
  --clr-dark-blue: #101560;
  --clr-link: #222;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #afd7d7;
  --bg-sub: #c3e2e2;
  --bg-wht: #fff;
  --bg-off_wht: #f3f4ee;
  --bg-blk: #000;

  //button colors
  --btn-clr-main: var(--clr-main);
  --btn-bg-main: transparent;
  --btn-bg-main-hvr: var(--bg-sub);

  --btn-clr-wht: var(--bg-wht);
  --btn-bg-wht: transparent;
  --btn-bg-wht-hvr: var(--bg-off_wht);

  --btn-clr-cta: var(--clr-wht);
  --btn-bg-cta: #f87567;


  // border colors
  --brd-main: #006463;

  // animation
  --transit-default: all .4s ease-out;


  // formy settings

}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);

$aspect-ratios: (
  widescreen: '3/2',
  square: '1/1',
  portrait: '2/3'
);